import React from 'react'

//import { makeStyles } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
//import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
//import Rating from '@mui/lab/Rating'
import Tooltip from '@mui/material/Tooltip'

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import Typography from '@mui/material/Typography'
import axios from 'axios'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import AuthService from '../../../services/authService.js'
import userService from '../../../services/userService.js'
import utils from '../../../helpers/utils.js'

import { connect } from 'react-redux' //Code

import { openProfileDialog } from '../../ProfileDialog/profileDialogSlice'
import { openSnackbarMessage } from '../../SnackbarMessage/snackbarMessageSlice'
/*
const useStyles = makeStyles({
    root: {
        maxWidth: 345
    }
})

//const classes = useStyles()
*/
//const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => {
    return {
        openProfileDialog: (shiftCollection, shiftRequest) => {
            console.log('opening profile dialog')
            let data = {
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection
            }
            dispatch(openProfileDialog(data))
        },
        openSnackbarMessage: data => {
            dispatch(openSnackbarMessage(data))
        }
    }
}
const AssociateCard = props => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    let shiftCollection = props.shiftCollection
    let associateRequest = props.associateRequest

    const openProfileDialog = () => {
        let shiftStatus = 'pending'
        props.openProfileDialog(
            props.shiftCollection,
            props.associateRequest,
            shiftStatus
        )
    }
    const requestShift = rate => {
        console.log('request shift!')
        //console.log(props.facility)
        handleOpen()
    }

    /*
    let rate = associateRequest.customPayoutHourlyRate
        ? associateRequest.customPayoutHourlyRate
        : baseRate
        */

    let tmp = { ...shiftCollection }
    tmp.request = { ...associateRequest }
    tmp.shift = { ...associateRequest.shift }
    tmp.customAssociateFacilityPayRate =
        tmp.request.customAssociateFacilityPayRate
    let rate = utils.getPayoutHourlyRate(tmp)
    rate = Number(rate).toFixed(2)
    //let rate = utils.getBillableHourlyRate(tmp)

    const reserveShift = async () => {
        console.log('shift is requested')
        console.log(props.nurse)
        setIsLoading(true)
        let associateRequest = Object.assign({}, props.associateRequest)
        associateRequest.action = 'reserve'
        let headers = await AuthService.getHeader()
        try {
            await axios.put(
                `/api/facility/shift/request/${associateRequest.id}`,
                associateRequest,
                {
                    headers: headers
                }
            )
        } catch (e) {
            //console.log(JSON.stringify(e.response))
            if (e.response && e.response.data && e.response.data.error === 1) {
                alert(
                    'Oh no! This nurse is already book at another facility for the same hours. Sorry. Please select another nurse.'
                )
            }
        }
        // reload shifts
        await userService.getAccount()
        handleClose()
        let msg =
            'Thank you for using NurseLab. We will communicate the shift details to this nurse.'
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success',
            autoHideDuration: 6000
        })
        setIsLoading(false)
    }

    const handleOpen = () => {
        setIsOpen(true)
        console.log('handle open clicked')
        console.log(props)

        //dispatch(openModal(props))
        //setState(state => ({ open: !state.open }))
        //setState(state => ({ open: true }))
    }

    const handleClose = () => {
        setIsOpen(false)
    }
    const handleCancel = () => {
        setIsOpen(false)
    }
    let associate = props.associateRequest.user

    let secondCardButton = ''
    if (!props.isPastShift) {
        secondCardButton = (
            <Tooltip title="Book nurse for shift">
                <Button
                    onClick={e => requestShift(e)}
                    size="small"
                    color="primary"
                >
                    Book nurse
                </Button>
            </Tooltip>
        )
    }
    //
    return (
        <Grid
            item
            md={3}
            style={{
                flex: 'none',
                display: 'flex',
                width: '100%',
                marginBottom: '2px',
                marginTop: '2px'
            }}
        >
            <Card
                style={{
                    display: 'flex',
                    width: '100%',
                    marginLeft: '2px',
                    marginRight: '2px',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}
            >
                {/*}
                    <CardMedia
                        component="img"
                        alt={props.facility}
                        height="70"
                        image={props.image}
                        title={props.facility}
                        style={{
                            objectFit: 'contain',
                        }}
                    />
                    */}

                <CardContent>
                    <Typography gutterBottom variant="h6" component="h6">
                        Available
                    </Typography>
                    <Avatar
                        src={associate.profileImageUrl}
                        style={{
                            height: '4.5em',
                            width: '4.5em',
                            marginBottom: '0.5em',
                            display: 'inline-block'
                        }}
                    />
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        style={{ fontSize: '1.25em' }}
                    >
                        {associate.firstName} {associate.lastName},{' '}
                        {props.shiftCollection.type}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        ${rate}
                        /hr
                        {/*Experience: 3 years*/}
                    </Typography>
                    {/*
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {associate.rating ? (
                            <Rating
                                style={{ marginTop: '0.25em' }}
                                name=""
                                value={associate.rating}
                                readOnly
                            />
                        ) : (
                            'No ratings yet'
                        )}
                    </Typography>
                    */}
                </CardContent>
                <CardActions>
                    <Tooltip title="Profile, Certifications & Licenses">
                        <Button
                            onClick={e => openProfileDialog(e)}
                            size="small"
                            color="primary"
                        >
                            <AssignmentIndIcon
                                style={{ marginRight: '0.25em' }}
                            />{' '}
                        </Button>
                    </Tooltip>

                    {secondCardButton}
                </CardActions>
            </Card>

            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm nurse</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Would you like to have{' '}
                        {props.associateRequest.user.firstName}{' '}
                        {props.associateRequest.user.lastName},{' '}
                        {props.shiftCollection.type} fill this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleCancel}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={reserveShift}
                        color="primary"
                        autoFocus
                    >
                        Yes, book nurse
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default connect(
    null,
    mapDispatchToProps
)(AssociateCard)
