import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

//import { withStyles } from '@mui/material/styles'

import { setUser } from '../features/User/userSlice.js'
//import userService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import { withRouter } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'

import axios from 'axios'
import {
    openTimesheetDialog,
    toggleTimesheetUpload
} from '../features/Timesheet/timesheetSlice'

import SelectAssociate from '../features/NurseAdmin/SelectAssociate/SelectAssociate.js'
import utils from '../helpers/utils'

import TzMoment from '../plugins/TzMoment'

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
})

let oldServerData = []

const mapStateToProps = state => ({ user: state.user, shifts: state.shifts })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        openTimesheetDialog: shiftId => {
            console.log('opening timesheet dialog')

            let data = {
                shift: {},
                shiftRequest: {},
                shiftCollection: {},
                shiftId: shiftId
            }
            dispatch(openTimesheetDialog(data))
        },
        uploadTimesheet: shiftId => {
            let data = {
                shiftId: shiftId
            }
            dispatch(toggleTimesheetUpload(data))
        }
    }
}

/*
function roundUpHalf(num) {
    let intPart = Math.trunc(num) // returns 3
    let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
    let roundedFloat = 0
    if (floatPart >= 0.5) {
        roundedFloat = 1
    } else if (floatPart > 0) {
        roundedFloat = 0.5
    }
    let total = intPart + roundedFloat
    total = total.toFixed(1)

    return total
}
*/

const NurseHistory = props => {
    const [selectedNurseName, setSelectedNurseName] = React.useState('')
    const [pendingLedger, setPendingLedger] = React.useState([])
    const [currentLedger, setCurrentLedger] = React.useState([])
    const [pendingInvoiceTotal, setPendingInvoiceTotal] = React.useState(0)
    const [currentInvoiceTotal, setCurrentInvoiceTotal] = React.useState(0)

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const handleOpenViewTimesheetDialog = async shiftId => {
        props.openTimesheetDialog(shiftId)
    }

    let timezone = 'America/Los_Angeles'
    if (props.user.selectedAssociate.timezone) {
        timezone = props.user.selectedAssociate.timezone
    }
    let tzMoment = new TzMoment(timezone)

    const pageLoad = async () => {
        if (!props.user.selectedAssociate.firstName) {
            return
        }

        let headers = await AuthService.getHeader()

        let res

        let associateId = props.user.selectedAssociate.id

        try {
            res = await axios.get(
                `/api/admin/account/payments/associate/${associateId}`,
                {
                    headers: headers
                }
            )

            console.log(res.data)
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        if (!res) {
            return
        }
        console.log(res.data)
        let ledgerItems = res.data
        oldServerData = ledgerItems
        populateTable(ledgerItems)
    }
    const populateTable = ledgerItems => {
        // filter into historic and new bilings
        let pendingItems = []
        let completedItems = []
        ledgerItems.forEach(item => {
            /*
                item.shiftCollection = Utils.prettyFormatDateTimeRange(
                    item.shiftCollection
                )

*/
            let isBefore = false
            if (item.shiftCollection) {
                isBefore = tzMoment
                    .moment(new Date())
                    .isBefore(item.shiftCollection.end)
            } else {
                isBefore = tzMoment.moment(new Date()).isBefore(item.postDate)
            }

            if (isBefore) {
                pendingItems.push(item)
            } else {
                completedItems.push(item)
            }
        })

        let pendingRows = []
        let completedRows = []
        let pTotal = 0
        let cTotal = 0
        if (pendingItems.length === 0) {
            pendingRows.push({
                id: 0,
                msg: 'No upcoming shifts.',
                total: 0
            })
        } else {
            ;[pendingRows, pTotal] = createRows(pendingItems)
        }
        if (completedItems.length === 0) {
            completedRows.push({
                id: 0,
                msg: 'No prior shifts.',
                total: 0
            })
        } else {
            ;[completedRows, cTotal] = createRows(completedItems)
        }
        setPendingLedger(pendingRows)
        setPendingInvoiceTotal(pTotal)

        setCurrentLedger(completedRows)
        setCurrentInvoiceTotal(cTotal)
    }
    const createRows = ledgerItems => {
        let tmpRows = []
        let bigTotal = 0

        ledgerItems.forEach(item => {
            let role = ''
            if (item.role) {
                role = item.role.shortName + ': '
            }

            let msg = item.description
            /*
        if (hrs > 5) {
            msg += '. Includes 30 min lunch break.'
        }
        */
            let total = item.amount ? item.amount : item.pendingAmount
            total = parseFloat(total).toFixed(2)
            bigTotal = (parseFloat(bigTotal) + parseFloat(total)).toFixed(2)
            let date = ''
            if (item.shiftCollection) {
                date = item.shiftCollection.start
            } else {
                date = item.created
            }

            let hrs = ''
            if (item.shiftCollection && item.shiftCollection.duration) {
                hrs = item.shiftCollection.duration
            }

            let rate = ''
            if (item.payRate && item.payRate.payoutHourlyRate) {
                rate = item.payRate.payoutHourlyRate.toFixed(2)
            }

            date = tzMoment.moment(date).format('l')
            let id = item.id

            let timesheetButton
            //TODO BETTER FIX AFTER CALL
            if (item.shift.hasTimesheet) {
                timesheetButton = (
                    <Tooltip title="View Timesheet">
                        <Button
                            variant="contained"
                            onClick={() =>
                                handleOpenViewTimesheetDialog(item.shift_id)
                            }
                        >
                            View
                        </Button>
                    </Tooltip>
                )
            } else {
                timesheetButton = (
                    <Tooltip title="Upload Signed Timesheet">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => props.uploadTimesheet(item.shift_id)}
                        >
                            Upload
                        </Button>
                    </Tooltip>
                )
            }
            let tmp = { id, msg, hrs, total, rate, date, timesheetButton }
            tmpRows.push(tmp)
        })
        return [tmpRows, bigTotal]
    }
    React.useEffect(() => {
        if (!props.user || !props.user.selectedAssociate.id) {
            alert('Please select a nurse first')
        }
        console.log('loading billing use effect')
    }, [])

    React.useEffect(
        () => {
            let name = utils.getNurseName(props.user.selectedAssociate)
            setSelectedNurseName(name)
            //switchView('month', true)
            //
            populateTable(oldServerData)

            pageLoad()
        },
        [props.user.selectedAssociate]
    )
    React.useEffect(
        () => {
            console.log('re-loading data upload')

            pageLoad()
        },
        [props.shifts]
    )
    const classes = useStyles()

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? 0 : '1em'
            }}
        >
            <br />
            <br />
            <SelectAssociate />
            <br />
            <h2>For Nurse: {selectedNurseName}</h2>
            <br />
            <h2> Prior shifts</h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="" colSpan={4}>
                                Details
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="">Hrs</TableCell>
                            <TableCell align="">Timesheet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentLedger.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg}</TableCell>
                                <TableCell align="">{row.hrs}</TableCell>
                                <TableCell align="">
                                    {row.timesheetButton}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/*
                        <TableRow>
                            <TableCell colSpan={4}>Total</TableCell>
                            <TableCell align="right">
                                {currentInvoiceTotal}
                            </TableCell>
                        </TableRow>
                        */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Base Rate. Time and a half is paid for shifts exceeding
            8hrs. Double time for shifts exceeding 12 hours. 30 minute lunch
            break not included in hours worked.
            */}
            <br />
            *30 minute lunch break not included in hours worked.
            <br />
            <br />
            <br />
            <br />
            <h2> Upcoming Shifts</h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="" colSpan={4}>
                                Details
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="">Hrs</TableCell>
                            <TableCell align="">Timesheet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingLedger.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg}</TableCell>
                                <TableCell align="">{row.hrs}</TableCell>
                                <TableCell align="">
                                    {row.timesheetButton}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/*
                        <TableRow>
                            <TableCell colSpan={4}>Total</TableCell>
                            <TableCell align="right">
                                {pendingInvoiceTotal}
                            </TableCell>
                        </TableRow>
                        */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Base Rate. Time and a half is paid for shifts exceeding
            8hrs. Double time for shifts exceeding 12 hours. 30 minute lunch
            break not included in hours worked.
            */}
            <br />
            *30 minute lunch break not included in hours worked.
        </Container>
    )
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(NurseHistory)
