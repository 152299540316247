import React from 'react'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { connect } from 'react-redux' //Code

import { setUser } from '../../User/userSlice.js'
import TZClocks from '../../TZClocks/TZClocks'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        }
    }
}
const SelectFacility = props => {
    const handleSelectFacility = e => {
        console.log('handle select')
        let facilityId = e.target.value
        let facility = props.user.facilities.filter(facility => {
            return facility.id === facilityId ? true : false
        })

        if (facility.length === 1) {
            facility = facility[0]
        } else {
            console.log('COULD NOT FIND MATCHIGN FACILITY, ERROR')
        }
        console.log(facility)
        props.setUser({
            ...props.user,
            selectedFacility: facility
        })
    }

    const { classes } = props

    console.log('start rendering')
    let menuItems = []
    if (props.user && props.user.facilities) {
        menuItems = props.user.facilities.map(fbb => {
            let displayName = fbb.name
            if (fbb.isInternallyAdministered) {
                displayName =
                    fbb.name + ' (Internally Administered by NurseLab)'
            }
            return (
                <MenuItem key={fbb.id} value={fbb.id}>
                    {displayName}
                </MenuItem>
            )
        })
    }
    console.log('have menu items')
    let facilityId =
        props.user &&
        props.user.selectedFacility &&
        props.user.selectedFacility.id
            ? props.user.selectedFacility.id
            : ''
    let selectFacility = (
        <div>
            <span
                style={{
                    fontSize: '1.5em',
                    fontWeight: 'bolder'
                }}
            >
                Facility:
            </span>
            <Select
                style={{
                    marginLeft: '1em',
                    fontSize: '1.5em',
                    maxWidth: '60vw',
                    fontWeight: 'bolder'
                }}
                variant="standard"
                value={facilityId}
                onChange={handleSelectFacility}
            >
                {menuItems}
            </Select>
            <TZClocks />
        </div>
    )
    return selectFacility
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectFacility)
