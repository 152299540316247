import React from 'react'

import { withRouter } from 'react-router-dom'
import Container from '@mui/material/Container'

//import SortFacilityMenu from '../SortFacilityMenu.js'
import JobListings from '../features/FacilityAdmin/JobListings/JobListings.js'

import withStyles from '@mui/styles/withStyles'

//import Paper from '@mui/material/Paper'
import { setUser, resetUser } from '../features/User/userSlice.js'
//import UserService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import ShiftRequest from '../features/FacilityAdmin/ShiftRequest/ShiftRequest'

import SelectFacility from '../features/FacilityAdmin/SelectFacility/SelectFacility'
import NurseUserService from '../services/userService.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const useStyles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
})

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        resetUser: () => dispatch(resetUser())
    }
}

const Home = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    React.useEffect(() => {
        console.log('fetch updates for facility_home')
        NurseUserService.getAccount()
    }, [])
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />

            <SelectFacility user={props.user} />
            <br />
            <br />
            <br />
            <ShiftRequest />
            <br />
            <header>
                {/*<h2>Nurse Candidates </h2>*/}
                {/*<SortFacilityMenu />*/}
            </header>
            <JobListings />
            <br />
            <br />
            <br />
        </Container>
    )
}

//export default withStyles(useStyles) connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
export default compose(
    withStyles(useStyles),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Home)
