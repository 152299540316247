import React from 'react'

import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import Typography from '@mui/material/Typography'

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
//import TimerIcon from '@mui/icons-material/Timer'
import BlockIcon from '@mui/icons-material/Block'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'

import { openModal, closeModal } from '../../PlivoModal/plivoModalSlice'

import { openCancelShiftDialog } from '../CancelShiftDialog/cancelShiftDialogSlice'
import { openProfileDialog } from '../../ProfileDialog/profileDialogSlice'
import { openClockDialog } from '../../ClockDialog/clockDialogSlice'
import {
    openTimesheetDialog,
    toggleTimesheetUpload
} from '../../Timesheet/timesheetSlice'

import utils from '../../../helpers/utils.js'

import TzMoment from '../../../plugins/TzMoment'
import { connect } from 'react-redux' //Code

import 'animate.css'

//import ReceiptLongIcon from '@mui/icons-material/Receipt'
//import CallIcon from '@mui/icons-material/Call'
//import Rating from '@mui/lab/Rating'
//import CardActionArea from '@mui/material/CardActionArea'
//import { makeStyles } from '@mui/material/styles'

const moment = require('moment')
/*
const useStyles = makeStyles({
    root: {
        maxWidth: 345
    }
})

//const classes = useStyles()
*/

const mapStateToProps = state => ({ isPlivoModalOpen: state.plivo.isOpen })
const mapDispatchToProps = dispatch => {
    return {
        openModal: associate => {
            dispatch(openModal(associate))
        },
        closeModal: () => {
            dispatch(closeModal())
        },
        openCancelShiftDialog: (shiftCollection, shiftRequest) => {
            let data = {
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection
            }
            dispatch(openCancelShiftDialog(data))
        },
        openProfileDialog: (shiftCollection, shiftRequest, shift) => {
            console.log('opening profile dialog')

            let shiftStatus = 'confirmed'
            let data = {
                shift: shift,
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection,
                shiftStatus: shiftStatus
            }
            dispatch(openProfileDialog(data))
        },
        openClockDialog: (shiftCollection, shiftRequest, shift) => {
            console.log('opening clock dialog')

            let data = {
                shift: shift,
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection
            }
            dispatch(openClockDialog(data))
        },
        uploadTimesheet: shift => {
            let data = {
                shift: shift
            }
            dispatch(toggleTimesheetUpload(data))
        },
        openTimesheetDialog: (shiftCollection, shiftRequest, shift) => {
            console.log('opening timesheet dialog')

            let data = {
                shift: shift,
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection
            }
            dispatch(openTimesheetDialog(data))
        }
    }
}

const PlacedAssociateCard = props => {
    let shiftCollection = props.shiftCollection
    let associateRequest = props.associateRequest
    let associate = props.associateRequest.user
    const [isOpen, setIsOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [clockDetails, setClockDetails] = React.useState('')
    const [animateClass, setAnimateClass] = React.useState('')

    let tzMoment = new TzMoment(props.shiftCollection.timezone)

    const callAssociate = rate => {
        //console.log(props.facility)
        console.log('open')
        console.log(associate)
        props.openModal(associate)
    }

    const cancelShiftDialog = () => {
        props.openCancelShiftDialog(
            props.shiftCollection,
            props.associateRequest
        )
    }

    const openProfileDialog = () => {
        props.openProfileDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
    }

    const openClockDialog = () => {
        props.openClockDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
    }
    const handleClose = () => {
        setIsOpen(false)
    }
    const handleOpenViewTimesheetDialog = async shiftId => {
        props.openTimesheetDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
    }

    let tmp = { ...shiftCollection }
    tmp.request = { ...associateRequest }
    tmp.shift = { ...associateRequest.shift }
    tmp.customAssociateFacilityPayRate =
        tmp.request.customAssociateFacilityPayRate
    let rate = utils.getPayoutHourlyRate(tmp)
    rate = Number(rate).toFixed(2)
    //let rate = utils.getBillableHourlyRate(tmp)

    const minutesFromNow = () => {
        let diff = moment.duration(
            moment(props.shiftCollection.start).diff(moment(new Date()))
        )
        let diffMins = diff.asMinutes()
        return diffMins
    }

    React.useEffect(
        () => {
            let tmpClock = []

            let diffMins = minutesFromNow()
            let color = 'red'
            if (diffMins > 0) {
                color = 'gray'
            }
            let clockIn = (
                <div style={{ color: color }}> Please add clock-in time.</div>
            )
            if (props.shift.clockInTime) {
                clockIn = tzMoment
                    .moment(props.shift.clockInTime)
                    .format('h:mma')
                clockIn = <div> Clock-in: {clockIn}</div>
            }

            tmpClock.push(clockIn)
            let clockOut = (
                <div style={{ color: color }}>Please add clock-out time.</div>
            )
            if (props.shift.clockOutTime) {
                clockOut = tzMoment
                    .moment(props.shift.clockOutTime)
                    .format('h:mma')
                clockOut = <div> Clock-out: {clockOut}</div>
            }
            tmpClock.push(clockOut)
            setClockDetails(tmpClock)
        },
        [props.shift]
    )
    let firstCardButton = ''
    let secondCardButton = ''
    let thirdCardButton = ''
    if (!props.isPastShift) {
        firstCardButton = ''
        /*
        firstCardButton = (
            <Tooltip title="Call Nurse">
                <Button
                    onClick={e => callAssociate(e)}
                    size="small"
                    color="primary"
                >
                    <CallIcon style={{ marginRight: '0.25em' }} /> {'  '}
                </Button>
            </Tooltip>
        )
        */
        secondCardButton = (
            <Tooltip title="Cancel Nurse">
                <Button
                    onClick={e => cancelShiftDialog(e)}
                    size="small"
                    color="primary"
                >
                    <BlockIcon style={{ marginRight: '0.25em' }} />{' '}
                </Button>
            </Tooltip>
        )
    } else {
        if (props.shift.hasTimesheet) {
            thirdCardButton = (
                <Tooltip title="View Timesheet">
                    <Button
                        color="primary"
                        onClick={() => handleOpenViewTimesheetDialog()}
                    >
                        View Timesheet
                    </Button>
                </Tooltip>
            )
        } else {
            thirdCardButton = (
                <Tooltip title="Upload Signed Timesheet">
                    <Button
                        color="secondary"
                        onClick={() =>
                            props.uploadTimesheet(shiftCollection.shift)
                        }
                    >
                        Upload Timesheet
                    </Button>
                </Tooltip>
            )
        }
    }

    //setAnimateClass('animate__bounce')
    React.useEffect(
        () => {
            if (props.shift.clockInTime && props.shift.clockOutTime) {
                return
            }
            let diffMins = minutesFromNow()
            if (diffMins < 0) {
                setAnimateClass('animate__wobble')
                setInterval(function() {
                    //console.log('runnning')
                    setTimeout(function() {
                        setAnimateClass('')
                    }, 9000)
                    setAnimateClass('animate__wobble')
                }, 10000)
            }
        },
        [props.shift]
    )

    return (
        <Grid
            item
            md={props.width}
            style={{
                flex: 'none',
                display: 'flex',
                width: '100%',
                marginBottom: '2px',
                marginTop: '2px'
            }}
        >
            <Card
                style={{
                    display: 'flex',
                    width: '100%',
                    marginLeft: '2px',
                    marginRight: '2px',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}
            >
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h6">
                        {props.shift && props.shift.isNoShow
                            ? 'No-Show/Canceled'
                            : ''}
                        {!props.isPastShift ? 'Confirmed' : ''}
                    </Typography>
                    <Avatar
                        src={associate.profileImageUrl}
                        style={{
                            height: '4.5em',
                            width: '4.5em',
                            marginBottom: '0.5em',
                            display: 'inline-block'
                        }}
                    />
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        style={{ fontSize: '1.25em' }}
                    >
                        {associate.firstName} {associate.lastName},{' '}
                        {props.shiftCollection.type}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {/*
                        Experience: 3 years
                        */}
                        ${rate}
                        /hr
                    </Typography>
                    {/* removed clock in function
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                    >
                        {clockDetails}
                    </Typography>
                    */}
                    {/*
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ marginTop: '0.25em' }}
                    >
                        {associate.rating ? (
                            <Rating name="" value={associate.rating} readOnly />
                        ) : (
                            'No ratings yet'
                        )}
                    </Typography>
                    */}
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    {firstCardButton}

                    {/* removed clock in function
                    <Tooltip title="Clock-in / Clock-out Times">
                        <Button
                            onClick={openClockDialog}
                            size="small"
                            color="primary"
                        >
                            <TimerIcon
                                className={`animate__animated animate__repeat-3 animate__slower 
                                    ${animateClass}`}
                                style={{
                                    marginRight: '0.25em',
                                    fontWeight: 'bolder'
                                }}
                            />{' '}
                        </Button>
                    </Tooltip>
                    */}

                    {secondCardButton}
                    <Tooltip title="Profile, Certifications & Licenses">
                        <Button
                            onClick={e => openProfileDialog(e)}
                            size="small"
                            color="primary"
                        >
                            <AssignmentIndIcon
                                style={{ marginRight: '0.25em' }}
                            />{' '}
                        </Button>
                    </Tooltip>

                    {thirdCardButton}
                </CardActions>
            </Card>

            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Call Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Would you like to call {associate.firstName}{' '}
                        {associate.lastName}, {shiftCollection.type} now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleClose}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={callAssociate}
                        color="primary"
                        autoFocus
                    >
                        Yes, call now.
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlacedAssociateCard)
