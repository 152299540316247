import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

//import { AmplifySignOut } from '@aws-amplify/ui-react'

import AppDrawer from './AppDrawer.js'
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(1),
            width: 240
        }
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em'
    }
}))

export default function NavBar() {
    const classes = useStyles()

    return (
        <div className={classes.root} style={{ height: '4em' }}>
            <AppDrawer />
        </div>
    )
}
