import React, { useRef, useState } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../services/authService.js'
import CircularProgress from '@mui/material/CircularProgress'
import ShiftService from '../../services/nurseAdmin/shiftService.js'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
    openTimesheetDialog,
    closeTimesheetDialog,
    resetTimesheetUploadToggle
} from './timesheetSlice.js'

const mapStateToProps = state => ({
    isOpen: state.timesheet.isOpen,
    shift: state.timesheet.shift,
    shiftId: state.timesheet.shiftId,
    shiftStatus: state.timesheet.shiftStatus,
    shiftCollection: state.timesheet.shiftCollection,
    shiftRequest: state.timesheet.shiftRequest,
    doUploadTimesheet: state.timesheet.doUploadTimesheet
})

const mapDispatchToProps = dispatch => {
    return {
        openDialog: () => {
            dispatch(openTimesheetDialog())
        },

        closeDialog: () => {
            dispatch(closeTimesheetDialog())
        },

        resetTimesheetUploadToggle: () => {
            dispatch(resetTimesheetUploadToggle())
        }
    }
}

const Timesheet = props => {
    const shiftRequest = props.shiftRequest
    const shift = props.shift

    //const associate = shiftRequest.user

    //console.log(shift)
    const shiftCollection = props.shiftCollection

    const timesheetUpload = useRef(null)
    const [
        isOpenViewTimesheetDialog,
        setIsOpenViewTimesheetDialog
    ] = React.useState(false)
    const [timesheet, setTimesheetUpload] = useState('') // storing the uploaded file
    const [timesheetUrl, setTimesheetUrl] = useState('') // storing the uploaded file

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))

    const [isNoShowDialogOpen, setIsNoShowDialogOpen] = React.useState(false)
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)
    const handleViewTimesheetDialogClose = () => {
        setIsOpenViewTimesheetDialog(false)
    }

    const handleOpenViewTimesheetDialog = async () => {
        console.log('CHECKING')
        let headers = await AuthService.getHeader()

        let res
        let shiftId = props.shiftId ? props.shiftId : shift.id

        try {
            res = await axios.get(
                `/api/admin/file/timesheet/shift/${shiftId}`,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
            return
        }
        let url = res.data.url
        setTimesheetUrl(url)
        setIsOpenViewTimesheetDialog(true)
        return
    }

    const handleTimesheetUpload = async e => {
        if (props.isOpen && isLoading) {
            setIsLoading(true)
        } else {
            setIsLoadingAndModalClosed(true)
        }
        const file = e.target.files[0] // accessing file
        if (!file) {
            console.log('no file')
            return
        }
        // reset input file value
        e.target.value = null
        console.log(file)
        setTimesheetUpload(file) // storing file

        const data = new FormData()
        data.append('image', file)
        ///NOTE shift == shiftcollection and shiftcollection.shift = shift. confusing! todo fix
        let shiftId = props.shiftId ? props.shiftId : shift.id
        console.log(shift)
        //console.log(JSON.stringify(props.shift))
        //console.log(JSON.stringify(shift))
        data.append('shiftId', shiftId)

        for (let propertyName in shift) {
            data.append(propertyName, shift[propertyName])
        }

        let headers = await AuthService.getHeader()
        let res = null
        try {
            res = await axios.post(
                `/api/admin/file/timesheet/shift/${shiftId}`,
                //`/api/nurse/file/timesheet`,
                data,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        /*
        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        */

        if (props.isOpen) {
            handleCloseTimesheetDialog()
        }
        if (props.isOpen && isLoading) {
            setIsLoading(false)
        } else {
            setIsLoadingAndModalClosed(false)
        }
        //reload upload/view timesheets

        // WHY DOES THIS NOT WORK?
        await ShiftService.getAvailableShifts()
    }

    React.useEffect(
        () => {
            if (!props.isOpen) {
                // clear prior timesheet image
                setTimesheetUrl('')
                return
            }
            handleOpenViewTimesheetDialog()
        },

        [props.isOpen]
    )

    React.useEffect(
        () => {
            if (!props.doUploadTimesheet) {
                // clear prior timesheet image
                return
            }
            uploadTimesheet()
            props.resetTimesheetUploadToggle()
        },

        [props.doUploadTimesheet]
    )
    const [isLoading, setIsLoading] = React.useState(false)

    const [
        isLoadingAndModalClosed,
        setIsLoadingAndModalClosed
    ] = React.useState(false)
    const openTimesheetDialog = () => {
        props.openTimesheetDialog()
        /*
        props.openProfileDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shift
        )
        */
    }

    const handleCloseTimesheetDialog = () => {
        //setIsClockDialogOpen(false)
        props.closeDialog()
    }

    const uploadTimesheet = () => {
        timesheetUpload.current.click()
    }
    return (
        <div>
            <input
                type="file"
                accept="image/*"
                onChange={handleTimesheetUpload}
                hidden
                ref={timesheetUpload}
            />
            <Dialog
                open={props.isOpen}
                onClose={handleCloseTimesheetDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
                maxWidth={'xl'}
            >
                <DialogTitle id="alert-dialog-title">
                    Viewing Timesheet
                </DialogTitle>
                <DialogContent style={{ display: 'flex' }}>
                    <img
                        src={timesheetUrl}
                        alt=""
                        style={{
                            objectFit: 'contain',
                            maxWidth: fullScreen ? '100%' : '100%',
                            maxHeight: fullScreen ? '100%' : '100%'
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseTimesheetDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => timesheetUpload.current.click()}
                    >
                        Replace Timesheet
                    </Button>
                    <a
                        href={timesheetUrl}
                        style={{ textDecoration: 'none' }}
                        download
                    >
                        <Button color="primary" autoFocus>
                            Download Timesheet
                        </Button>
                    </a>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
            {/* only if timesheet is uploaded outside of modal */}
            {isLoadingAndModalClosed ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        left: 0,
                        height: '100vh',
                        width: '100vw',
                        zIndex: 10000000,
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        position: 'fixed',
                        top: 0,
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress size="8em" />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Timesheet)
