import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles';

import CheckIcon from '@mui/icons-material/Check'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import { connect } from 'react-redux' //Code
//import { TimePicker, DatePicker } from 'antd'
import axios from 'axios'

import UserService from '../../../services/userService.js'
//const appRoot = require('app-root-path')
//import authService from appRoot + '/services/authService.js'
import AuthService from '../../../services/authService.js'
import { DatePicker } from 'antd'
import TzMoment from '../../../plugins/TzMoment'

// all invoices use PST
const tzMoment = new TzMoment('America/Los_Angeles')

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    datePicker: {
        width: '100%',
        marginTop: '0.5em'
    },
    input: {
        marginTop: '0.5em'
    }
}))

class Invoice {
    constructor() {
        this.invoice = ''
        this.invoiceDate = tzMoment.moment(new Date())
        this.dueDate = ''
        this.fileUrl = ''
        this.isPaid = false
    }
}

const mapStateToProps = state => ({ user: state.user })

const AddInvoice = props => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [invoice, setInvoice] = useState(new Invoice())

    const fileUpload = useRef(null)
    const [file, setFile] = useState('') // storing the uploaded file

    const handleOpen = () => {
        //reset file on file open
        setFile('')
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        //console.log('name ' + name + ' value ' + value)
        setInvoice({
            ...invoice,
            [name]: value
        })
    }
    const handleInvoiceDateChange = date => {
        if (!date) {
            return
        }
        date = new Date(date)
        let dateNum = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()
        let minutes = date.getMinutes()
        let hours = date.getHours()

        let pstDate = tzMoment.moment()

        //convert date to pst equivalent
        pstDate.set({
            year: year,
            month: month,
            date: dateNum,
            hours: hours,
            minutes: minutes,
            seconds: 0,
            milliseconds: 0
        })
        //alert(pstDate.format('l LT z'))
        pstDate = pstDate.toDate()
        //alert(pstDate)

        //console.log('name ' + name + ' value ' + value)
        setInvoice({
            ...invoice,
            invoiceDate: pstDate
        })
    }
    const handleFileChange = e => {
        const file = e.target.files[0] // accessing file
        console.log(file)
        setFile(file) // storing file
    }

    const handleSubmit = async event => {
        event.preventDefault()
        if (!file) {
            alert('Please attach invoice file')
            return
        }

        setIsLoading(true)

        const data = new FormData()
        data.append('file', file)
        invoice.invoiceDate = new Date(invoice.invoiceDate)
        invoice.invoiceDate = invoice.invoiceDate.toJSON()
        //alert(invoice.invoiceDate)
        for (let propertyName in invoice) {
            data.append(propertyName, invoice[propertyName])
        }

        let facilityId = props.user.selectedFacility.id
        let headers = await AuthService.getHeader()
        let res = null
        //alert(facilityId)
        try {
            res = await axios.post(
                `/api/admin/file/invoice/${facilityId}`,
                //`/api/nurse/file/timesheet`,
                data,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        /*
        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        */

        setIsLoading(false)
        //handleCloseTimesheetDialog()

        /*
        try {
            await axios.post('/api/admin/invoice', invoice, {
                headers: headers
            })
        } catch (e) {
            //console.log(e)
            console.log(e.response)
            //console.log(e.response.data.msg)
            if (e.response && e.response.data && e.response.data.msg) {
                alert('Error: ' + e.response.data.msg)
            }
            setIsLoading(false)
            setOpen(false)
            return
        }
        */

        //reload data
        await UserService.getAccount()
        // reset request
        setInvoice(new Invoice())
        setIsLoading(false)
        setOpen(false)
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Upload Invoice
            </Button>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                fullScreen={fullScreen}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="alert-dialog-title">
                        {'Add Invoice'}
                    </DialogTitle>
                    <DialogContent>
                        <FormLabel component="legend">Invoice Number</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={invoice.invoice}
                                required
                                name="invoice"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>

                        <br />
                        <br />
                        <FormLabel component="legend">Invoice Date</FormLabel>
                        <DatePicker
                            id="datePickerContainer"
                            style={{ marginTop: '0.5em' }}
                            required
                            name="invoiceDate"
                            size="large"
                            onChange={handleInvoiceDateChange}
                            defaultValue={invoice.invoiceDate}
                            format={'dddd, MMMM D'}
                        />
                        {/*

                        <br />
                        <br />
                        <FormLabel component="legend">Due Date</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={invoice.dueDate}
                                name="Due Date"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>
                        */}
                        <br />
                        <br />
                        <FormControl fullWidth>
                            <FormLabel component="legend">Paid?</FormLabel>

                            <Select
                                id="isPaid"
                                value={invoice.isPaid}
                                name="isPaid"
                                label="Is Paid?"
                                onChange={handleInputChange}
                            >
                                <MenuItem value={false}>No</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <br />

                        <img
                            src={invoice.fileUrl}
                            alt=""
                            onClick={() => {
                                fileUpload.current.click()
                            }}
                            style={{ borderRadius: '1em' }}
                        />
                        <br />
                        <Button variant="contained" component="label">
                            Attach invoice pdf
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={handleFileChange}
                                hidden
                                ref={fileUpload}
                            />
                        </Button>
                        <span
                            style={{ marginTop: '0.5em', marginLeft: '0.5em' }}
                        >
                            {file ? <CheckIcon /> : ''}
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isLoading}
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            autoFocus
                        >
                            Upload Invoice
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default connect(
    mapStateToProps,
    null
)(AddInvoice)
