import React, { useState, useRef } from 'react'

import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import Box from '@mui/material/Box'

import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { connect } from 'react-redux' //Code
//import { TimePicker, DatePicker } from 'antd'
import { TimePicker } from 'antd'
import DatePicker from 'react-multi-date-picker'
import {
    //Settings,
    //DatePickerHeader,
    //MultiColors,
    DatePanel
    //Toolbar
} from 'react-multi-date-picker/plugins'
import axios from 'axios'
//import { Auth } from 'aws-amplify'

import userService from '../../../services/userService.js'
import authService from '../../../services/authService.js'
import Utils from '../../../helpers/utils'
//import classes from '../../ant.css'
//import moment from 'moment'
import TzMoment from '../../../plugins/TzMoment'
import { openSnackbarMessage } from '../../SnackbarMessage/snackbarMessageSlice'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    datePicker: {
        width: '100%',
        marginTop: '0.5em'
    },
    input: {
        marginTop: '0.5em'
    }
}))

/*
const minDate = new Date()
const maxDate = new Date(moment(new Date()).add(45, 'days'))
*/

const mapDispatchToProps = dispatch => {
    return {
        openSnackbarMessage: data => {
            dispatch(openSnackbarMessage(data))
        }
    }
}

const mapStateToProps = state => ({ user: state.user })

const ShiftRequest = props => {
    let tzMoment = new TzMoment(props.user.selectedFacility.timezone)

    class Request {
        constructor() {
            this.type = ''
            this.dates = []
            this.datePickerDates = []
            this.note = ''
            this.description = ''
            this.quantity = 1
            this.startTime = tzMoment.moment('9:00', 'HH:mm')
            this.endTime = tzMoment.moment('17:00', 'HH:mm')
            this.startDateTime = null
            this.endDateTime = null
            this.data = { zone: '' }
            this.isEditExisting = false
        }
    }

    const datePickerRef = useRef()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [crisisRateHelperText, setCrisisRateHelperText] = useState('')
    const [request, setRequest] = useState(new Request())

    let localDate = new Date().getDate()
    let facilityDate = tzMoment.moment(new Date()).format('D')
    let minDate = new Date(tzMoment.moment(new Date()))
    if (localDate != facilityDate) {
        minDate = new Date(tzMoment.moment(new Date()).subtract(1, 'days'))
    }
    const maxDate = new Date(tzMoment.moment(new Date()).add(45, 'days'))

    const handleOpen = () => {
        if (!props.user.selectedFacility.isEnabled) {
            alert(
                'This facility has been blocked from placing new shifts until outstanding payments have been cleared. When payment has been made, this account can be re-enabled from the settings page.'
            )
            handleClose()
            return
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const setNurseType = event => {
        let nurseType = event.target.value
        console.log('nurse type ' + nurseType)
        setRequest({ ...request, type: nurseType })
    }
    const setZone = event => {
        let zone = event.target.value
        if (zone === 'Red') {
            setCrisisRateHelperText(
                '*Crisis billing rate applies. See contract for details.'
            )
        } else {
            setCrisisRateHelperText()
        }
        console.log('zone type ' + zone)
        let data = {}
        if (request.data) {
            data = Object.assign({}, request.data)
        }
        data.zone = zone
        setRequest({ ...request, data: data })
        //props.updateShiftRequest({ ...request, data: data })
    }
    const setNote = event => {
        let txt = event.target.value
        console.log('nurse note' + txt)
        setRequest({ ...request, note: txt })
    }

    const setDescription = event => {
        let txt = event.target.value
        console.log('nurse note' + txt)
        setRequest({ ...request, description: txt })
    }

    const setQuantity = event => {
        let qty = event.target.value
        console.log('nurse qty' + qty)
        setRequest({ ...request, quantity: qty })
    }

    const setDatePickerDates = dates => {
        console.log('dates ' + dates)
        dates = dates.map(date => {
            date = new Date(date)
            return date
        })
        /*
        for (let x = 0; x < dates.length; x++) {
            console.log('per date')
            console.log(new Date(dates[x]))
        }
        */
        setRequest({ ...request, datePickerDates: dates })
    }
    const shiftStartTimeChange = time => {
        console.log(time)
        setRequest({ ...request, startTime: time })
    }
    const shiftEndTimeChange = time => {
        request.endTime = time
        setRequest({ ...request, endTime: time })
    }

    const getShiftRanges = () => {
        /*
        let startTime = moment(request.startTime).toDate()
        let sHours = startTime.getHours()
        let sMinutes = startTime.getMinutes()

        let endTime = moment(request.endTime).toDate()
        let eHours = endTime.getHours()
        let eMinutes = endTime.getMinutes()
        */
        // get date time w/o timezone attached
        let startTime = tzMoment.moment(request.startTime)
        let sHours = startTime.format('H')
        let sMinutes = startTime.format('m')

        let endTime = tzMoment.moment(request.endTime)
        let eHours = endTime.format('H')
        //let eMinutes = endTime.getMinutes()
        let eMinutes = endTime.format('m')

        let dates = request.datePickerDates.map(epochDate => {
            // date returned is in EPOCH number format and IN LOCAL MACHINE TIME. so if 12:01 local time might be pushed a day ahead/behind when converted to PST/facility time

            // pull local date w/o timezone info
            let date = new Date(epochDate)
            let dateNum = date.getDate()
            let month = date.getMonth()
            let year = date.getFullYear()

            let startDateTime = tzMoment.moment()

            // add date info to new date obj w/timezone
            startDateTime.set({
                year: year,
                month: month,
                date: dateNum,
                hours: sHours,
                minutes: sMinutes,
                seconds: 0,
                milliseconds: 0
            })
            //console.log('start time ' + startDateTime)
            /*
            alert(
                date +
                    ' | to date = ' +
                    //toDate +
                    //' | no tz start = ' +
                    //noTzStartDate +
                    ' | startdatetime = ' +
                    startDateTime +
                    ' ' +
                    startDateTime.format('l LT z') +
                    ' | shours =  ' +
                    sHours +
                    ' | ehours = ' +
                    eHours
            )
            */

            let endDateTime = startDateTime.clone()
            endDateTime.set({
                hours: eHours,
                minutes: eMinutes,
                seconds: 0,
                milliseconds: 0
            })
            //console.log('end time ' + endDateTime)
            if (startDateTime >= endDateTime) {
                //endDateTime = tzMoment.moment(endDateTime)
                endDateTime.add(1, 'days')
                //endDateTime = tzMoment.moment(endDateTime).toDate()
            }
            //console.log('start time ' + startDateTime)
            //console.log('end time ' + endDateTime)

            return [startDateTime, endDateTime]
        })
        //console.log('start time ' + startDateTime)
        //console.log('end time ' + endDateTime)

        return dates
    }
    const isShiftDurationValid = () => {
        let dates = getShiftRanges()

        let startDateTime = dates[0][0]
        let endDateTime = dates[0][1]

        let shiftDuration = tzMoment.moment.duration(
            tzMoment.moment(endDateTime).diff(tzMoment.moment(startDateTime))
        )

        let shiftLength = shiftDuration.hours()
        console.log('duration ' + shiftLength)
        if (shiftLength > 18) {
            let msg =
                'Sorry, could not place the request. The shift duration exceeds the maximum number of hours.'
            props.openSnackbarMessage({
                isOpen: true,
                message: msg,
                alertType: 'error'
            })

            setIsLoading(false)
            return false
        }

        if (shiftLength < 4) {
            let msg =
                'Sorry, the minimum shift is 4 hours. Please fix and try again.'
            props.openSnackbarMessage({
                isOpen: true,
                message: msg,
                alertType: 'error'
            })
            setIsLoading(false)
            return false
        }

        // set request start/end times
        return true
    }
    const createShiftRequest = () => {
        request.dates = getShiftRanges()

        if (
            props.user &&
            props.user.selectedFacility &&
            props.user.selectedFacility.id
        ) {
            request.facilityId = props.user.selectedFacility.id
        } else {
            console.log('error getting facility id')
            return false
        }
        console.log('facility id ' + props.user.selectedFacility.id)
        let shift = { shift: request }
        return shift
    }
    const handleSubmit = async event => {
        event.preventDefault()
        setIsLoading(true)

        if (!Utils.hasFacilities(props.user)) {
            setIsLoading(false)
            setOpen(false)
            let msg =
                'Before you can place a nurse, please go to the settings and fill out the required information about your facility first. Thank you. '
            props.openSnackbarMessage({
                isOpen: true,
                message: msg,
                alertType: 'error'
            })
            return
        }
        let headers = await authService.getHeader()

        // checks shift validity also add start/end time to shift request obj
        if (!isShiftDurationValid()) {
            return
        }

        let shiftRequest = createShiftRequest()
        console.log('shift request')
        console.log(shiftRequest)
        if (!shiftRequest) {
            console.log('error creating shift request')
            return
        }

        await axios.post('/api/facility/shift/', shiftRequest, {
            headers: headers
        })

        console.log('facility id ' + shiftRequest.shift.facilityId)
        await userService.getAccount()

        // reset request
        setRequest(new Request())
        /*
        let shifts = await axios.get('/api/facility/shift/', {
            params: { facilityId: shiftRequest.shift.facilityId },
            headers: headers
        })
        console.log('shifts' + JSON.stringify(shifts))
        */
        setIsLoading(false)
        setOpen(false)
        //using useeffect on user instead of shiftaddedcallback
        /*
        setTimeout(function() {
            console.log(
                'shift request shift collection length ' +
                    props.user.selectedFacility.shiftCollections.length
            )

            props.shiftAddedCallback()
        }, 500)
        */
    }

    const BottomPanel = ({
        state,
        handleChange,
        position,
        calendarProps,
        nodes,
        className,
        ...props
    }) => {
        return (
            <div position="bottom">
                <Button
                    onClick={() => datePickerRef.current.closeCalendar()}
                    style={{ margin: '5px' }}
                >
                    Save
                </Button>
            </div>
        )
    }
    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Request Nurse(s)
            </Button>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
            >
                <form
                    style={
                        fullScreen
                            ? {
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column'
                              }
                            : {}
                    }
                    onSubmit={handleSubmit}
                >
                    <DialogTitle id="alert-dialog-title">
                        {request.isEditExisting
                            ? 'Edit Request'
                            : 'Nurse Request'}
                    </DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText id="alert-dialog-description">
                            Nurses on our platform will apply for this.
                        </DialogContentText>
                        */}
                        <Box>
                            <Grid container justifyContent="left" spacing={3}>
                                <Grid item sm={12} xs={12}>
                                    <FormLabel component="legend" margin={2}>
                                        Date(s)
                                    </FormLabel>

                                    <DatePicker
                                        ref={datePickerRef}
                                        multiple={true}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        style={{}}
                                        required
                                        onChange={setDatePickerDates}
                                        type={'input-icon'}
                                        className="multiDatePicker"
                                        inputClass="multiDateInput"
                                        containerStyle={{
                                            width: '100%',
                                            verticalAlign: 'middle'
                                        }}
                                        placeholder={'Select Date(s)'}
                                        timePicker={true}
                                        format={'(ddd) MMM D'}
                                        plugins={[
                                            <DatePanel
                                                position="right"
                                                sort="date"
                                                eachDaysInRange
                                            />,

                                            <BottomPanel position="bottom" />
                                        ]}
                                    />
                                    {/*}
                                    <MultipleDatePicker
                                        value={dates}
                                        onChange={setDates}
                                        disabledDate={disabledDate}
                                        format={'dddd, MMMM D'}
                                        validRange={validRange}
                                    />

                                    <DatePicker
                                        id="datePickerContainer"
                                        className={classes.datePicker}
                                        required
                                        size="large"
                                        disabledDate={disabledDate}
                                        defaultValue={request.date}
                                        onChange={handleDateChange}
                                        format={'dddd, MMMM D'}
                                    />
                                    */}
                                </Grid>
                                <Grid item sm={3} xs={6}>
                                    <FormLabel component="legend">
                                        Start
                                    </FormLabel>
                                    <TimePicker
                                        minuteStep={15}
                                        className={classes.input}
                                        use12hours
                                        format="h:mm a"
                                        size="large"
                                        showNow={false}
                                        value={request.startTime}
                                        onSelect={shiftStartTimeChange}
                                    />
                                </Grid>
                                <Grid item sm={3} xs={6}>
                                    <FormLabel component="legend">
                                        End
                                    </FormLabel>
                                    <TimePicker
                                        className={classes.input}
                                        minuteStep={15}
                                        use12hours
                                        showNow={false}
                                        format="h:mm a"
                                        size="large"
                                        value={request.endTime}
                                        onSelect={shiftEndTimeChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-around" />
                        </Box>
                        <br />
                        <br />
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={6}>
                                <FormLabel component="legend">
                                    Type of nurse
                                </FormLabel>
                                <RadioGroup
                                    aria-label="type"
                                    name="nurseType"
                                    style={{ marginLeft: '0.5em' }}
                                    onChange={setNurseType}
                                    value={request.type}
                                >
                                    <FormControlLabel
                                        value="RN"
                                        required
                                        disabled={request.isEditExisting}
                                        control={<Radio required={true} />}
                                        label="RN"
                                    />
                                    <FormControlLabel
                                        value="LVN"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="LVN"
                                    />
                                    <FormControlLabel
                                        value="CNA"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="CNA"
                                    />
                                </RadioGroup>
                            </Grid>

                            <Grid item sm={6} xs={6}>
                                <FormLabel component="legend">
                                    What zone is this shift?
                                </FormLabel>
                                <RadioGroup
                                    aria-label="type"
                                    name="zone"
                                    style={{ marginLeft: '0.5em' }}
                                    onChange={setZone}
                                    value={request.data.zone}
                                >
                                    <FormControlLabel
                                        value="Green"
                                        required
                                        disabled={request.isEditExisting}
                                        control={<Radio required={true} />}
                                        label="Green"
                                    />
                                    <FormControlLabel
                                        value="Yellow"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="Yellow"
                                    />
                                    <FormControlLabel
                                        value="Red"
                                        disabled={request.isEditExisting}
                                        control={<Radio />}
                                        label="Red"
                                    />
                                    <FormHelperText>
                                        {crisisRateHelperText}
                                    </FormHelperText>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <FormLabel component="legend">
                            Number of nurses
                        </FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                type="number"
                                min={1}
                                defaultValue={request.quantity}
                                onChange={setQuantity}
                                placeholder="1"
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        {/*
                        <FormLabel component="legend">
                            Job Description
                        </FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                multiline
                                fullWidth
                                defaultValue={request.description}
                                onChange={setDescription}
                                rows={5}
                                placeholder="(optional)"
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        */}
                        <FormLabel component="legend">Shift Notes</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                multiline
                                fullWidth
                                defaultValue={request.note}
                                onChange={setNote}
                                rows={2}
                                placeholder="ex. Check-in with the Jane Doe in the front (optional)"
                                variant="outlined"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isLoading}
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            autoFocus
                        >
                            Submit
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftRequest)
