import TzMoment from '../plugins/TzMoment'
const moment = require('moment')

const utils = {
    hasFacilities: userObj => {
        if (userObj && userObj.facilities && userObj.facilities.length > 0) {
            return true
        } else {
            return false
        }
    },
    getIsFullyBooked: (bookedRequestsCount, quantity) => {
        if (bookedRequestsCount === quantity) {
            return true
        } else {
            return false
        }
    },

    roundUpHalf: num => {
        let intPart = Math.trunc(num) // returns 3
        let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
        let roundedFloat = 0
        if (floatPart >= 0.5) {
            roundedFloat = 1
        } else if (floatPart > 0) {
            roundedFloat = 0.5
        }
        let total = intPart + roundedFloat
        total = total.toFixed(1)

        return total
    },

    roundHalf: num => {
        let intPart = Math.trunc(num) // returns 3
        let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
        let rounded = Math.round(floatPart * 2) / 2

        let total = intPart + rounded
        total = total.toFixed(1)

        return total
    },
    getNurseName: selectedAssociate => {
        let name =
            'Not selected. Please select nurse by clicking "Change Nurse" above.'
        if (selectedAssociate.firstName) {
            name =
                selectedAssociate.firstName +
                ' ' +
                selectedAssociate.lastName +
                ', ' +
                selectedAssociate.type
            if (selectedAssociate.isInternallyAdministered) {
                name = name + ' (Internally Administered by NurseLab)'
            }
        }
        return name
    },
    getDuration: (end, start) => {
        let hrs = moment.duration(moment(end).diff(moment(start))).asHours()
        // minimum billing hrs is 4 hrs
        let minimumHrs = 4
        if (hrs < minimumHrs) {
            hrs = minimumHrs
        }

        // subtract lunch rates
        if (hrs >= 5) {
            hrs = hrs - 0.5
        }

        hrs = utils.roundHalf(hrs)
        return hrs
    },
    prettyFormatDateTimeRange: shift => {
        let shiftRange = shift.dateTimeRange
        let tzMoment = new TzMoment(shift.timezone)
        let start = shiftRange[0]
        let end = shiftRange[1]
        shift.start = start
        shift.end = end
        shift.date = tzMoment.moment(start).format('MMMM Do, dddd')
        shift.dateSmall = tzMoment.moment(start).format('M/D, dddd')
        shift.startTime = tzMoment.moment(start).format('h:mma')
        shift.endTime = tzMoment.moment(end).format('h:mma')

        return shift
    },
    /*
    getPayoutHourlyRate: shiftCollection => {
        let shift = shiftCollection.shift
        let payoutHourlyRate = 0
        // locked-in rate
        if (shift && shift.payoutHourlyRate) {
            payoutHourlyRate = shift.payoutHourlyRate
            if (payoutHourlyRate.toString().indexOf('.') !== -1) {
                payoutHourlyRate = parseFloat(payoutHourlyRate).toFixed(2)
            }
            return payoutHourlyRate
        }

        // base rate either top level of shift collection or in payrate obj
        // TODO clean up and standardize
        let r1 = shiftCollection.payoutHourlyRate
            ? shiftCollection.payoutHourlyRate
            : shiftCollection.payRate.payoutHourlyRate // default shift rate
        let r2 = 0 // one time shift request
        let r3 = 0

        let shiftRequest = shiftCollection.request
        if (shiftRequest && shiftRequest.customPayoutHourlyRate) {
            r2 = shiftRequest.customPayoutHourlyRate
        }

        let customFacilityRate = shiftCollection.customAssociateFacilityPayRate
        if (customFacilityRate) {
            if (customFacilityRate.isConfirmed) {
                r3 = customFacilityRate.payoutHourlyRate
            }
        }
        let tmp = [r1, r2, r3]
        tmp = tmp.map(val => {
            if (!val) {
                return 0
            }
            if (val && val.toString().indexOf('.') !== -1) {
                val = parseFloat(val).toFixed(2)
            }
            return val
        })
        payoutHourlyRate = Math.max(...tmp)
        return payoutHourlyRate
    },
    */
    getBillableHourlyRate: shiftCollection => {
        let bhr = 0
        // confirmed shift
        let isConfirmedShift = false
        if (shiftCollection.shift && shiftCollection.shift.billableHourlyRate) {
            isConfirmedShift = true
        }

        if (isConfirmedShift) {
            bhr = shiftCollection.shift.billableHourlyRate
        }

        if (!bhr) {
            let isCrisisRate = utils.getIsCrisisRate(shiftCollection)

            if (
                isCrisisRate &&
                shiftCollection.payRate.crisisBillableHourlyRate
            ) {
                bhr = shiftCollection.payRate.crisisBillableHourlyRate
            } else {
                bhr = shiftCollection.payRate.billableHourlyRate // default shift rate
            }
        }
        if (bhr && bhr.toString().indexOf('.') !== -1) {
            bhr = parseFloat(bhr).toFixed(2)
        }
        return bhr
    },
    getBasePayoutHourlyRate: shiftCollection => {
        let phr = 0

        let isCrisisRate = utils.getIsCrisisRate(shiftCollection)

        if (!isCrisisRate) {
            phr = shiftCollection.payRate.payoutHourlyRate // default shift rate
        } else {
            phr = shiftCollection.payRate.crisisPayoutHourlyRate // default shift rate
        }

        return phr
    },
    getPayoutHourlyRate: shiftCollection => {
        let phr = 0

        // if already confirmed
        let isConfirmedShift = false
        if (shiftCollection.shift && shiftCollection.shift.payoutHourlyRate) {
            isConfirmedShift = true
        }

        if (isConfirmedShift) {
            phr = shiftCollection.shift.payoutHourlyRate
            if (phr.toString().indexOf('.') !== -1) {
                phr = parseFloat(phr).toFixed(2)
            }
            return phr
        }
        if (
            shiftCollection.request &&
            shiftCollection.request.customPayoutHourlyRate
        ) {
            phr = shiftCollection.request.customPayoutHourlyRate
            if (phr.toString().indexOf('.') !== -1) {
                phr = parseFloat(phr).toFixed(2)
            }
            return phr
        }

        // TODO CLEAN UP
        let r1 = 0 // base rate
        let r2 = 0 // custom crisis rate if crisis rate exists
        let r3 = 0 // custom base rate if base rate exists

        //tmp pay rate // TODO CLEAN UP
        let tmpPR
        if (shiftCollection.payoutHourlyRate) {
            tmpPR = shiftCollection // default shift rate
        } else if (shiftCollection.payRate.payoutHourlyRate) {
            tmpPR = shiftCollection.payRate // default shift rate
        }

        // SET BASE RATE
        r1 = tmpPR.payoutHourlyRate

        let isCrisisRate = utils.getIsCrisisRate(shiftCollection)

        let cafp = shiftCollection.customAssociateFacilityPayRate
        if (isCrisisRate) {
            // UPDATE BASE RATE TO CRISIS RATE
            r1 = tmpPR.crisisPayoutHourlyRate
            if (cafp && cafp.isCrisisPayoutHourlyRateConfirmed) {
                r2 = cafp.crisisPayoutHourlyRate
                    ? cafp.crisisPayoutHourlyRate
                    : 0
            }
        }

        if (cafp && cafp.isPayoutHourlyRateConfirmed) {
            r3 = cafp.payoutHourlyRate ? cafp.payoutHourlyRate : 0
        }

        console.log('testing payout hourly rate ', r1, r2, r3)
        let tmp = [r1, r2, r3]
        tmp = tmp.map(val => {
            if (!val) {
                return 0
            }
            if (val && val.toString().indexOf('.') !== -1) {
                val = parseFloat(val).toFixed(2)
            }
            return val
        })
        phr = Math.max(...tmp)
        return phr
    },
    getIsCrisisRate: shiftCollection => {
        if (shiftCollection.data && shiftCollection.data.zone === 'Red') {
            return true
        } else {
            return false
        }
    },

    pastelColor: input_str => {
        //TODO: adjust base colour values below based on theme
        var baseRed = 128
        var baseGreen = 128
        var baseBlue = 128
        //
        //lazy seeded random hack to get values from 0 - 256
        //for seed just take bitwise XOR of first two chars
        var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1)
        var rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256
        var rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256
        var rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256

        //                                        //build colour
        var red = Math.round((rand_1 + baseRed) / 2)
        var green = Math.round((rand_2 + baseGreen) / 2)
        var blue = Math.round((rand_3 + baseBlue) / 2)

        return { red: red, green: green, blue: blue }
    }
    //                                                        }
}

export default utils
