import React from 'react'
import { connect } from 'react-redux' //Code

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import CardShift from '../CardShift/CardShift.js'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

import Grid from '@mui/material/Grid'
const moment = require('moment')

const mapStateToProps = state => ({ user: state.user, shifts: state.shifts })

const CardListings = props => {
    console.log('card listings')
    console.log(props.shifts)

    if (!props.shifts) {
        return null
    }

    if (!props.user.selectedAssociate.firstName) {
        //return 'Please select nurse first'
        return null
    }
    //todo supdate shifts to shift collection?
    let shifts = props.shifts

    let currentShifts = []
    let historicShifts = []
    let unselectedShifts = []

    let currentItems = []
    let historicItems = []
    let unselectedItems = []
    let items = []

    shifts.forEach(x => {
        if (x.isFilled && !x.shift) {
            unselectedShifts.push(x)
        } else if (moment(x.end).isAfter(new Date())) {
            currentShifts.push(x)
        } else {
            historicShifts.push(x)
        }
    })

    currentItems = currentShifts.map((shift, index) => {
        return <CardShift shift={shift} key={shift.id} />
    })

    historicItems = historicShifts.map((shift, index) => {
        /*
        if (!shift.shift) {
            console.log('error not past requested shift!')
            return
        }
        */
        return <CardShift shift={shift} key={shift.id} />
    })

    unselectedItems = unselectedShifts.map((shift, index) => {
        //return <CardShift shift={shift} key={shift.id} />
    })
    // show most recent passed shifts first
    unselectedItems.reverse()
    historicItems.reverse()

    items.push(
        <div
            key="currentShiftsHeader"
            style={{ width: '100%', marginLeft: '1.5em' }}
        >
            <h2>Upcoming Shifts</h2>
            <br />
        </div>
    )
    if (currentItems.length === 0) {
        items.push(
            <p
                key="noShifts"
                style={{ marginLeft: '1.25em', fontSize: '1.5em' }}
            >
                No shifts available to request at this time. Please check back
                soon.
            </p>
        )
    } else {
        items = items.concat(currentItems)
    }

    items.push(
        <div
            key="pastShiftsHeader"
            style={{ width: '100%', marginLeft: '1.5em' }}
        >
            <br />
            <br />
            <h2>Prior Shifts</h2>
        </div>
    )
    if (historicItems.length === 0) {
        items.push(
            <p
                key="noPastShifts"
                style={{ marginLeft: '1.25em', fontSize: '1.5em' }}
            >
                No past shifts.
            </p>
        )
    } else {
        items = items.concat(historicItems)
    }

    // unselected shifts
    if (unselectedItems.length > 0) {
        /*
        items.push(
            <div style={{ width: '100%' }}>
                <br />
                <br />
                <h2>Unselected Shifts (last 90 days)</h2>
            </div>
        )
        items = items.concat(unselectedItems)
        */
        items.push(
            <div
                key="unselectedShiftsHeader"
                style={{
                    width: '100%',
                    marginTop: '2em',
                    marginLeft: '0.6em',
                    marginRight: '0.6em'
                }}
            >
                <Accordion
                    style={{
                        backgroundColor: '#f9f9f9'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            style={{ width: '100%', marginLeft: '1em' }}
                        >
                            View Unselected Shifts (last 90 days)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            {unselectedItems}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
    return items
}

export default connect(
    mapStateToProps,
    null
)(CardListings)
