import React from 'react'
//import { useDispatch, useSelector } from 'react-redux'
//import plus_icon from "../../plus_icon.png";

import Button from '@mui/material/Button'
//import { makeStyles } from '@mui/material/styles'

import { openModal, closeModal } from './plivoModalSlice'

import { connect } from 'react-redux' //Code

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'

import PlivoService from '../../services/plivoService.js'

const mapStateToProps = state => ({
    isOpen: state.plivo.isOpen,
    toUser: state.plivo.toUser
})
const mapDispatchToProps = dispatch => {
    return {
        openModal: () => {
            dispatch(openModal())
        },

        closeModal: () => {
            dispatch(closeModal())
        }
    }
}

const Plivo = props => {
    let associate = props.toUser
    const [isLoading, setIsLoading] = React.useState(false)

    const [isDialerOpen, setDialerOpen] = React.useState(false)

    const handleClose = () => {
        props.closeModal()
    }
    const handleCall = () => {
        PlivoService.call(associate.phone)
        props.closeModal()
        setDialerOpen(true)
    }
    const handleEndCall = () => {
        PlivoService.endCall()
        setDialerOpen(false)
    }

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Call Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Would you like to call {associate.firstName}{' '}
                        {associate.lastName} now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleClose}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleCall}
                        color="primary"
                        autoFocus
                    >
                        Yes, call now.
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDialerOpen}
                onClose={handleEndCall}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Calling...</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Calling {associate.firstName} {associate.lastName},{' '}
                        {associate.type}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleEndCall}
                        color="primary"
                    >
                        End Call
                    </Button>
                    {isLoading ? <CircularProgress /> : null}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Plivo)
