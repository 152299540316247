import React, { useState } from 'react'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { connect } from 'react-redux' //Code
//import { TimePicker, DatePicker } from 'antd'
import axios from 'axios'

import UserService from '../../../services/userService.js'
//const appRoot = require('app-root-path')
//import authService from appRoot + '/services/authService.js'
import authService from '../../../services/authService.js'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    datePicker: {
        width: '100%',
        marginTop: '0.5em'
    },
    input: {
        marginTop: '0.5em'
    }
}))

class Associate {
    constructor() {
        this.firstName = ''
        this.lastName = ''
        this.type = ''
        this.phone = ''
        this.email = ''
        this.initialCredentialSource = 'nurselab'
        this.profileImageUrl = null
        this.isInternallyAdministered = true
    }
}

const mapStateToProps = state => ({ user: state.user })

const CreateAssociate = props => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    const [isOpen, setOpen] = useState(false)
    const [associate, setAssociate] = useState(new Associate())

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        //console.log('name ' + name + ' value ' + value)
        setAssociate({
            ...associate,
            [name]: value
        })
    }

    const setNurseType = event => {
        let nurseType = event.target.value
        console.log('nurse type ' + nurseType)
        setAssociate({ ...associate, type: nurseType })
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setIsLoading(true)

        let headers = await authService.getHeader()

        try {
            await axios.post('/api/admin/associate', associate, {
                headers: headers
            })
        } catch (e) {
            //console.log(e)
            console.log(e.response)
            //console.log(e.response.data.msg)
            if (e.response && e.response.data && e.response.data.msg) {
                alert('Error: ' + e.response.data.msg)
            }
            setIsLoading(false)
            setOpen(false)
            return
        }

        //reload data
        await UserService.getAccount()
        // reset request
        setAssociate(new Associate())
        setIsLoading(false)
        setOpen(false)
    }

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                {isDesktop
                    ? 'Create New (Internally Administered) Nurse'
                    : 'Add New Nurse'}
            </Button>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="alert-dialog-title">
                        {'New Nurse (Internally Administered)'}
                    </DialogTitle>
                    <DialogContent>
                        <FormLabel component="legend">First Name</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={associate.firstName}
                                required
                                name="firstName"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        <FormLabel component="legend">Last Name</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={associate.lastName}
                                required
                                name="lastName"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        <FormLabel component="legend">Phone</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={associate.phone}
                                name="phone"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        <FormLabel component="legend">Email</FormLabel>
                        <div>
                            <TextField
                                className={classes.input}
                                fullWidth
                                defaultValue={associate.email}
                                required
                                name="email"
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </div>
                        <br />
                        <br />
                        <FormLabel component="legend">Type of nurse</FormLabel>
                        <RadioGroup
                            aria-label="type"
                            name="type"
                            style={{ marginLeft: '0.5em' }}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel
                                value="RN"
                                required
                                control={<Radio required={true} />}
                                label="RN"
                            />
                            <FormControlLabel
                                value="LVN"
                                control={<Radio />}
                                label="LVN"
                            />
                            <FormControlLabel
                                value="CNA"
                                control={<Radio />}
                                label="CNA"
                            />
                        </RadioGroup>
                        <br />
                        <br />
                        <FormLabel component="legend">
                            Source of credentials
                        </FormLabel>
                        <RadioGroup
                            aria-label="initialCredentialSource"
                            name="initialCredentialSource"
                            style={{ marginLeft: '0.5em' }}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel
                                value="nurselab"
                                required
                                control={<Radio required={true} />}
                                label="nurselab"
                            />
                            <FormControlLabel
                                value="rnventory"
                                control={<Radio />}
                                label="rnventory"
                            />
                            <FormControlLabel
                                value="ezzystaffing"
                                control={<Radio />}
                                label="ezzystaffing"
                            />
                        </RadioGroup>
                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isLoading}
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            autoFocus
                        >
                            Add New Nurse
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default connect(
    mapStateToProps,
    null
)(CreateAssociate)
