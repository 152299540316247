import { createSlice } from '@reduxjs/toolkit'

export const shiftSlice = createSlice({
    name: 'shifts',
    initialState: [],
    reducers: {
        setShifts: (state, action) => {
            console.log('saving shifts')
            //console.log('Action', action.payload)
            state = action.payload
            return state
        },
        addComment: (state, action) => {
            console.log('Action', action)
            state = state.push({
                id: action.payload.id,
                comment: action.payload.comment,
                questionId: action.payload.questionId
            })
        },
        editComment: (state, action) => {
            state = state.map(comment => {
                if (comment.id === action.payload.id) {
                    return {
                        id: action.payload.id,
                        comment: action.payload.comment,
                        questionId: action.payload.questionId
                    }
                } else {
                    return {
                        ...comment
                    }
                }
            })
            return state
        },
        removeComment: (state, action) => {
            state = state.filter(comment => comment.id !== action.payload.id)

            return state
        }
    }
})

export const { setShifts } = shiftSlice.actions

//export const comments = (state) => state.comments;
//export const userState = state => state.userState
export const shifts = state => state

export default shiftSlice.reducer
