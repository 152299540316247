import React from 'react'

import { withRouter } from 'react-router-dom'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
//import Avatar from '@mui/material/Avatar'

//import SortFacilityMenu from '../SortFacilityMenu.js'
//import CreateAssociate from '../features/NurseAdmin/CreateAssociate/CreateAssociate.js'
//import AssociateTable from '../features/NurseAdmin/AssociateTable/AssociateTable.js'
import SelectAssociate from '../features/NurseAdmin/SelectAssociate/SelectAssociate.js'
import CardListings from '../features/NurseAdmin/CardListings/CardListings.js'

import withStyles from '@mui/styles/withStyles'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import Alert from '@mui/material/Alert'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ShiftService from '../services/nurseAdmin/shiftService.js'
//const moment = require('moment')

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {}
}

//TODO allow multiple users
const NurseShifts = props => {
    const [selectedNurseName, setSelectedNurseName] = React.useState('')
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    React.useEffect(
        () => {
            let name =
                'Not selected. Please select nurse by clicking "Change Nurse" above.'
            if (props.user.selectedAssociate.firstName) {
                name =
                    props.user.selectedAssociate.firstName +
                    ' ' +
                    props.user.selectedAssociate.lastName +
                    ', ' +
                    props.user.selectedAssociate.type
                if (props.user.selectedAssociate.isInternallyAdministered) {
                    name = name + ' (Internally Administered by NurseLab)'
                }
            }

            setSelectedNurseName(name)
        },
        [props.user.selectedAssociate]
    )

    React.useEffect(() => {
        // reload nurse on page load
        ShiftService.getAvailableShifts()
    }, [])
    //const { classes } = props

    let accountActivationAlert
    if (!props.user || !props.user.isActivated) {
        accountActivationAlert = (
            <Alert severity="success">
                Hi! We need to verify your account. Please reach out to Scott.
            </Alert>
        )
    } else {
        accountActivationAlert = ''
    }

    // can't use container for this because grid container overrides main container and doesn't format on mobile properly
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? 0 : '1em'
            }}
        >
            <br />
            {accountActivationAlert}
            <br />
            <SelectAssociate />
            {/*}
            <CreateAssociate />
            <br />
            <br />
            <AssociateTable />

            */}
            <br />
            <h2>For Nurse: {selectedNurseName}</h2>
            <Grid container spacing={isDesktop ? 3 : 2}>
                <CardListings />
            </Grid>
            <br />
            <br />
            <br />
        </Container>
    )
}

//export default withStyles(useStyles) connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
export default compose(
    //withStyles(useStyles),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(NurseShifts)
