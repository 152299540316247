import axios from 'axios'
import store from '../app/store'
import { setUser } from '../features/User/userSlice.js'
import AuthService from './authService.js'
import Utils from '../helpers/utils.js'

const isShiftCollectionFilled = shiftCollection => {
    let filledShifts = shiftCollection.requests.filter(
        request => request.shift && Object.keys(request.shift).length > 0
    )

    if (shiftCollection.quantity === filledShifts.length) {
        return true
    }
    return false
}

const setSelectedFacility = (
    store,
    user,
    facilities,
    oldSelectedFacilityId
) => {
    if (oldSelectedFacilityId) {
        console.log('found selected facilty')
        let updatedFacility = facilities.find(
            o => o.id.toString() === oldSelectedFacilityId.toString()
        )
        if (updatedFacility) {
            console.log('updated facility')
            user.selectedFacility = updatedFacility
        } else {
            console.log('non updated facility')
        }
    } else {
        console.log('no selected facility!!!!!!!!!!!')
        // (2) TODO make smarter; ok for now bc we don't support multi-facility
        for (let x = 0; x < facilities.length; x++) {
            let facility = facilities[x]
            user.selectedFacility = facility
        }
    }
    return user
}
const UserService = {
    getAccount: async function(accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }

        let user = null
        let res = await axios.get('/api/admin/account/', {
            headers: headers
        })
        if (res) {
            user = res.data
        }

        // ONLY LET ADMIN USERS ACCESS ADMIN PANEL
        if (!user.isActivated) {
            alert(
                'Your admin account is not yet activated. Please contact NurseLab Support for assistance. Redirecting you to Google.'
            )
            window.location.href = 'https://google.com'
        }

        let oldUser = store.getState().user
        let oldSelectedFacilityId = oldUser.selectedFacility
            ? oldUser.selectedFacility.id
            : null

        console.log('getting user')
        let facilities = user.facilities
        if (!facilities || facilities.length == 0) {
            alert('no facilities, 304 response?')
        }

        //sort alphabetically
        facilities.sort((a, b) => a.name.localeCompare(b.name))

        facilities = facilities.map(facility => {
            let shiftCollections = facility.shiftCollections
            //console.log('user service ')
            //console.log(shiftCollections)
            shiftCollections = shiftCollections.map(shiftCollection => {
                shiftCollection = Utils.prettyFormatDateTimeRange(
                    shiftCollection
                )
                //shift.status = 'Open'
                shiftCollection.isFilled = isShiftCollectionFilled(
                    shiftCollection
                )

                // TODO move out?  add empty object
                shiftCollection.requests = shiftCollection.requests.map(
                    request => {
                        if (!request.shift) {
                            request.shift = {}
                        }
                        return request
                    }
                )
                return shiftCollection
            })
            facility.shiftCollections = shiftCollections
            return facility
        })

        // set facility if facility is not selected, update facility if exists
        // (1) replace user facility w/ refreshed facility obj
        user = setSelectedFacility(
            store,
            user,
            facilities,
            oldSelectedFacilityId
        )

        //console.log('user service ' + user.selectedFacility.shifts.length)
        //
        user.associates = []
        let associates = null
        let res2 = {}
        try {
            res2 = await axios.get('/api/admin/associate/list', {
                headers: headers
            })
            //console.log('second res CONSOLE')
            //console.log(res2)
        } catch (e) {
            //console.log('second res NONE')
            console.log('error associate list get request')
            console.log(JSON.stringify(e))
            return
        }
        //console.log('second res CONSOLE')
        //console.log(res2)
        associates = res2.data
        user.associates = associates

        // re insert selected associate
        if (oldUser.selectedAssociate && oldUser.selectedAssociate.id) {
            // pull out selected associate and add to user obj
            let tmpSel = associates.find(
                associate => associate.id === oldUser.selectedAssociate.id
            )
            console.log('matching associate ')
            console.log(tmpSel)
            if (tmpSel) {
                user.selectedAssociate = tmpSel
            } else {
                user.selectedAssociate = {}
            }
        } else {
            user.selectedAssociate = {}
        }

        console.log('saving user ')
        console.log(user)

        store.dispatch(setUser(user))

        return user
    }
    /*
    getInvoice: async () => {
        let headers = await AuthService.getHeader()

        let invoice = null
        let res = await axios.get('/api/facility/account/invoice', {
            headers: headers
        })
        if (res) {
            invoice = res.data
        }
        return invoice
    }
    */
}

export default UserService
