import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

//import { withStyles } from '@mui/material/styles'

import { setUser } from '../features/User/userSlice.js'
//import userService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import { withRouter } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import axios from 'axios'

//import Utils from '../helpers/utils.js'
import TzMoment from '../plugins/TzMoment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
})

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        }
    }
}

let oldServerData = []

/*
function roundUpHalf(num) {
    let intPart = Math.trunc(num) // returns 3
    let floatPart = Number((num - intPart).toFixed(2)) // return 0.2
    let roundedFloat = 0
    if (floatPart >= 0.5) {
        roundedFloat = 1
    } else if (floatPart > 0) {
        roundedFloat = 0.5
    }
    let total = intPart + roundedFloat
    total = total.toFixed(1)

    return total
}
*/

const Payments = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const [pendingLedger, setPendingLedger] = React.useState([])
    const [currentLedger, setCurrentLedger] = React.useState([])
    const [pendingInvoiceTotal, setPendingInvoiceTotal] = React.useState(0)
    const [currentInvoiceTotal, setCurrentInvoiceTotal] = React.useState(0)

    let timezone = 'America/Los_Angeles'
    if (props.user.selectedAssociate.timezone) {
        timezone = props.user.selectedAssociate.timezone
    }
    let tzMoment = new TzMoment(timezone)

    const createRows = ledgerItems => {
        let tmpRows = []
        let bigTotal = 0

        ledgerItems.forEach(item => {
            let role = ''
            if (item.role) {
                role = item.role.shortName + ': '
            }

            let msg = item.description
            /*
        if (hrs > 5) {
            msg += '. Includes 30 min lunch break.'
        }
        */
            let total = item.amount ? item.amount : item.pendingAmount
            total = parseFloat(total).toFixed(2)
            bigTotal = (parseFloat(bigTotal) + parseFloat(total)).toFixed(2)
            let date = ''
            if (item.shiftCollection) {
                date = item.shiftCollection.start
            } else {
                date = item.created
            }

            let hrs = ''
            if (item.shiftCollection && item.shiftCollection.duration) {
                hrs = item.shiftCollection.duration
            }

            let rate = ''
            if (item.payRate && item.payRate.payoutHourlyRate) {
                rate = item.payRate.payoutHourlyRate.toFixed(2)
            }

            date = tzMoment.moment(date).format('l')
            let id = item.id

            let tmp = { id, msg, hrs, total, rate, date }
            tmpRows.push(tmp)
        })
        return [tmpRows, bigTotal]
    }
    const populateTable = ledgerItems => {
        // filter into historic and new bilings
        let pendingItems = []
        let completedItems = []
        ledgerItems.forEach(item => {
            /*
                item.shiftCollection = Utils.prettyFormatDateTimeRange(
                    item.shiftCollection
                )

*/
            let isBefore = false
            if (item.shiftCollection) {
                isBefore = tzMoment
                    .moment(new Date())
                    .isBefore(item.shiftCollection.end)
            } else {
                isBefore = tzMoment.moment(new Date()).isBefore(item.postDate)
            }

            if (isBefore) {
                pendingItems.push(item)
            } else {
                completedItems.push(item)
            }
        })

        let pendingRows = []
        let completedRows = []
        let pTotal = 0
        let cTotal = 0
        if (pendingItems.length === 0) {
            pendingRows.push({
                id: 0,
                msg: 'No pending balance',
                total: 0
            })
        } else {
            ;[pendingRows, pTotal] = createRows(pendingItems)
        }
        if (completedItems.length === 0) {
            completedRows.push({
                id: 0,
                msg: 'No balance',
                total: 0
            })
        } else {
            ;[completedRows, cTotal] = createRows(completedItems)
        }
        setPendingLedger(pendingRows)
        setPendingInvoiceTotal(pTotal)

        setCurrentLedger(completedRows)
        setCurrentInvoiceTotal(cTotal)
    }

    const pageLoad = async () => {
        let headers = await AuthService.getHeader()

        let res

        let associateId = props.user.selectedAssociate.id

        try {
            res = await axios.get(
                `/api/admin/account/payments/associate/${associateId}`,
                {
                    headers: headers
                }
            )

            console.log(res.data)
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        if (!res) {
            return
        }
        console.log(res.data)
        let ledgerItems = res.data
        oldServerData = ledgerItems
        populateTable(ledgerItems)
    }
    React.useEffect(() => {
        if (!props.user || !props.user.selectedAssociate.id) {
            alert('Please select a nurse first from nurse dashboard')
            props.history.push('/nurse')
        }
        console.log('loading billing use effect')

        populateTable(oldServerData)
        pageLoad()
    }, [])
    React.useEffect(
        () => {
            console.log('re-loading data upload')

            pageLoad()
        },
        [props.shiftCollections]
    )
    const classes = useStyles()

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />
            <br />
            <h2> Earned Money for the last 30 days</h2>
            <h5> *Earnings are paid out on the 5th and 19th every month.</h5>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                Details
                            </TableCell>
                            <TableCell align="right" />
                        </TableRow>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Hrs</TableCell>
                            <TableCell align="right">Hr/Rate</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentLedger.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg}</TableCell>

                                <TableCell align="right">{row.hrs}</TableCell>
                                <TableCell align="right">{row.rate}</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={4}>Total</TableCell>
                            <TableCell align="right">
                                {currentInvoiceTotal}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Base Rate. Time and a half is paid for shifts exceeding
            8hrs. Double time for shifts exceeding 12 hours. 30 minute lunch
            break not included in hours worked.
            */}
            <br />
            *30 minute lunch break not included in hours worked.
            <br />
            <br />
            <br />
            <br />
            <h2> Pending Money From Upcoming Shifts</h2>
            <h5>
                {' '}
                *Earnings are estimated based on anticipated hours worked.
                Earnings may be reduced if fewer hours are worked or shifts are
                canceled.
            </h5>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                Details
                            </TableCell>
                            <TableCell align="right" />
                        </TableRow>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Hrs</TableCell>
                            <TableCell align="right">Hr/Rate</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingLedger.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.msg}</TableCell>

                                <TableCell align="right">{row.hrs}</TableCell>
                                <TableCell align="right">{row.rate}</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={4}>Total</TableCell>
                            <TableCell align="right">
                                {pendingInvoiceTotal}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Base Rate. Time and a half is paid for shifts exceeding
            8hrs. Double time for shifts exceeding 12 hours. 30 minute lunch
            break not included in hours worked.
            */}
            <br />
            *30 minute lunch break not included in hours worked.
            <br />
            <br />
            <br />
        </Container>
    )
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Payments)
