import { createSlice } from '@reduxjs/toolkit'

export const timesheetSlice = createSlice({
    name: 'timesheet',
    initialState: {
        isOpen: false,
        shiftId: null,
        status: '',
        shiftCollection: {},
        shiftRequest: { user: {} },
        doUploadTimesheet: false
    },
    reducers: {
        openTimesheetDialog: (state, action, status) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            console.log('opening profile dialog in slice')
            state = {
                ...state,
                isOpen: true,
                shift: action.payload.shift ? action.payload.shift : null,
                shiftStatus: action.payload.shiftStatus,
                shiftRequest: action.payload.shiftRequest,
                shiftCollection: action.payload.shiftCollection,
                shiftId: action.payload.shiftId ? action.payload.shiftId : null
            }

            return state
        },
        toggleTimesheetUpload: (state, action) => {
            state = {
                ...state,
                shift: action.payload.shift ? action.payload.shift : null,
                shiftId: action.payload.shiftId ? action.payload.shiftId : null,
                doUploadTimesheet: true
            }
            return state
        },
        resetTimesheetUploadToggle: (state, action) => {
            state = {
                ...state,
                doUploadTimesheet: false
            }
            return state
        },
        closeTimesheetDialog: (state, action) =>
            (state = { ...state, isOpen: false, status: '' })
    }
})

export const isOpen = state => state
export const {
    openTimesheetDialog,
    closeTimesheetDialog,
    toggleTimesheetUpload,
    resetTimesheetUploadToggle
} = timesheetSlice.actions

export default timesheetSlice.reducer
