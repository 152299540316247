import React, { useState } from 'react'
import { connect } from 'react-redux' //Code

import axios from 'axios'

import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../../services/authService.js'
import CircularProgress from '@mui/material/CircularProgress'
//import ShiftService from '../../services/shiftService.js'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormControlLabel from '@mui/material/FormControlLabel'

import {
    openCustomFacilityPayDialog,
    closeCustomFacilityPayDialog
} from './customFacilityPayDialogSlice.js'

const mapStateToProps = state => ({
    isOpen: state.customFacilityPayDialog.isOpen,
    user: state.user
})

const mapDispatchToProps = dispatch => {
    return {
        openDialog: () => {
            dispatch(openCustomFacilityPayDialog())
        },

        closeDialog: () => {
            dispatch(closeCustomFacilityPayDialog())
        }
    }
}

class CustomPay {
    basePayoutHourlyRate = 0
    baseCrisisPayoutHourlyRate = 0

    maxCrisisPayoutRate = 0
    maxPayoutHourlyRate = 0

    payoutHourlyRate = null
    crisisPayoutHourlyRate = null
    isPayoutHourlyRateConfirmed = true
    isCrisisPayoutHourlyRateConfirmed = true

    constructor(rateObj) {
        if (!rateObj) {
            return
        }

        this.roleId = rateObj.roleId ? rateObj.roleId : ''
        this.rateType = rateObj.rateType ? rateObj.rateType : ''
        //user rates
        this.payoutHourlyRate = rateObj.payoutHourlyRate
            ? rateObj.payoutHourlyRate
            : null
        this.crisisPayoutHourlyRate = rateObj.crisisPayoutHourlyRate
            ? rateObj.crisisPayoutHourlyRate
            : null
        this.isCrisisPayoutHourlyRateConfirmed = rateObj.isCrisisPayoutHourlyRateConfirmed
            ? rateObj.isCrisisPayoutHourlyRateConfirmed
            : true
        this.isPayoutHourlyRateConfirmed = rateObj.isPayoutHourlyRateConfirmed
            ? rateObj.isPayoutHourlyRateConfirmed
            : true

        this.basePayoutHourlyRate = rateObj.defaultPayRate.payoutHourlyRate
        this.baseCrisisPayoutHourlyRate =
            rateObj.defaultPayRate.crisisPayoutHourlyRate

        this.billableHourlyRate = rateObj.defaultPayRate.billableHourlyRate
        this.crisisBillableHourlyRate =
            rateObj.defaultPayRate.crisisBillableHourlyRate

        this.rateObj = rateObj
    }
    calculateMaxRates() {
        this.maxPayoutHourlyRate = parseFloat(
            this.billableHourlyRate * 0.8
        ).toFixed(2)

        this.maxCrisisPayoutHourlyRate = parseFloat(
            this.crisisBillableHourlyRate * 0.8
        ).toFixed(2)
    }
}

const CustomFacilityPayDialog = props => {
    const [selectedNurseName, setSelectedNurseName] = React.useState('')
    const [facilityMenu, setFacilityMenu] = useState([]) // storing the uploaded file
    const [updatedPayRate, setUpdatedPayRate] = useState('')
    const [updatedCrisisPayRate, setUpdatedCrisisPayRate] = useState('')
    // const [hasExistingCustomRate, setHasExistingCustomRate] = useState(false)
    const [rateType, setRateType] = useState('')
    const [maxPayoutRate, setMaxPayoutRate] = useState('')
    const [defaultPayoutRate, setDefaultPayoutRate] = useState('')

    const [maxCrisisPayoutRate, setMaxCrisisPayoutRate] = useState('')
    const [crisisPayoutRate, setCrisisPayoutRate] = useState('')

    const [facilityId, setFacilityId] = useState('') // storing the uploaded file
    const [roleId, setRoleId] = useState('') // storing the uploaded file

    const [customPay, setCustomPay] = useState(new CustomPay())

    const setNurseName = () => {
        let name =
            props.user.selectedAssociate.firstName +
            ' ' +
            props.user.selectedAssociate.lastName +
            ', ' +
            props.user.selectedAssociate.type
        if (props.user.selectedAssociate.isInternallyAdministered) {
            name += ' (Internally Administered User)'
        }

        setSelectedNurseName(name)
    }

    const handleSaveUpdatedRate = async e => {
        setIsLoading(true)

        // base rate errors
        if (customPay.payoutHourlyRate) {
            if (customPay.payoutHourlyRate > customPay.maxPayoutHourlyRate) {
                setIsLoading(false)
                alert('Rate is too high. Please check. Thanks!')
                return
            } else if (
                customPay.payoutHourlyRate <
                customPay.maxPayoutHourlyRate * 0.3
            ) {
                setIsLoading(false)
                alert('Rate is too low. Please check. Thanks!')
                return
            }
        }

        // crisis rate errors
        if (customPay.crisisPayoutHourlyRate) {
            if (
                customPay.crisisPayoutHourlyRate >
                customPay.maxCrisisPayoutHourlyRate
            ) {
                setIsLoading(false)
                alert('Custom crisis rate is too high. Please check. Thanks!')
                return
            } else if (
                customPay.crisisPayoutHourlyRate <
                customPay.maxCrisisPayoutHourlyRate * 0.3
            ) {
                setIsLoading(false)
                alert('Custom crisis rate is too low. Please check. Thanks!')
                return
            }
        }

        let res = {}
        let headers = await AuthService.getHeader()
        let associateId = props.user.selectedAssociate.id

        let data = { ...customPay }
        data.facilityId = facilityId
        data.associateId = associateId
        // set empty strings to null
        if (!data.payoutHourlyRate) {
            data.payoutHourlyRate = null
        }
        if (!data.crisisPayoutHourlyRate) {
            data.crisisPayoutHourlyRate = null
        }
        //alert(JSON.stringify(data))
        try {
            res = await axios.post(
                '/api/admin/associate/customFacilityPayRate/',
                data,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert('Sorry, there was an error. Please try again later.')
        }
        setIsLoading(false)
    }
    const updateCustomPay = async event => {
        const name = event.target.name
        let value = event.target.value

        setCustomPay({
            ...customPay,
            [name]: value
        })

        return
    }
    const handleBooleanInputChange = async event => {
        const name = event.target.name
        let value = event.target.value

        setCustomPay({
            ...customPay,
            [name]: !customPay[name]
        })

        return
        /*
        setIsLoading(true)

        let res = {}
        let headers = await AuthService.getHeader()
        let associateId = props.user.selectedAssociate.id
        let data = {
            facilityId: facilityId,
            associateId: associateId,
            payRate: updatedPayRate,
            crisisPayRate: updatedCrisisPayRate,
            roleId: roleId
        }
        //TODO impelement bettter, eg allow a nurse to set their own rate, and staffer approves
        if (rateType === 'crisisRate') {
            data.isCrisisPayoutHourlyRateConfirmed = false
            data.crisisPayRate = 0
        } else {
            data.isPayoutHourlyRateConfirmed = false
            data.payRate = 0
        }
        //alert(JSON.stringify(data))
        try {
            res = await axios.post(
                '/api/admin/associate/customFacilityPayRate/',
                data,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert('Sorry, there was an error. Please try again later.')
        }

        setUpdatedPayRate('')
        setUpdatedCrisisPayRate('')
        setIsLoading(false)
        */
    }
    const handleSelectFacility = async e => {
        console.log('handle select')
        setIsLoading(true)
        setCustomPay(new CustomPay())

        //reset value
        //setHasExistingCustomRate(false)
        //setUpdatedPayRate('')
        //setUpdatedCrisisPayRate('')
        let fId = e.target.value
        setFacilityId(fId)
        let res = {}
        let headers = await AuthService.getHeader()
        let rates = []
        try {
            res = await axios.get(
                `/api/admin/associate/customFacilityPayRate/${fId}/${
                    props.user.selectedAssociate.id
                }`,
                {
                    headers: headers
                }
            )
            rates = res.data
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        //TODO support multiple rates
        rates.forEach(rateObj => {
            let tmp = new CustomPay(rateObj)
            tmp.calculateMaxRates()
            setCustomPay(tmp)
            /*
            setUpdatedPayRate(rateObj.payoutHourlyRate)
            setUpdatedCrisisPayRate(rateObj.crisisPayoutHourlyRate)
            */
            /*
            if (rateObj.payoutHourlyRate) {
                setHasExistingCustomRate(true)
            }
            */
            /*
            setRateType(rateObj.type)
            setRoleId(rateObj.roleId)

            let billRate = rateObj.defaultPayRate.billableHourlyRate
            let maxRate = parseFloat(billRate * 0.8).toFixed(2)
            let cMaxRate = parseFloat(cBillRate * 0.8).toFixed(2)
            */
            //setDefaultPayoutRate(rateObj.defaultPayRate.payoutHourlyRate)
            //setMaxPayoutRate(maxRate)

            //let cBillRate = rateObj.defaultPayRate.crisisBillableHourlyRate

            //setCrisisPayoutRate(rateObj.defaultPayRate.crisisPayoutHourlyRate)
            //setMaxCrisisPayoutRate(cMaxRate)
        })
        setIsLoading(false)
    }

    const loadFacilities = () => {
        let menuItems
        if (props.user && props.user.facilities) {
            menuItems = props.user.facilities.map(fbb => {
                let displayName = fbb.name
                if (fbb.isInternallyAdministered) {
                    displayName =
                        fbb.name + ' (Internally Administered by NurseLab)'
                }
                return (
                    <MenuItem key={fbb.id} value={fbb.id}>
                        {displayName}
                    </MenuItem>
                )
            })
        }
        setFacilityMenu(menuItems)
    }
    // handle back button + see timsehet slice  for implemntation
    /*
    React.useEffect(
        () => {
            const onPopChange = e => {
                let last = props.navStack.slice(-1)[0]

                if (last === 'file' && props.isOpen) {
                    props.popNavStack()
                    props.closeDialog()
                    showTawk()
                    window.removeEventListener('popstate', onPopChange)
                    return
                }
            }
            window.addEventListener('popstate', onPopChange)
        },
        [props.navStack]
    )
    */
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)

    const handleCloseFileDialog = () => {
        props.closeDialog()
        //window.history.back()
        //setIsFileDialogOpen(false)
    }

    React.useEffect(
        () => {
            if (!props.isOpen) {
                // clear prior timesheet image
                //setFileUrl('')
                return
            }

            setNurseName()

            // lvn, cna, rn
            let role = props.user.selectedAssociate.type
            setRateType(role)
            loadFacilities()
        },
        [props.isOpen]
    )

    const [isLoading, setIsLoading] = React.useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.isOpen}
                onClose={handleCloseFileDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                <DialogTitle id="alert-dialog-title">
                    Custom Facility Pay Rate
                </DialogTitle>

                <DialogContent style={{ minWidth: '50vw' }}>
                    <Grid container spacing={3} style={{}}>
                        <Grid item xs={12} md={12}>
                            <div>
                                <span
                                    style={{
                                        fontSize: '1.5em',
                                        color: 'black'
                                    }}
                                >
                                    Nurse:
                                    <span style={{ marginLeft: '1em' }}>
                                        {selectedNurseName}
                                    </span>
                                </span>
                            </div>
                            <br />
                            <div>
                                <span
                                    style={{
                                        fontSize: '1.5em',
                                        color: 'black'
                                    }}
                                >
                                    Select Facility:
                                </span>
                                <Select
                                    style={{
                                        marginLeft: '1em',
                                        fontSize: '1.5em',
                                        fontWeight: 'bolder'
                                    }}
                                    onChange={handleSelectFacility}
                                >
                                    {facilityMenu}
                                </Select>
                                {isLoading ? (
                                    <CircularProgress
                                        style={{ marginLeft: '1em' }}
                                    />
                                ) : null}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <span
                                style={{
                                    fontSize: '1.5em',
                                    color: 'black'
                                }}
                            >
                                Custom Hourly Rate ({rateType}
                                ):
                            </span>

                            <CurrencyTextField
                                style={{
                                    marginLeft: '1em'
                                }}
                                value={customPay.payoutHourlyRate}
                                emptyInputBehavior="null"
                                outputFormat="string"
                                decimalCharacter="."
                                name="payoutHourlyRate"
                                onKeyUp={updateCustomPay}
                                size="medium"
                                helperText={
                                    customPay.maxPayoutHourlyRate
                                        ? 'Base Facility Rate: $' +
                                          customPay.basePayoutHourlyRate +
                                          '. Max Rate: $' +
                                          customPay.maxPayoutHourlyRate +
                                          '.'
                                        : ''
                                }
                            />
                            <FormControlLabel
                                style={{
                                    marginLeft: '1.5em'
                                }}
                                control={
                                    <Switch
                                        checked={
                                            customPay.isPayoutHourlyRateConfirmed
                                        }
                                        onChange={handleBooleanInputChange}
                                        name="isPayoutHourlyRateConfirmed"
                                    />
                                }
                                label="Is rate enabled/confirmed?"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <span
                                style={{
                                    fontSize: '1.5em',
                                    color: 'black'
                                }}
                            >
                                Custom Crisis Hourly Rate ({rateType}
                                ):
                            </span>

                            <CurrencyTextField
                                style={{
                                    marginLeft: '1em'
                                }}
                                value={customPay.crisisPayoutHourlyRate}
                                outputFormat="string"
                                emptyInputBehavior="null"
                                decimalCharacter="."
                                onKeyUp={updateCustomPay}
                                name="crisisPayoutHourlyRate"
                                size="medium"
                                helperText={
                                    customPay.maxCrisisPayoutHourlyRate
                                        ? 'Crisis Facility Rate: $' +
                                          customPay.baseCrisisPayoutHourlyRate +
                                          '. Max Crisis Rate: $' +
                                          customPay.maxCrisisPayoutHourlyRate +
                                          '.'
                                        : ''
                                }
                            />
                            <FormControlLabel
                                style={{
                                    marginLeft: '1.5em'
                                }}
                                control={
                                    <Switch
                                        checked={
                                            customPay.isCrisisPayoutHourlyRateConfirmed
                                        }
                                        onChange={handleBooleanInputChange}
                                        name="isCrisisPayoutHourlyRateConfirmed"
                                    />
                                }
                                label="Is rate enabled/confirmed?"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialog} color="primary">
                        Close
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSaveUpdatedRate}
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomFacilityPayDialog)
