import React from 'react'

import { connect } from 'react-redux' //Code

import ShiftTable from '../features/FacilityAdmin/ShiftTable/ShiftTable'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

//const localizer = momentLocalizer(moment)

const CANCELED_GRAY = '#bbbbbb'
/*
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue'
        }
    })

const gradient = {
    background:
        'repeating-linear-gradient( -55deg, #222, #222 10px, #333 10px, #333 10px)'
}
*/

const mapStateToProps = state => ({ user: state.user })

const FacilityOpenShifts = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

    /*
    tzMoment.moment.locale('en', {
        week: {
            dow: 1,
            doy: 1
        }
    })
    */
    // calc - 275px width of side bar

    return (
        <span
            style={{
                width: isDesktop ? 'calc(100% - 240px)' : '100%'
                //overflowY: 'hidden'
            }}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
                <ShiftTable filterOpenShifts={true} />
            </div>
        </span>
    )
}
export default connect(
    mapStateToProps,
    null
)(FacilityOpenShifts)
