// TODO ability to add /remove facility
// ability to update multiple facilities

import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import { connect } from 'react-redux' //Code
import UserService from '../services/userService.js'
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'
import { compose } from 'redux' //Code
import { openFileDialog } from '../features/File/fileSlice'
import { openCustomFacilityPayDialog } from '../features/NurseAdmin/CustomFacilityPayDialog/customFacilityPayDialogSlice'

import CustomFacilityPayDialog from '../features/NurseAdmin/CustomFacilityPayDialog/CustomFacilityPayDialog'
import SelectAssociate from '../features/NurseAdmin/SelectAssociate/SelectAssociate.js'

import Reviews from '../features/Reviews/Reviews'

import utils from '../helpers/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

class UserSettings {
    constructor(user) {
        this.id = user.id ? user.id : ''
        this.firstName = user.firstName ? user.firstName : ''
        this.lastName = user.lastName ? user.lastName : ''
        this.address = user.address ? user.address : ''
        this.email = user.email ? user.email : ''
        this.phone = user.phone ? user.phone : ''
        this.city = user.city ? user.city : ''
        this.zipcode = user.zipcode ? user.zipcode : ''
        this.state = user.state ? user.state : ''
        this.profileImageUrl = user.profileImageUrl ? user.profileImageUrl : ''
        this.isActivated = user.isActivated ? user.isActivated : false
        this.isInternallyAdministered = user.isInternallyAdministered
            ? user.isInternallyAdministered
            : false
        this.isEnabled = user.isEnabled ? user.isEnabled : false
        this.isEmailEnabled = user.isEmailEnabled ? user.isEmailEnabled : false
        this.isSmsEnabled = user.isSmsEnabled ? user.isSmsEnabled : false
        this.userNote = user.userNote ? user.userNote : {}
        this.isTestUser = user.isTestUser ? user.isTestUser : false
    }
}
const styles = {
    textField: {
        width: '100%',
        marginBottom: '0.75em'
    }
}

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        openFileDialog: (resourceType, associate) => {
            dispatch(
                openFileDialog({
                    resourceType: resourceType,
                    associate: associate
                })
            )
        },
        openCustomFacilityPayDialog: () => {
            dispatch(openCustomFacilityPayDialog())
        }
    }
}
const Settings = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const [selectedNurseName, setSelectedNurseName] = React.useState('')
    const fileUpload = useRef(null)
    const [file, setFile] = useState('') // storing the uploaded file
    const [userSettings, setUserSettings] = useState(
        new UserSettings(props.user.selectedAssociate)
    )

    const setNurseName = () => {
        let name = utils.getNurseName(props.user.selectedAssociate)
        setSelectedNurseName(name)
    }
    React.useEffect(() => {
        if (!props.user || !props.user.selectedAssociate.id) {
            alert('You must select a nurse first')
            //props.history.push('/nurse')
        }
        //setNurseName()
        // on page load scroll to top, fix buggy nav
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(
        () => {
            let tmp = props.user.selectedAssociate
                ? props.user.selectedAssociate
                : {}
            let settings = new UserSettings(tmp)
            setUserSettings(settings)

            setNurseName()
        },
        [props.user.selectedAssociate]
    )
    const [isLoading, setIsLoading] = useState(false)
    const handleInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        //console.log('name ' + name + ' value ' + value)
        setUserSettings({
            ...userSettings,
            [name]: value
        })
    }
    const handleOpenFileDialog = async resourceType => {
        let associate = props.user.selectedAssociate

        props.openFileDialog(resourceType, associate)
    }
    const handleNoteChange = event => {
        //const name = event.target.name
        const value = event.target.value
        console.log('value of note ' + value)
        //console.log('name ' + name + ' value ' + value)
        setUserSettings({
            ...userSettings,
            userNote: { text: value }
        })
    }
    const handleBooleanInputChange = event => {
        const name = event.target.name
        let value = event.target.value
        console.log('name ' + name + ' value ' + value)
        console.log(!userSettings[name])
        setUserSettings({
            ...userSettings,
            [name]: !userSettings[name]
        })
    }

    const handleFileChange = e => {
        const file = e.target.files[0] // accessing file
        console.log(file)
        setFile(file) // storing file

        // auto save user data on file upload
        updateUserSettings()
    }

    const updateUserSettings = async event => {
        if (event) {
            event.preventDefault()
        }
        console.log(userSettings.name)
        //console.log(this.state)
        setIsLoading(true)
        let res = null

        const data = new FormData()
        data.append('image', file)
        for (let propertyName in userSettings) {
            if (propertyName === 'userNote') {
                data.append(
                    propertyName,
                    JSON.stringify(userSettings[propertyName])
                )
            } else {
                data.append(propertyName, userSettings[propertyName])
            }
        }

        let headers = await AuthService.getHeader()
        try {
            res = await axios.put(
                `/api/admin/associate/account/${
                    props.user.selectedAssociate.id
                }`,
                data,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        try {
            if (res) {
                let tmpUser = await UserService.getAccount()
                //reload settings /images
                //setUserSettings(new UserSettings(tmpUser))
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        setIsLoading(false)
    }
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginTop: isDesktop ? 0 : '1em',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />
            <SelectAssociate />
            <br />
            <h2>For Nurse: {selectedNurseName}</h2>
            <br />
            <h2>Settings</h2>
            <br />

            <form onSubmit={updateUserSettings}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={userSettings.firstName}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="First Name"
                            name="firstName"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={userSettings.lastName}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Last Name"
                            name="lastName"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id=""
                            value={userSettings.email}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Email"
                            name="email"
                            required
                            disabled
                            variant="outlined"
                            helperText="If email needs to change contact support"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id=""
                            value={userSettings.phone}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Phone"
                            name="phone"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={userSettings.address}
                            onChange={handleInputChange}
                            label="Address"
                            name="address"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={userSettings.city}
                            onChange={handleInputChange}
                            style={styles.textField}
                            label="City"
                            name="city"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={userSettings.state}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="State"
                            name="state"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            style={styles.textField}
                            value={userSettings.zipcode}
                            onChange={handleInputChange}
                            label="Zipcode"
                            name="zipcode"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            style={styles.textField}
                            value={userSettings.userNote.text}
                            onChange={handleNoteChange}
                            label="Note (only NurseLab admins can see)"
                            name="note"
                            multiline
                            rows={4}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Profile Picture
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Avatar
                            alt=""
                            style={{ height: '5em', width: '5em' }}
                            src={userSettings.profileImageUrl}
                            onClick={() => {
                                fileUpload.current.click()
                            }}
                        />
                        <br />
                        <Button variant="contained" component="label">
                            Upload Profile Image
                            <input
                                type="file"
                                ref={fileUpload}
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden
                            />
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Records
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        {/*
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpload}
                            hidden
                            ref={resourceUpload}
                        />
                        */}
                        {/*
                            onClick={() => resourceUpload.current.click()}
                            */}
                        <Button
                            onClick={() => handleOpenFileDialog('bls')}
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                        >
                            Update BLS
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            onClick={() => handleOpenFileDialog('tb')}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                        >
                            TB Test Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => handleOpenFileDialog('hepatitis')}
                        >
                            Hepatitis Vaccination Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('flu')}
                            disabled={isLoading}
                        >
                            Flu Immunization Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('covid')}
                            disabled={isLoading}
                        >
                            Covid Vaccine Record
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            onClick={() => handleOpenFileDialog('nurseLicense')}
                            disabled={isLoading}
                        >
                            Nurse License
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Reviews
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Reviews
                            userId={
                                props.user &&
                                props.user.selectedAssociate &&
                                props.user.selectedAssociate.id
                                    ? props.user.selectedAssociate.id
                                    : null
                            }
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h6" gutterBottom>
                            Account Status
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isActivated}
                                    onChange={handleBooleanInputChange}
                                    name="isActivated"
                                />
                            }
                            label="Activated Account"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isEnabled"
                                />
                            }
                            label="Enabled Account (user is suspended when account is disabled)"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        userSettings.isInternallyAdministered
                                    }
                                    onChange={handleBooleanInputChange}
                                    name="isInternallyAdministered"
                                />
                            }
                            label="Account Administered By NurseLab (on behalf of nurse, eg nurse does not want to or cannot create an account themself)"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isTestUser}
                                    onChange={handleBooleanInputChange}
                                    name="isTestUser"
                                />
                            }
                            label="Account is a NurseLab Test User"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Notifications
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isEmailEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isEmailEnabled"
                                />
                            }
                            label="Email Notifications"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userSettings.isSmsEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isSmsEnabled"
                                    disabled={
                                        userSettings.isInternallyAdministered
                                    }
                                />
                            }
                            label="Sms Notifications (can not be enabled if account is administered by NurseLab)"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Custom Pay
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Button
                            variant="contained"
                            component="label"
                            onClick={() => props.openCustomFacilityPayDialog()}
                        >
                            Set Custom Facility Pay Rate for {selectedNurseName}
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <Button
                            type="submit"
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </Grid>
                </Grid>
            </form>
            <CustomFacilityPayDialog />
            <br />
            <br />
            <br />
        </Container>
    )
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Settings)
