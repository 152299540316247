import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux' //Code

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import JobListing from '../JobListing/JobListing.js'
//import SortFacilityMenu from '../../SortFacilityMenu.js'
const moment = require('moment')

const mapStateToProps = state => ({ user: state.user })

const JobListings = props => {
    const reload = useCallback(
        () => {
            if (!props.user || !props.user.selectedFacility) {
                return null
            }
            let selectedFacility = props.user.selectedFacility

            let shiftCollections = selectedFacility.shiftCollections

            if (!Array.isArray(shiftCollections)) {
                console.log('ERRROR shift collectiosn not array')
                return []
            }
            let displayShiftCollections = shiftCollections.map(x => {
                //let y = Object.assign({}, x)
                let y = JSON.parse(JSON.stringify(x))
                return y
            })

            let currentShifts = []
            let historicShifts = []
            let currentItems = []
            let historicItems = []
            let items = []

            displayShiftCollections.forEach(x => {
                if (moment(x.end).isAfter(new Date())) {
                    currentShifts.push(x)
                } else {
                    historicShifts.push(x)
                }
            })

            currentItems = currentShifts.map((shiftCollection, index) => {
                return (
                    <JobListing
                        key={shiftCollection.id}
                        shiftCollection={shiftCollection}
                        isPastShift={false}
                    />
                )
            })

            historicItems = historicShifts.map((shiftCollection, index) => {
                return (
                    <JobListing
                        key={shiftCollection.id}
                        shiftCollection={shiftCollection}
                        isPastShift={true}
                    />
                )
            })
            // show most recent passed shifts first
            historicItems.reverse()

            items.push(
                <div key="upcomingShiftsHeader">
                    <h2>Upcoming Shifts</h2>
                </div>
            )
            if (currentItems.length === 0) {
                items.push(
                    <h3 key="noUpcomingShifts">
                        No upcoming shift requests. Request a nurse by clicking
                        the "Request Nurse(s)" button above.{' '}
                    </h3>
                )
            } else {
                items = items.concat(currentItems)
            }

            items.push(
                <div key="completedShiftsHeader">
                    <br />
                    <br />
                    <h2>Completed Shifts</h2>
                </div>
            )
            if (historicItems.length === 0) {
                items.push(<h3 key="noPastShifts">No past shifts.</h3>)
            } else {
                items = items.concat(historicItems)
            }
            return items
        },
        [props.user]
    )

    let tmp = reload()

    useEffect(
        () => {
            console.log('reloading listings')
            reload()
        },
        [props.user.selectedFacility, reload]
    )
    return tmp
}

export default connect(
    mapStateToProps,
    null
)(JobListings)
