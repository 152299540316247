import React, { useState, useRef } from 'react'
import { connect } from 'react-redux' //Code

import Card from '@mui/material/Card'
//import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'

import Typography from '@mui/material/Typography'
import axios from 'axios'

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import AuthService from '../../../services/authService.js'
//import userService from '../../services/userService.js'
import ShiftService from '../../../services/nurseAdmin/shiftService.js'
import CircularProgress from '@mui/material/CircularProgress'
//import CallIcon from '@mui/icons-material/Call'
//import DescriptionIcon from '@mui/icons-material/Description'
//import NoteIcon from '@mui/icons-material/Note'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import NoteIcon from '@mui/icons-material/Note'
import DescriptionIcon from '@mui/icons-material/Description'
import InfoIcon from '@mui/icons-material/Info'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import ScheduleIcon from '@mui/icons-material/Schedule'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import Tooltip from '@mui/material/Tooltip'

import { openModal, closeModal } from '../../PlivoModal/plivoModalSlice'
import {
    openTimesheetDialog,
    toggleTimesheetUpload
} from '../../Timesheet/timesheetSlice'

import { useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import utils from '../../../helpers/utils.js'

import { openSnackbarMessage } from '../../SnackbarMessage/snackbarMessageSlice'

import CancelShiftDialog from '../CancelShiftDialog/CancelShiftDialog'

const moment = require('moment')

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        plivoCall: shift => {
            dispatch(openModal(shift))
        },
        closeModal: () => {
            dispatch(closeModal())
        },
        uploadTimesheet: shift => {
            let data = {
                shift: shift
            }
            dispatch(toggleTimesheetUpload(data))
        },
        openTimesheetDialog: (shiftCollection, shiftRequest, shift) => {
            console.log('opening timesheet dialog')

            let data = {
                shift: shift,
                shiftRequest: shiftRequest,
                shiftCollection: shiftCollection
            }
            dispatch(openTimesheetDialog(data))
        },
        openSnackbarMessage: data => {
            dispatch(openSnackbarMessage(data))
        }
    }
}

const CardShift = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    /* popover */
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
    const [popoverText, setPopoverText] = React.useState('')
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
    /* end pop over */

    //THIS IS REALLY SHIFT COLLECTION AND NOT SHIFT
    let shift = props.shift

    let isPastShift = false
    if (!moment(shift.end).isAfter(new Date())) {
        isPastShift = true
    }
    const [
        isOpenRequestShiftDialog,
        setIsOpenRequestShiftDialog
    ] = React.useState(false)
    const [
        isOpenRequestConfirmationDialog,
        setIsOpenRequestConfirmationDialog
    ] = React.useState(false)
    const [cancelDialogState, setCancelDialogState] = React.useState(false)
    const [cancelAction, setCancelAction] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    //set hourly rate
    let payoutHourlyRate = utils.getPayoutHourlyRate(props.shift)
    payoutHourlyRate = Number(payoutHourlyRate).toFixed(2)

    const handleCallFacility = shift => {
        props.plivoCall(shift)
    }

    const handleOpenNurseShiftRequest = shiftId => {
        setIsOpenRequestShiftDialog(true)
        return
    }
    const handleOpenCancelNurseShiftRequest = (shiftId, action) => {
        // action == 'cancelShift' or 'cancelShiftRequest'
        setCancelDialogState(true)
        setCancelAction(action)
    }

    const handleCloseCancelDialog = () => {
        setCancelDialogState(false)
    }
    const handleOpenViewTimesheetDialog = async shiftId => {
        /*
        props.openTimesheetDialog(
            props.shiftCollection,
            props.associateRequest,
            props.shiftCollection.shift
        )
        */
        props.openTimesheetDialog(
            props.shift,
            props.associateRequest,
            props.shift.shift
        )
    }
    const handleRequestShiftDialogClose = () => {
        setIsOpenRequestShiftDialog(false)
    }
    const handleCloseRequestConfirmationDialog = () => {
        setIsOpenRequestConfirmationDialog(false)
    }
    const handleRequestShift = async () => {
        setIsLoading(true)
        let headers = await AuthService.getHeader()
        //console.log(shiftId)
        let shiftData = Object.assign({}, shift)
        shiftData.action = 'request'

        shiftData.associateId = props.user.selectedAssociate.id
        console.log('assocaite id ' + shiftData.associateId)

        await axios.post(`/api/admin/shift/request`, shiftData, {
            headers: headers
        })

        let msg = `Shift is requested! We will let the ${
            shift.facilityName
        } that you have requested this shift.`
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success'
        })

        //reload user data
        setIsLoading(false)
        setIsOpenRequestShiftDialog(false)
        await ShiftService.getAvailableShifts()
    }

    let cardStyle = {
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
        borderColor: '#D3D3D3',
        borderStyle: 'solid',
        borderWidth: '1px'
    }

    let shiftStatus = ''
    let firstCardButton = ''
    let secondCardButton = ''

    if (shift.isNoShow) {
        cardStyle.backgroundColor = '#ffbaba'
        shiftStatus = <span style={{ color: 'red' }}> No-Show/Canceled</span>
        firstCardButton = ''
    } else if (shift.isCanceled) {
        cardStyle.backgroundColor = '#cccccc'
        shiftStatus = <span style={{ color: 'black' }}> Canceled </span>
        firstCardButton = ''
    } else if (isPastShift) {
        cardStyle.backgroundColor = '#E1EABF'
        shiftStatus = <span style={{ color: 'green' }}> Completed </span>
        firstCardButton = ''
        if (shift.shift && shift.shift.hasTimesheet) {
            secondCardButton = (
                <Tooltip title="View Timesheet">
                    <Button
                        variant="contained"
                        onClick={() => handleOpenViewTimesheetDialog()}
                    >
                        View Timesheet
                    </Button>
                </Tooltip>
            )
        } else {
            secondCardButton = (
                <Tooltip title="Upload Signed Timesheet">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.uploadTimesheet(shift.shift)}
                    >
                        Upload Timesheet
                    </Button>
                </Tooltip>
            )
        }
    } else if (shift.isConfirmed) {
        //#98c06e
        cardStyle.backgroundColor = '#E1EABF'
        shiftStatus = <span style={{ color: 'green' }}> Confirmed </span>
        firstCardButton = (
            <Tooltip title="Cancel Shift">
                <Button
                    variant="outlined"
                    onClick={() =>
                        handleOpenCancelNurseShiftRequest(
                            shift.id,
                            'cancelShift'
                        )
                    }
                >
                    Cancel Shift
                </Button>
            </Tooltip>
        )

        /*
        secondCardButton = (
            <Tooltip title="Call Facility">
                <Button onClick={() => handleCallFacility(shift)}>
                    <CallIcon style={{ marginRight: '0.25em' }} /> {'  '}
                    Facility
                </Button>
            </Tooltip>
        )
        */
        //rejected
    } else if (shift.isFilled && !shift.shift) {
        cardStyle.backgroundColor = '#B2BEC3'
        shiftStatus = (
            <span style={{ color: '#2d3436' }}>
                {' '}
                Requested, But Filled by Another Nurse
            </span>
        )
    } else if (shift.isPending) {
        // #DFEBCA
        // #DCEEBD
        cardStyle.backgroundColor = '#FDFCCC'
        shiftStatus = (
            <span style={{ color: 'orange' }}> Awaiting Confirmation</span>
        )

        firstCardButton = (
            <Tooltip title="Cancel Shift Request">
                <Button
                    variant="outlined"
                    onClick={() =>
                        handleOpenCancelNurseShiftRequest(
                            shift.id,
                            'cancelShiftRequest'
                        )
                    }
                >
                    Cancel Shift Request
                </Button>
            </Tooltip>
        )
    } else {
        shiftStatus = 'Available'

        firstCardButton = (
            <Tooltip title="Request shift with facility">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenNurseShiftRequest(shift.id)}
                >
                    Request Shift
                </Button>
            </Tooltip>
        )
    }

    const handlePopoverOpen = (event, text) => {
        setPopoverText(text)
        setPopoverAnchorEl(event.currentTarget)
        setIsPopoverOpen(true)
    }
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        setIsPopoverOpen(false)
    }

    let otRate = payoutHourlyRate * 1.5
    otRate = (Math.round(otRate * 100) / 100).toFixed(2)
    let payTmp = (
        <span>
            ${payoutHourlyRate}
            /hr. Based on {shift.duration} hrs.
            <br />
            30 minute break not paid.
            <br />
            Time worked past 8 hrs compensated at ${otRate}
            /hr.
        </span>
    )
    let userTotal = shift.userTotal
    if (!Number.isInteger(userTotal)) {
        if (userTotal) {
            userTotal = userTotal.toFixed(2)
        } else {
            userTotal = 'Reload Nurse (or contact scott to fix)'
        }
    }
    let payDetails = (
        <span
            style={{ color: 'blue' }}
            onMouseEnter={e => handlePopoverOpen(e, payTmp)}
            onMouseLeave={handlePopoverClose}
        >
            ${userTotal}{' '}
            <span style={{ color: 'blue', fontSize: '0.5em' }}>Details</span>
        </span>
    )
    let description = null
    if (shift.description) {
        description = <span>📘 Description: {shift.description}</span>
        if (shift.description.length > 50) {
            let synopsis = shift.description.substring(0, 50) + '... '
            description = (
                <span>
                    📘 Description: {synopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={e =>
                            handlePopoverOpen(e, shift.description)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </span>
            )
        }
    }
    let note = null
    if (shift.note && shift.note.length > 0) {
        note = (
            <div style={{ marginTop: '0.6em' }}>
                <NoteIcon
                    style={{
                        verticalAlign: 'text-top',
                        fontSize: '1.25em',
                        color: '#606060',
                        marginRight: '0.35em'
                    }}
                />

                {shift.note}
            </div>
        )

        if (shift.note.length > 50) {
            let noteSynopsis = shift.note.substring(0, 50) + '... '
            note = (
                <span>
                    <NoteIcon
                        style={{
                            verticalAlign: 'text-top',
                            fontSize: '1.25em',
                            color: '#606060',
                            marginRight: '0.35em'
                        }}
                    />
                    {noteSynopsis}
                    <span
                        style={{ color: 'blue' }}
                        onMouseEnter={e => handlePopoverOpen(e, shift.note)}
                        onMouseLeave={handlePopoverClose}
                    >
                        read more
                    </span>
                </span>
            )
        }
    }
    let facilityColor = utils.pastelColor(shift.facilityName)

    let bg = `rgb(${facilityColor.red}, ${facilityColor.green}, ${
        facilityColor.blue
    })`

    let facilityLogoSmall = shift.logoUrl ? (
        <img
            component="img"
            style={{
                objectFit: 'contain',
                display: 'inline-block',
                maxWidth: '70px',
                height: '43px',
                marginRight: '0.75em',
                left: '0'
            }}
            src={shift.logoUrl}
            alt={shift.facilityName}
        />
    ) : (
        <Avatar
            variant="rounded"
            style={{
                fontWeight: 'bolder',
                height: '43px',
                marginRight: '0.75em'
            }}
            sx={{ bgcolor: bg }}
        >
            {shift.facilityName.charAt(0)}
        </Avatar>
    )

    let gridItemSize = 3
    if (props.width ? (gridItemSize = props.width) : (gridItemSize = 3))
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={gridItemSize}>
                <Card
                    className={shift.isPending ? 'pending' : ''}
                    style={cardStyle}
                    elevation={1}
                >
                    <div
                        style={
                            !isDesktop
                                ? {
                                      margin: 'auto',
                                      textAlign: 'left'
                                  }
                                : {}
                        }
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            style={{
                                textAlign: 'left',
                                width: '100%',
                                marginTop: '0.2em',
                                marginLeft: '0.5em',
                                fontSize: '1em'
                            }}
                        >
                            {isDesktop ? (
                                <span>
                                    {shiftStatus}
                                    <span
                                        style={{
                                            marginRight: '1em',
                                            float: 'right'
                                        }}
                                    >
                                        {shift.dateSmall}
                                    </span>
                                </span>
                            ) : (
                                <span>
                                    {shiftStatus}
                                    <span
                                        style={{
                                            marginRight: '1em',
                                            float: 'right'
                                        }}
                                    >
                                        {shift.dateSmall}
                                    </span>
                                </span>
                            )}
                        </Typography>
                        <hr
                            style={{
                                borderColor: '#E8E8E8',
                                marginTop: '0.25em',
                                marginBottom: '0.25em'
                            }}
                        />

                        <div
                            style={{
                                objectFit: 'contain',
                                fontWeight: 'bolder',
                                fontSize: '1.3em',
                                textAlign: 'left',
                                minHeight: isDesktop ? '3em' : '2em',
                                paddingTop: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                marginRight: '1em',
                                marginLeft: '0.5em'
                            }}
                        >
                            {facilityLogoSmall}
                            {shift.facilityName}
                        </div>

                        <CardContent>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{}}
                            >
                                <AccessTimeIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        color: '#606060',
                                        marginRight: '0.35em'
                                    }}
                                />{' '}
                                {'  '}
                                {shift.startTime} - {shift.endTime}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em'
                                }}
                            >
                                <WysiwygIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        color: '#606060',
                                        marginRight: '0.35em'
                                    }}
                                />{' '}
                                <span style={{ paddingRight: '0.45em' }}>
                                    {payDetails}
                                </span>
                                <span
                                    style={{ paddingRight: '0.15em' }}
                                    aria-label="position"
                                />
                                {shift.type}
                                {shift.data && shift.data.zone ? (
                                    <span
                                        style={{ paddingLeft: '0.75em' }}
                                        aria-label="position"
                                    >
                                        {shift.data.zone} zone
                                    </span>
                                ) : null}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em'
                                }}
                            >
                                {description}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em'
                                }}
                            >
                                {note}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{
                                    marginTop: isDesktop ? '0.5em' : '0.3em'
                                }}
                            >
                                <CorporateFareIcon
                                    style={{
                                        verticalAlign: 'text-top',
                                        fontSize: '1.25em',
                                        marginRight: '0.35em',
                                        color: '#606060'
                                    }}
                                />
                                {'   '}
                                <span>
                                    {shift.address}, {shift.city},{' '}
                                    {shift.zipcode}
                                </span>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {firstCardButton}
                            {/*style={{ marginLeft: '3em' }}*/}
                            {secondCardButton}
                        </CardActions>
                    </div>
                    <Popover
                        id="mouse-over-popover"
                        open={isPopoverOpen}
                        anchorEl={popoverAnchorEl}
                        style={{
                            pointerEvents: 'none',
                            maxWidth: '500px',
                            minWidth: '70%'
                        }}
                        disableRestoreFocus
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <Typography style={{ padding: '1em' }}>
                            {popoverText}
                        </Typography>
                    </Popover>
                </Card>
                <Dialog
                    open={isOpenRequestShiftDialog}
                    onClose={handleRequestShiftDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Request Shift?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This shift is from{' '}
                            <b>
                                {shift.startTime} to {shift.endTime}
                            </b>{' '}
                            on <b>{shift.date}</b> at{' '}
                            <b>{shift.facilityName}</b> located on{' '}
                            <b>
                                {shift.address}, {shift.city}, {shift.zipcode}
                            </b>
                            .<br />
                            The hourly rate for this shift is $
                            {payoutHourlyRate}.<br /> <br />
                            Would you like to request this shift?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleRequestShiftDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRequestShift}
                            color="primary"
                            autoFocus
                        >
                            Yes, request shift!
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isOpenRequestConfirmationDialog}
                    onClose={handleCloseRequestConfirmationDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Shift Requested
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Shift is requested at {shift.facilityName} for this
                            nurse.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseRequestConfirmationDialog}
                            color="primary"
                        >
                            Got it
                        </Button>

                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </Dialog>
                {/*
                <Dialog
                    open={cancelDialogState}
                    onClose={handleCloseCancelDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {cancelDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {cancelDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseCancelDialog}
                            color="primary"
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleConfirmCancelDialog}
                            color="primary"
                        >
                            Yes
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </DialogActions>
                </Dialog>
                */}
                <CancelShiftDialog
                    isOpen={cancelDialogState}
                    cancelAction={cancelAction}
                    shiftId={shift.id}
                    shift={shift}
                    handleClose={handleCloseCancelDialog}
                />
            </Grid>
        )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardShift)
