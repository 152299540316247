const plivo = require('plivo-browser-sdk')
const plivoOptions = {
    debug: 'DEBUG',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000
}
/*
var plivoOptions = {
    debug: 'DEBUG',
    permOnClick: false,
    codecs: ['OPUS', 'PCMU'],
    enableIPV6: false,
    audioConstraints: {
        optional: [
            {
                googAutoGainControl: false
            }
        ]
    },
    dscp: true,
    enableTracking: true,
    closeProtection: false,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: false
}
*/

const plivoUsername = 'facility219301059381274721'
const plivoPassword = 'H3llo2020!'
const plivoBrowserSdk = new plivo(plivoOptions)

var toCall = null
let onCall = false
const onLogin = () => {
    if (toCall) {
        let to = toCall
        call(to)
        toCall = null
    }
}

const call = phone => {
    console.log('making call now')
    console.log('calling out  to phone ' + phone)

    onCall = true
    plivoBrowserSdk.client.call(phone)
}

const callTerminated = () => {
    console.log('call terminated callback fired')
    onCall = false
    //console.log('call hung up, logging out')
    //plivoBrowserSdk.client.logout()
    // BUG in plivo code, must wait a bit before calling logout on call closure
    setTimeout(function() {
        // only logout if not in existing call
        if (onCall === false) {
            plivoBrowserSdk.client.logout()
        }
    }, 1000)
}
plivoBrowserSdk.client.on('onLogin', onLogin)

plivoBrowserSdk.client.on('onLogout', function() {
    console.log('logged out of plivo')
})
plivoBrowserSdk.client.on('onCallTerminated', callTerminated)

plivoBrowserSdk.client.on('onCallFailed', function(e) {
    console.log('call failed')
    console.log(JSON.stringify(e))
})

const PlivoService = {
    // if not logged in, log in, then call
    call: function(phoneNumber) {
        console.log('calling phone ')
        if (!plivoBrowserSdk.client.isLoggedIn) {
            toCall = phoneNumber
            console.log('loggin in now')
            plivoBrowserSdk.client.login(plivoUsername, plivoPassword)
        } else {
            console.log('calling now')
            call(phoneNumber)
        }
    },

    endCall: function() {
        console.log('hanging up call')
        try {
            if (plivoBrowserSdk.client.callSession) {
                //plivoBrowserSdk.client.logout()
                plivoBrowserSdk.client.hangup()
            } else {
                console.log('ERRROR NO CALL SESSION')

                //plivoBrowserSdk.client.logout()
                plivoBrowserSdk.client.hangup()
            }

            //plivoBrowserSdk.client.logout()
        } catch (e) {
            console.log('error hanging up ' + JSON.stringify(e))
        }
    }
}

export default PlivoService
