import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux' //Code

import { compose } from 'redux' //Code
import { setUser, resetUser } from '../../../features/User/userSlice.js'
import { setShifts } from '../../../features/NurseAdmin/Shift/shiftSlice.js'

import { withRouter } from 'react-router-dom'
//import MaterialTable from 'material-table'
import MaterialTable from 'material-react-table'

import ShiftService from '../../../services/nurseAdmin/shiftService.js'
import withStyles from '@mui/styles/withStyles'

import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

// for created date use local computer timezone to assist staffers on newest
const moment = require('moment')

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        resetUser: () => {
            dispatch(resetUser())
        },
        setShifts: shifts => {
            dispatch(setShifts(shifts))
        }
    }
}
const useStyles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
})

const ShiftTable = props => {
    let refreshAssociateDataInterval = null
    const [selectedRowId, setSelectedRowId] = React.useState(null)
    const [selectedAssociateName, setSelectedAssociateName] = React.useState('')
    const [rowSelection, setRowSelection] = useState({})

    const [tableData, setTableData] = React.useState([])

    /* popover */
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
    const [popoverText, setPopoverText] = React.useState('')
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
    /* end pop over */

    const handlePopoverOpen = (event, text) => {
        console.log('popover open ' + text)
        setPopoverText(text)
        setPopoverAnchorEl(event.currentTarget)
        setIsPopoverOpen(true)
    }
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        setIsPopoverOpen(false)
    }
    useEffect(
        () => {
            if (!props.user.facilities) {
                return
            }
            let tmp = props.user.facilities.map(x => Object.assign({}, x))

            //shifts
            let data = []
            for (let facility of tmp) {
                let shiftCollections = facility.shiftCollections
                for (let shiftCollection of shiftCollections) {
                    // if shift already passed, don't show if prop is not present
                    if (!props.showPriorShifts) {
                        if (!moment(shiftCollection.end).isAfter(new Date())) {
                            let isPastShift = true
                            continue
                        }
                    }
                    //  limit show prior shifts to last 3 months
                    let last90 = moment().subtract(90, 'days')
                    if (props.showPriorShifts) {
                        if (!moment(shiftCollection.end).isAfter(last90)) {
                            let isPastShift = true
                            continue
                        }
                    }
                    let newCol = Object.assign({}, shiftCollection, {
                        facilityName: facility.name,
                        isInternallyAdministered:
                            facility.isInternallyAdministered,
                        city: facility.city,
                        phone: facility.phone,
                        isTestFacility: facility.isTestFacility
                    })
                    //alert(facility.name)
                    data.push(newCol)
                }
            }

            //sort but unnecessary
            /*
            data.sort((a, b) => {
                return moment(a.start) - moment(b.start)
            })
            */

            /*
            for (let x = 0; x < data.length; x++) {
                console.log('dates')
                console.log(data[x].start)
            }
            */

            // migrate over check box if checked
            //
            /*
            data = data.map(row => {
                //redundant, just use selected associate?
                let tmpId =
                    props.user && props.user.selectedAssociate
                        ? props.user.selectedAssociate
                        : null
                if (row.id === selectedRowId) {
                    row.tableData = { checked: true }
                } else if (row.id === tmpId) {
                    row.tableData = { checked: true }
                }
                return row
            })
            */

            setTableData(data)
        },
        // adding selectedrowId to array below breaks table selection -_-
        [props.user.facilities]
    )

    const createNote = row => {
        let note = null
        if (row.userNote && row.userNote.text) {
            if (row.userNote.text.length > 20) {
                let shortNote = row.userNote.text.substring(0, 20) + '... '
                note = (
                    <span
                        onMouseEnter={e =>
                            handlePopoverOpen(e, row.userNote.text)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        {shortNote}
                        <span
                            style={{
                                color: 'blue',
                                fontSize: '0.5em',
                                marginLeft: '0.5em'
                            }}
                        >
                            Details
                        </span>
                    </span>
                )
            } else {
                note = row.userNote.text
            }
        }

        return note
    }

    // const columns: ColDef[] = [
    //const columns = useMemo(() => [
    const tableInstanceRef = useRef(null)

    const dateTableFormat = {
        month: 'long',
        weekday: 'short',
        //year: 'numeric',
        day: 'numeric'
    }

    const columns = useMemo(
        () => [
            //const columns = [
            {
                accessorKey: 'isFilled',
                id: 'isFilled',
                header: 'Filled?',
                size: 80,
                /*
            accessorFn: originalRow => {
                let tmpBookedRequests = originalRow.requests.filter(
                    request => request.isActive
                )
                let quantityBooked = tmpBookedRequests.length
                let remaining = originalRow.quantity - quantityBooked
                let ret = null
                if (originalRow.quantity > 0 && remaining == 0) {
                    return 'true'
                } else {
                    return 'false'
                }
            },
            */
                accessorFn: originalRow => {
                    let isFilled = false
                    let tmpBookedRequests = originalRow.requests.filter(
                        request => request.isActive
                    )
                    let quantityBooked = tmpBookedRequests.length
                    let remaining = originalRow.quantity - quantityBooked
                    if (originalRow.quantity > 0 && remaining == 0) {
                        isFilled = true
                    } else {
                        isFilled = false
                    }
                    if (isFilled) {
                        return 'Filled'
                    } else {
                        return 'Not Filled'
                    }
                },
                filterVariant: 'select',
                /*
            filterFn: (row, id, filterValue) => {
                let isFilled = false
                let tmpBookedRequests = row.original.requests.filter(
                    request => request.isActive
                )
                let quantityBooked = tmpBookedRequests.length
                let remaining = row.original.quantity - quantityBooked
                if (row.original.quantity > 0 && remaining == 0) {
                    isFilled = true
                } else {
                    isFilled = false
                }
                if (filterValue == 'Filled') {
                    if (isFilled) {
                        return true
                    } else {
                        return false
                    }
                } else if (filterValue == 'Not Filled') {
                    if (isFilled) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            },
            */
                //filterSelectOptions: ['Filled', 'Not Filled', 'All'], //custom options list (as opposed to faceted list)
                Cell: ({ renderedCellValue, row }) => {
                    let tmpBookedRequests = row.original.requests.filter(
                        request => request.isActive
                    )
                    let quantityBooked = tmpBookedRequests.length
                    let remaining = row.original.quantity - quantityBooked
                    let ret = null
                    if (row.original.quantity > 0 && remaining == 0) {
                        ret = (
                            <span
                                style={{ color: 'green', fontWeight: 'bolder' }}
                            >
                                Filled
                            </span>
                        )
                    } else {
                        ret = (
                            <span
                                style={{ color: 'red', fontWeight: 'bolder' }}
                            >
                                Not Filled
                            </span>
                        )
                    }
                    return ret
                }
            },
            {
                header: 'Type',
                accessorKey: 'type',
                id: 'type',
                size: 70,
                filterVariant: 'multi-select'
                //filterSelectOptions: ['CNA', 'LVN', 'RN'] //custom options list (as opposed to faceted list)
            },
            {
                header: 'Date',
                accessorFn: row => new Date(row.start), //convert to Date for sorting and filtering
                accessorKey: 'date',
                filterVariant: 'date',
                filterFn: 'lessThan',
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    return moment(cell.getValue()).format('MMMM Do, dddd')
                },
                /*
                    cell
                        .getValue()
                        ?.toLocaleDateString('en-us', dateTableFormat), //render Date as a string
                        */

                id: 'date',
                size: 80
            },
            {
                accessorKey: 'startTime',
                id: 'time',
                header: 'Shift',
                size: 80,
                Cell: ({ renderedCellValue, row }) =>
                    `${row.original.startTime}-${row.original.endTime}`,
                //filterSelectOptions: ['Morning', 'Afternoon', 'Night'],

                accessorFn: originalRow => {
                    const format = 'h:mma'
                    let beforeTime = null
                    let afterTime = null

                    let mStartTime = moment(originalRow.startTime, format)
                    afterTime = moment('12:00am', format)
                    beforeTime = moment('12:00pm', format)
                    if (mStartTime.isBetween(afterTime, beforeTime)) {
                        return 'Morning'
                    }

                    afterTime = moment('12:00pm', format)
                    beforeTime = moment('5:00pm', format)
                    if (mStartTime.isBetween(afterTime, beforeTime)) {
                        return 'Afternoon'
                    }

                    return 'Night'
                },
                filterVariant: 'multi-select'
                /*
            filterFn: (row, id, filterValues) => {
                const format = 'h:mma'
                let beforeTime = null
                let afterTime = null

                console.log(filterValues)
                // var time = moment() gives you current time. no format required.
                //const time = moment('date', format);
                // const beforeTime = moment('08:34:00', format);
                // const afterTime = moment('10:34:00', format);
                // '

                let mStartTime = moment(row.original.startTime, format)
                let isTrue = filterValues.some(filterValue => {
                    if (filterValue == 'Morning') {
                        //console.log('found!!!!')
                        afterTime = moment('12:00am', format)
                        beforeTime = moment('12:00pm', format)
                    }

                    if (filterValue == 'Afternoon') {
                        //console.log('found hiasdfsdaf !!!!')
                        afterTime = moment('12:00pm', format)
                        beforeTime = moment('5:00pm', format)
                    }
                    if (filterValue == 'Night') {
                        afterTime = moment('5:00pm', format)
                        beforeTime = moment('11:59pm', format)
                    }
                    //console.log(row.original.startTime)
                    if (mStartTime.isBetween(afterTime, beforeTime)) {
                        console.log('in between')
                        return true
                    } else {
                        console.log('NOT inbewtween')
                        return false
                    }
                })
                return isTrue
            }
                */
            },
            {
                header: 'Facility',
                accessorKey: 'facilityName',
                id: 'facilityName',
                filterVariant: 'multi-select',
                size: 130,
                Cell: ({ renderedCellValue, row }) => {
                    let name = `${row.original.facilityName}`
                    if (row.original.isInternallyAdministered) {
                        name = name + ' (facility is internally administered)'
                    }
                    return name
                }
            },
            {
                header: 'City',
                accessorKey: 'city',
                id: 'city',
                size: 80,
                filterVariant: 'multi-select'
            },
            //{ header: 'Phone', accessorKey: 'phone', id: 'phone', size: 70 },
            {
                header: 'Canceled?',
                size: 130,
                id: 'isCanceled',
                //accessorFn: originalRow =>
                //    originalRow.isCanceled ? 'true' : 'false', //must be strings
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow && originalRow.isCanceled
                        ? 'Canceled'
                        : 'Not Canceled', //must be strings
                /*
            filterSelectOptions: ['Not Canceled', 'Canceled', 'All'], //custom options list (as opposed to faceted list)
            filterFn: (row, id, filterValue) => {
                let isCanceled = row.original.isCanceled

                if (filterValue == 'Not Canceled') {
                    if (!isCanceled) {
                        return true
                    } else {
                        return false
                    }
                } else if (filterValue == 'Canceled') {
                    if (isCanceled) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            */
                Cell: ({ renderedCellValue, row }) => {
                    return row.original && row.original.isCanceled ? (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            CANCELED
                        </span>
                    ) : (
                        <span style={{ color: 'green', fontWeight: 'normal' }}>
                            No
                        </span>
                    )
                }
            },
            {
                accessorKey: 'quantity',
                id: 'quantity',
                header: '#filled/requested',
                size: 70,
                Cell: ({ renderedCellValue, row }) => {
                    let tmpBookedRequests = row.original.requests.filter(
                        request => request.isActive
                    )
                    let quantityBooked = tmpBookedRequests.length
                    let text = quantityBooked + '/' + row.original.quantity

                    return text
                }
            },
            {
                accessorKey: 'note',
                id: 'note',
                header: 'Note',
                size: 150,
                Cell: ({ renderedCellValue, row }) => {
                    return createNote(row.original)
                }
            },
            {
                id: 'created',
                accessorKey: 'created',
                header: 'Created',
                size: 80,
                Cell: ({ renderedCellValue, row }) => {
                    return moment(row.original.created).format('M/D/YY')
                }
                //render: row => moment(row.created).format('MM/D/YY, h:mm a')
            },
            {
                header: 'Test Shift?',
                size: 100,
                id: 'isTestFacility',
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow && originalRow.isTestFacility ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) => {
                    return row.original && row.original.isTestFacility ? (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            Yes
                        </span>
                    ) : (
                        <span style={{ color: 'green', fontWeight: 'normal' }}>
                            No
                        </span>
                    )
                }
            }
        ],
        []
    )

    const handleSelectAssociate = userId => {
        clearInterval(refreshAssociateDataInterval)
        console.log('selection!')

        let associate = tableData.find(x => {
            return x.id === userId
        })
        //alert(' associate ' + JSON.stringify(associate))
        if (!associate) {
            setSelectedRowId(null)

            props.setUser({
                ...props.user,
                selectedAssociate: {}
            })
            props.setShifts([])
            return
        }

        let newAssociateId = associate.id
        setSelectedRowId(newAssociateId)
        setSelectedAssociateName(
            `${associate.firstName} ${associate.lastName}, ${associate.type}`
        )
        console.log('new ' + newAssociateId)
        console.log('old ' + selectedRowId)
        console.log(tableData)
        /*
        // de-select other selected rows
        let newRows = tableData.map(row => {
            let nRow = JSON.parse(JSON.stringify(row))
            //let nRow = row
            if (nRow.id === newAssociateId) {
                nRow.tableData.checked = true
            } else {
                nRow.tableData.checked = false
            }
            return nRow
        })
        //console.log('new rows')
        //console.log(newRows)
        setTableData(newRows)
        */

        //empty out shifts
        props.setShifts([])
        let tmp = JSON.parse(JSON.stringify(associate))
        props.setUser({
            ...props.user,
            selectedAssociate: tmp
            //associates: JSON.parse(JSON.stringify(newRows))
        })

        ShiftService.getAvailableShifts()
        refreshAssociateDataInterval = setInterval(() => {
            console.log('running data refresh ' + new Date())
            ShiftService.getAvailableShifts()
        }, 360000)
        return
    }

    /*
    useEffect(
        () => {
            //do something when the row selection changes...
            //
            let userIds = Object.keys(rowSelection)
            let userId = null
            if (userIds.length > 0) {
                userId = userIds[0]
            }
            if (!userId) {
                return
            }

            handleSelectAssociate(userId)

            console.log({ rowSelection })
        },
        [rowSelection]
    )
    */

    // set initial state of open shift filter

    let openShiftFilter = {}

    if (props.filterOpenShifts) {
        openShiftFilter = {
            id: 'isFilled',
            value: 'Not Filled'
        }
    }
    return (
        <div className={'shiftTable'} style={{ width: '100%' }}>
            <MaterialTable
                header="Select Associate"
                columns={columns}
                enableFacetedValues={true}
                data={tableData}
                //enableRowSelection
                //enableMultiRowSelection={false}

                enableStickyHeader={true}
                enableGlobalFilterModes
                enablePagination={false}
                enableRowNumbers
                enableBottomToolbar={false}
                rowNumberMode="static"
                enableRowVirtualization
                rowVirtualizerProps={{
                    overscan: 20 //adjust the number or rows that are rendered above and below the visible area of the table
                }}
                //enableRowNumbers={true}
                memoMode="cells" // memoize table rows to improve render performance, but break a lot of features
                isMultiSortEvent={() => true}
                muiTableHeadProps={{
                    sx: {
                        // first number index row header
                        '& th:nth-of-type(1)': {
                            paddingLeft: '2em',
                            maxWidth: '4em'
                        },
                        maxWidth: '100%',
                        // hide oclumns? hmmm. test
                        overflowX: 'hidden'
                    }
                }}
                muiTableBodyProps={{
                    sx: {
                        //stripe the rows, make odd rows a darker color
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: '#f8f8fa'
                        },
                        // first number index row
                        '& td:nth-of-type(1)': {
                            maxWidth: '4em',
                            paddingLeft: '2em',
                            textAlignt: 'right'
                        }
                        //
                    }
                }}
                initialState={{
                    columnFilters: [
                        {
                            id: 'isCanceled',
                            value: 'Not Canceled'
                        },
                        // by default hide shifts from test facilities
                        {
                            id: 'isTestFacility',
                            value: 'No'
                        },
                        openShiftFilter
                    ],
                    sorting: [
                        {
                            id: 'date', //sort by age by default on page load
                            desc: props.showPriorShifts ? true : false
                        }
                    ],
                    showColumnFilters: true

                    //pagination: { pageSize: 75, pageIndex: 0 }
                }}
                //
                /*
                muiTableContainerProps={{
                    sx: { maxWidth: '100%', maxHeight: '50%' }
                }}
                 */
                /*
                muiTablePaginationProps={{
                    rowsPerPageOptions: [25, 50, 100, 200],
                    showFirstButton: false,
                    showLastButton: false
                }}
                 */
                tableInstanceRef={tableInstanceRef}
                getRowId={originalRow => originalRow.userId}
                //onSelectionChange={handleSelectAssociate}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                enableFullScreenToggle
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                localization={{
                    selectedCountOfRowCountRowsSelected: `Selected Nurse: ${selectedAssociateName}`
                }}
                options={{
                    search: true,
                    detailPanelType: 'single',
                    selection: true,
                    headerStyle: { fontSize: '12px' },
                    rowStyle: rowData => ({
                        padding: '5px'
                    })
                }}
                actions={[
                    {
                        icon: 'settings',
                        onClick: (event, rowData) => {
                            props.history.push('/nurse_settings')
                        }
                    }
                ]}
                /*
                renderTopToolbarCustomActions={({ table }) => {
                    const handleDeactivate = () => {
                        table.getSelectedRowModel().flatRows.map(row => {
                            alert('deactivating ' + row.getValue('name'))
                        })
                    }

                    const handleActivate = () => {
                        table.getSelectedRowModel().flatRows.map(row => {
                            alert('activating ' + row.getValue('name'))
                        })
                    }

                    const handleContact = () => {
                        props.closeModal()
                        props.history.push('/nurse_settings')
                    }

                    return (
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <Button
                                color="error"
                                disabled={!table.getIsSomeRowsSelected()}
                                onClick={handleContact}
                                variant="contained"
                            >
                                Edit Nurse
                            </Button>
                        </div>
                    )
                }}
                        */
            />
            <Popover
                id="mouse-over-popover"
                open={isPopoverOpen}
                anchorEl={popoverAnchorEl}
                style={{
                    pointerEvents: 'none',
                    maxWidth: '500px',
                    minWidth: '70%'
                }}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Typography style={{ padding: '1em' }}>
                    {popoverText}
                </Typography>
            </Popover>
        </div>
    )
}

export default compose(
    withStyles(useStyles),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ShiftTable)
