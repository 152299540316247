// client facility
import React from 'react'
import axios from 'axios'
import '@aws-amplify/ui-react/styles.css'
import {
    Heading,
    Authenticator,
    useTheme,
    useAuthenticator,
    CheckboxField,
    View,
    Image
} from '@aws-amplify/ui-react'
import { Hub } from 'aws-amplify'

//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import NavBar from './NavBar.js'
import FacilityHome from './pages/Facility_Home'
import FacilityShifts from './pages/Facility_Shifts'
import FacilityComprehensiveShifts from './pages/Facility_Comprehensive_Shifts'
import FacilityOpenShifts from './pages/Facility_Open_shifts'
import FacilityHistoricalShifts from './pages/Facility_Historical_Shifts'
import FacilitySettings from './pages/Facility_Settings'
import FacilityTools from './pages/Facility_Tools'
import FacilityOnboarding from './pages/Facility_Onboarding'
//import FacilityBilling from './pages/Facility_Billing'
import FacilityInvoices from './pages/Facility_Invoices'

import NurseShifts from './pages/Nurse_Shifts'
import Nurses from './pages/Nurses'
import NurseCalendar from './pages/Nurse_Calendar'
import NurseSettings from './pages/Nurse_Settings'
import NursePayments from './pages/Nurse_Payments'
import NurseHistory from './pages/Nurse_History'

import PlivoModal from './features/PlivoModal/PlivoModal'

import CancelShiftDialog from './features/FacilityAdmin/CancelShiftDialog/CancelShiftDialog'
import ProfileDialog from './features/ProfileDialog/ProfileDialog'
import ClockDialog from './features/ClockDialog/ClockDialog'
import Timesheet from './features/Timesheet/Timesheet'
import SnackbarMessage from './features/SnackbarMessage/SnackbarMessage'
import File from './features/File/File'
import { Provider, ErrorBoundary, RollbarContext } from '@rollbar/react' // <-- Provider imports 'rollbar' for us

//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

//import UserService from './services/userService.js'
import NurseUserService from './services/userService.js'
import { setUser, resetUser } from './features/User/userSlice.js'

import authService from './services/authService.js'
import { connect } from 'react-redux' //Code
//import { withRouter } from 'react-router-dom'
import { compose } from 'redux' //Code

import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    adaptV4Theme
} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

//import { App as CapacitorApp } from '@capacitor/app'

//import { green, purple } from '@mui/material/colors'

const theme = createTheme(
    adaptV4Theme({
        typography: {
            fontSize: 12
        },
        palette: {
            primary: {
                //main: purple[500],
                main: '#3f51b5'
            },
            secondary: {
                main: '#008080'
            }
        }
    })
)

//import { App as CapacitorApp } from '@capacitor/app'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:7000'
//axios.defaults.baseURL = 'https://app-api.nurselab.co'
//
// same configuration you would create for the Rollbar.js SDK
const nodeEnv = process.env.NODE_ENV
const rollbarConfig = {
    enabled: nodeEnv === 'production',
    accessToken: '9f33950192834173a563fee3bf806afe',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production'
    }
}

/*
// set up back button behavior, only for android
CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
        CapacitorApp.exitApp()
    } else {
        window.history.back()
    }
})
*/

const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        resetUser: () => {
            dispatch(resetUser())
        }
    }
}

const App = props => {
    // code to run on component mount
    React.useEffect(() => {
        let refreshDataInterval = null
        Hub.listen('auth', async ({ payload }) => {
            const { event } = payload
            if (event === 'signIn' || event === 'autoSignIn') {
                console.log('login event: ' + event)
                await NurseUserService.getAccount()
                //console.log('user obj ' + JSON.stringify(userObj))
                console.log(props.user)

                refreshDataInterval = setInterval(() => {
                    console.log('running data refresh ' + new Date())
                    //UserService.getAccount()
                    NurseUserService.getAccount()
                }, 180000)

                console.log('end test')
            } else if (event === 'signOut') {
                clearInterval(refreshDataInterval)
                //store.dispatch(setUser({}))
                props.resetUser()

                //props.history.push('/')
                console.log('signed out')
            }
        })
        /*
        onAuthUIStateChange(async (nextAuthState, authData) => {
            console.log('auth change!')
            if (nextAuthState === 'signedin') {
                await NurseUserService.getAccount()
                //console.log('user obj ' + JSON.stringify(userObj))
                console.log(props.user)

                refreshDataInterval = setInterval(() => {
                    console.log('running data refresh ' + new Date())
                    //UserService.getAccount()
                    NurseUserService.getAccount()
                }, 180000)

                console.log('end test')
            } else if ((nextAuthState = 'signedout')) {
                clearInterval(refreshDataInterval)
                //store.dispatch(setUser({}))
                props.resetUser()

                //props.history.push('/')
                console.log('signed out')
            }
        })
        */

        const init = async () => {
            let isLoggedIn = await authService.isLoggedIn()
            if (isLoggedIn) {
                console.log(' is logged in account reload, WHY!?')
                //await UserService.getAccount()
                await NurseUserService.getAccount()

                console.log('running has facilities? ')
            }
        }
        init()
    }, [])

    const authenticatorFormFields = {
        signUp: {
            given_name: {
                placeholder: 'Enter Your First Name',
                isRequired: true,
                label: 'First Name:',
                order: 1
            },
            family_name: {
                placeholder: 'Enter Your Last Name',
                isRequired: true,
                label: 'Last Name:',
                order: 2
            },
            phone_number: {
                isRequired: true
            }
        }
    }
    const authenticatorComponents = {
        Header() {
            const { tokens } = useTheme()

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image
                        alt="NurseLab logo"
                        src="/nurselab.png"
                        style={{ height: '6.5em', marginBottom: '1em' }}
                    />
                </View>
            )
        },
        SignIn: {
            Header() {
                const { tokens } = useTheme()

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Admin Sign-in
                    </Heading>
                )
            }
        },
        SignUp: {
            Header() {
                const { tokens } = useTheme()

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Create a New Account
                    </Heading>
                )
            },
            FormFields() {
                const { validationErrors } = useAuthenticator()

                return (
                    <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />
                        {/* Append & require Terms & Conditions field to sign up  */}
                        {/*hasError={!!validationErrors.acknowledgement}*/}
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            required="true"
                            label={
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '0.35em'
                                    }}
                                >
                                    By signing up, I agree with the{' '}
                                    <a
                                        href="https://nurselab.co/terms"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        terms and conditions
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://nurselab.co/privacy"
                                        target="_blank"
                                        style={{ display: 'inline-block' }}
                                    >
                                        privacy policy
                                    </a>
                                    .
                                </span>
                            }
                        />
                    </>
                )
            }
        }
    }
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Provider config={rollbarConfig}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary>
                        <div
                            className="App"
                            style={{
                                backgroundColor: 'white'
                            }}
                        >
                            <Authenticator
                                style={{}}
                                loginMechanisms={['email']}
                                className="amplifySignIn"
                                usernameAlias="email"
                                components={authenticatorComponents}
                                formFields={authenticatorFormFields}
                                signUpAttributes={[
                                    'given_name',
                                    'family_name',
                                    'phone_number'
                                ]}
                                services={{
                                    async validateCustomSignUp(formData) {
                                        if (!formData.acknowledgement) {
                                            return {
                                                acknowledgement:
                                                    'You must agree to the terms and conditions and privacy policy.'
                                            }
                                        }
                                    }
                                }}
                            >
                                <Router>
                                    <div
                                        style={
                                            isDesktop
                                                ? {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: ''
                                                  }
                                                : {
                                                      display: 'flex',
                                                      marginTop: '0em',
                                                      padding: '0',
                                                      paddingTop: '0'
                                                  }
                                        }
                                    >
                                        <NavBar />

                                        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
       */}
                                        <Switch>
                                            <Route exact path="/">
                                                <RollbarContext context="/">
                                                    <FacilityComprehensiveShifts />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/open_shifts">
                                                <RollbarContext context="/open_shifts">
                                                    <FacilityOpenShifts />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/prior_shifts">
                                                <RollbarContext context="/prior_shifts">
                                                    <FacilityHistoricalShifts />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_shifts">
                                                <RollbarContext context="/facility_shifts">
                                                    <FacilityHome />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_onboarding">
                                                <RollbarContext context="facility_onboarding">
                                                    <FacilityOnboarding />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_calendar">
                                                <RollbarContext context="facility_calendar">
                                                    <FacilityShifts />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_invoices">
                                                <RollbarContext context="facility_invoices">
                                                    <FacilityInvoices />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_tools">
                                                <RollbarContext context="facility_tools">
                                                    <FacilityTools />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/facility_settings">
                                                <RollbarContext context="facility_settings">
                                                    <FacilitySettings />
                                                </RollbarContext>
                                            </Route>

                                            <Route path="/nurses">
                                                <RollbarContext context="nurses">
                                                    <Nurses />
                                                </RollbarContext>
                                            </Route>

                                            <Route path="/nurse_shifts">
                                                <RollbarContext context="nurse_shifts">
                                                    <NurseShifts />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/nurse_calendar">
                                                <RollbarContext context="nurse_calendar">
                                                    <NurseCalendar />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/nurse_settings">
                                                <RollbarContext context="nurse_settings">
                                                    <NurseSettings />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/nurse_activity">
                                                <RollbarContext context="nurse_activity">
                                                    <NurseHistory />
                                                </RollbarContext>
                                            </Route>
                                            <Route path="/nurse_payments">
                                                <RollbarContext context="nurse_payments">
                                                    <NursePayments />
                                                </RollbarContext>
                                            </Route>
                                        </Switch>
                                        <PlivoModal />
                                        <CancelShiftDialog />
                                        <ProfileDialog />
                                        <ClockDialog />
                                        <File />
                                        <SnackbarMessage />
                                        <Timesheet />
                                    </div>
                                </Router>
                                {/*
                                <SignUp
                                    usernameAlias="email"
                                    className="amplifySignUp"
                                    slot="sign-up"
                                    formFields={[
                                        {
                                            type: 'given_name',
                                            label: 'First Name',
                                            placeholder: 'First Name',
                                            required: true
                                        },
                                        {
                                            type: 'family_name',
                                            label: 'Last Name',
                                            placeholder: 'Last Name',
                                            required: true
                                        },
                                        {
                                            type: 'email',
                                            label: 'Email Address',
                                            placeholder: 'Email',
                                            required: true
                                        },
                                        {
                                            type: 'password',
                                            label: 'Password',
                                            placeholder: 'Password',
                                            required: true
                                        },
                                        {
                                            type: 'phone_number',
                                            label: 'Phone Number',
                                            placeholder: '(555) 555-1212',
                                            required: true
                                        }
                                    ]}
                                >
                                    <div
                                        slot="secondary-footer-content"
                                        className="signupTerms"
                                    >
                                        By signing up you agree to our{' '}
                                        <a
                                            target="_blank"
                                            href="https://nurselab.co/terms"
                                        >
                                            terms and conditions
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            target="_blank"
                                            href="https://nurselab.co/facility-agreement"
                                        >
                                            facility agreement
                                        </a>
                                        .<br />
                                        <br />
                                        <br />
                                        <br />
                                        Have an account?{' '}
                                        <amplify-button
                                            variant="anchor"
                                            onClick={() =>
                                                (window.location.href = '/')
                                            }
                                            data-test="sign-up-sign-in-link"
                                        >
                                            Sign-in
                                        </amplify-button>
                                    </div>
                                </SignUp>
                                    */}
                            </Authenticator>
                        </div>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    )
}

/*
export default withAuthenticator(App, {
    usernameAttributes: 'email'
})

*/
export default compose(
    connect(
        null,
        mapDispatchToProps
    )
)(App)
