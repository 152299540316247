import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux' //Code

import { compose } from 'redux' //Code
import { setUser, resetUser } from '../../../features/User/userSlice.js'
import { setShifts } from '../../../features/NurseAdmin/Shift/shiftSlice.js'

// required for edit
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-react-table'

import ShiftService from '../../../services/nurseAdmin/shiftService.js'
import Avatar from '@mui/material/Avatar'
import withStyles from '@mui/styles/withStyles'

import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import CreateAssociate from '../CreateAssociate/CreateAssociate.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
// for created date use local computer timezone to assist staffers on newest
const moment = require('moment')

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        resetUser: () => {
            dispatch(resetUser())
        },
        setShifts: shifts => {
            dispatch(setShifts(shifts))
        }
    }
}
/*
const useStyles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
})
*/

const AssociateTable = props => {
    let refreshAssociateDataInterval = null
    const [selectedRowId, setSelectedRowId] = React.useState(null)
    const [selectedAssociateName, setSelectedAssociateName] = React.useState('')
    const [rowSelection, setRowSelection] = useState({})

    const [tableData, setTableData] = React.useState([])

    /* popover */
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)
    const [popoverText, setPopoverText] = React.useState('')
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
    /* end pop over */

    const handlePopoverOpen = (event, text) => {
        console.log('popover open ' + text)
        setPopoverText(text)
        setPopoverAnchorEl(event.currentTarget)
        setIsPopoverOpen(true)
    }
    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        setIsPopoverOpen(false)
    }
    useEffect(
        () => {
            if (!props.user.associates) {
                return
            }
            let data = props.user.associates.map(x => Object.assign({}, x))
            // migrate over check box if checked
            //
            data = data.map(row => {
                //redundant, just use selected associate?
                let tmpId =
                    props.user && props.user.selectedAssociate
                        ? props.user.selectedAssociate
                        : null
                // make array of types to string for table sorting, may have unintentional side effects
                row.type = String(row.type)
                //console.log('type ' + row.type)
                //console.log(typeof row.type)
                if (row.id === selectedRowId) {
                    row.tableData = { checked: true }
                } else if (row.id === tmpId) {
                    row.tableData = { checked: true }
                }
                return row
            })

            setTableData(data)
        },
        // adding selectedrowId to array below breaks table selection -_-
        [props.user.associates]
    )

    const createNote = row => {
        let note = null
        if (row.userNote && row.userNote.text) {
            if (row.userNote.text.length > 20) {
                let shortNote = row.userNote.text.substring(0, 20) + '... '
                note = (
                    <span
                        onMouseEnter={e =>
                            handlePopoverOpen(e, row.userNote.text)
                        }
                        onMouseLeave={handlePopoverClose}
                    >
                        {shortNote}
                        <span
                            style={{
                                color: 'blue',
                                fontSize: '0.5em',
                                marginLeft: '0.5em'
                            }}
                        >
                            Details
                        </span>
                    </span>
                )
            } else {
                note = row.userNote.text
            }
        }

        return note
    }

    // const columns: ColDef[] = [
    //const columns = useMemo(() => [
    const tableInstanceRef = useRef(null)
    const columns = useMemo(
        () => [
            //const columns = [
            {
                header: 'Profile Pic',
                accessorKey: 'profileImgUrl',
                id: 'profileImgUrl',
                size: 30,
                enableColumnFilter: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Avatar
                        src={row.original.profileImageUrl}
                        style={{
                            height: '2em',
                            width: '2em'
                        }}
                    />
                )
            },
            {
                header: 'First',
                id: 'firstName',
                accessorKey: 'firstName',
                size: 100
            },
            {
                header: 'Last',
                accessorKey: 'lastName',
                id: 'lastName',
                size: 100
            },
            { header: 'Email', accessorKey: 'email', id: 'email', size: 150 },
            { header: 'Phone', accessorKey: 'phone', id: 'phone', size: 100 },
            {
                header: 'Type',
                accessorKey: 'type',
                id: 'type',
                size: 80,
                //filterFn: 'notEmpty',
                filterVariant: 'multi-select'
            },
            {
                accessorKey: 'isActivated',
                header: 'Activated',
                id: 'isActivated',
                size: 80,
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.isActivated ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) =>
                    row.original.isActivated ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
            },
            {
                accessorKey: 'isInternallyAdministered',
                header: 'Administered Internally by NurseLab',
                id: 'isInternallyAdministered',
                size: 260,
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.isInternallyAdministered ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) =>
                    row.original.isInternallyAdministered ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
            },
            {
                accessorKey: 'isEnabled',
                id: 'isEnabled',
                header: 'Enabled',
                size: 80,
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.isEnabled ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) =>
                    row.original.isEnabled ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
            },
            {
                accessorKey: 'hasNurseLabStaffingRegistrationAgreement',
                id: 'hasNurseLabStaffingRegistrationAgreement',
                header: 'Signed NurseLab Registration Agreement?',
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.profile &&
                    originalRow.profile.hasNurseLabStaffingRegistrationAgreement
                        ? 'Yes'
                        : 'No', //must be strings
                size: 290,
                Cell: ({ renderedCellValue, row }) =>
                    row.original.profile &&
                    row.original.profile
                        .hasNurseLabStaffingRegistrationAgreement ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
            },
            {
                accessorKey: 'isSmsEnabled',
                id: 'isSmsEnabled',
                header: 'SMS',
                size: 80,
                accessorFn: originalRow =>
                    originalRow.isSmsEnabled ? 'Yes' : 'No', //must be strings
                filterVariant: 'select',

                Cell: ({ renderedCellValue, row }) => {
                    return row.original.isSmsEnabled ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
                }
            },
            {
                accessorKey: 'isEmailEnabled',
                id: 'isEmailEnabled',
                header: 'Email',
                size: 80,
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.isEmailEnabled ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) =>
                    row.original.isEmailEnabled ? (
                        <span style={{ color: 'green' }}>Yes</span>
                    ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            No
                        </span>
                    )
            },
            {
                accessorKey: 'isTestUser',
                id: 'isTestUser',
                header: 'Test User',
                size: 80,
                filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.isTestUser ? 'Yes' : 'No', //must be strings
                Cell: ({ renderedCellValue, row }) =>
                    row.original.isTestUser ? (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>
                            Yes
                        </span>
                    ) : (
                        <span style={{ color: 'green' }}>No</span>
                    )
            },
            {
                accessorKey: 'note',
                id: 'note',
                header: 'Note',
                size: 150,
                Cell: ({ renderedCellValue, row }) => {
                    return createNote(row.original)
                }
            },
            {
                accessorKey: 'initialCredentialSource',
                id: 'initialCredentialSource',
                header: 'Credential Source',
                filterVariant: 'multi-select',
                size: 80
            },
            {
                id: 'created',
                accessorKey: 'created',
                header: 'Created',
                Cell: ({ renderedCellValue, row }) => {
                    return moment(row.original.created).format('M/D/YY')
                }
                //render: row => moment(row.created).format('MM/D/YY, h:mm a')
            },
            {
                accessorKey: 'source',
                id: 'source',
                header: 'Sign-up Source',
                size: 80,
                enableColumnFilter: false,
                //filterVariant: 'select',
                accessorFn: originalRow =>
                    originalRow.source === 'adminPanel' ? 'Admin' : 'Platform', //must be strings
                Cell: ({ renderedCellValue, row }) => {
                    return row.original.source === 'adminPanel' ? (
                        <span>Admin</span>
                    ) : (
                        <span>Platform</span>
                    )
                }
            }
        ],
        []
    )
    const handleSelectAssociate = userId => {
        clearInterval(refreshAssociateDataInterval)
        console.log('selection!')

        let associate = tableData.find(x => {
            return x.id === userId
        })
        //alert(' associate ' + JSON.stringify(associate))
        if (!associate) {
            setSelectedRowId(null)

            props.setUser({
                ...props.user,
                selectedAssociate: {}
            })
            props.setShifts([])
            return
        }

        let newAssociateId = associate.id
        setSelectedRowId(newAssociateId)
        setSelectedAssociateName(
            `${associate.firstName} ${associate.lastName}, ${associate.type}`
        )
        //console.log('new ' + newAssociateId)
        //console.log('old ' + selectedRowId)
        //console.log(tableData)
        //empty out shifts
        props.setShifts([])
        let tmp = JSON.parse(JSON.stringify(associate))
        props.setUser({
            ...props.user,
            selectedAssociate: tmp
            //associates: JSON.parse(JSON.stringify(newRows))
        })

        ShiftService.getAvailableShifts()
        refreshAssociateDataInterval = setInterval(() => {
            console.log('running data refresh ' + new Date())
            ShiftService.getAvailableShifts()
        }, 360000)
        return
    }

    useEffect(
        () => {
            //do something when the row selection changes...
            //
            let userIds = Object.keys(rowSelection)
            let userId = null
            if (userIds.length > 0) {
                userId = userIds[0]
            }
            if (!userId) {
                return
            }

            handleSelectAssociate(userId)

            console.log({ rowSelection })
        },
        [rowSelection]
    )

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    //
    return (
        <div
            className={'associateTable'}
            style={{ width: '100%', display: 'block' }}
        >
            <MaterialTable
                enableFacetedValues={true}
                //style={{ padding: '1em' }}
                header="Select Associate"
                initialState={{
                    showColumnFilters: true,
                    pagination: { pageSize: 50, pageIndex: 0 },
                    density: isDesktop ? 'spacious' : 'compact',
                    columnFilters: [
                        {
                            id: 'isTestUser',
                            value: 'No'
                        }
                    ]
                }}
                columns={columns}
                data={tableData}
                //enableBottomToolbar={false}
                //enableColumnResizing
                // enableColumnVirtualization
                enableGlobalFilterModes
                //enablePagination={false}
                enablePinning
                //enableRowVirtualization
                //enableRowNumbers
                //rowNumberMode="static"
                enableRowSelection
                //memoMode="cells" // memoize table cells to improve render performance, but break some features
                //muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
                enableStickyHeader={true}
                /*
                //enableColumnVirtualization={true}
                enableRowVirtualization
                //enableColumnVirtualization
                /**/
                rowVirtualizerProps={{
                    overscan: 20 //adjust the number or rows that are rendered above and below the visible area of the table
                }}
                /*
                columnVirtualizerProps={{
                    overscan: 10 //adjust the number or rows that are rendered above and below the visible area of the table
                }}
                 */
                enableMultiRowSelection={false}
                //tableInstanceRef={tableInstanceRef}
                getRowId={originalRow => originalRow.userId}
                //onSelectionChange={handleSelectAssociate}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                localization={{
                    selectedCountOfRowCountRowsSelected: `Selected Nurse: ${selectedAssociateName}`
                }}
                // for check box
                muiTableHeadProps={{
                    sx: {
                        // first number index row header
                        '& th:nth-of-type(1)': {
                            paddingLeft: '1em'
                        }
                    }
                }}
                muiSelectCheckboxProps={{
                    sx: {
                        paddingLeft: '2.5em'
                    }
                }}
                muiTableHeadCellFilterTextFieldProps={{
                    sx: {
                        maxWidth: '20em'
                    }
                }}
                muiTableBodyProps={{
                    sx: {
                        //stripe the rows, make odd rows a darker color
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: '#f8f8fa'
                        }
                    }
                }}
                options={{
                    search: true,
                    detailPanelType: 'single',
                    selection: true,
                    headerStyle: { fontSize: '12px' },
                    rowStyle: rowData => ({
                        padding: '5px'
                    })
                }}
                actions={[
                    {
                        icon: 'settings',
                        onClick: (event, rowData) => {
                            props.history.push('/nurse_settings')
                        }
                    }
                ]}
                renderTopToolbarCustomActions={({ table }) => {
                    const handleDeactivate = () => {
                        table.getSelectedRowModel().flatRows.map(row => {
                            alert('deactivating ' + row.getValue('name'))
                        })
                    }

                    const handleActivate = () => {
                        table.getSelectedRowModel().flatRows.map(row => {
                            alert('activating ' + row.getValue('name'))
                        })
                    }

                    const handleContact = () => {
                        props.closeModal()
                        props.history.push('/nurse_settings')
                        /*
                        table.getSelectedRowModel().flatRows.map(row => {
                            alert(JSON.stringify(row))
                            //alert('contact ' + row.getValue('name'))
                        })
                        */
                    }

                    return (
                        <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                            <Button
                                color="error"
                                disabled={!table.getIsSomeRowsSelected()}
                                onClick={handleContact}
                                variant="contained"
                            >
                                Edit Nurse
                            </Button>

                            <CreateAssociate />
                        </Box>
                    )
                }}
            />
            <Popover
                id="mouse-over-popover"
                open={isPopoverOpen}
                anchorEl={popoverAnchorEl}
                style={{
                    pointerEvents: 'none',
                    maxWidth: '500px',
                    minWidth: '70%'
                }}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Typography style={{ padding: '1em' }}>
                    {popoverText}
                </Typography>
            </Popover>
        </div>
    )
}

export default compose(
    //withStyles(useStyles),
    //null,
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AssociateTable)
