// TODO ability to add /remove facility
// ability to update multiple facilities

import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import axios from 'axios'
import { connect } from 'react-redux' //Code
import UserService from '../services/userService.js'
import AuthService from '../services/authService.js'
import { setUser } from '../features/User/userSlice.js'
import Utils from '../helpers/utils'

import SelectFacility from '../features/FacilityAdmin/SelectFacility/SelectFacility'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

class Facility {
    constructor(facility) {
        this.id = facility.id ? facility.id : ''
        this.name = facility.name ? facility.name : ''
        this.address = facility.address ? facility.address : ''
        this.city = facility.city ? facility.city : ''
        this.phone = facility.phone ? facility.phone : ''
        this.zipcode = facility.zipcode ? facility.zipcode : ''
        this.state = facility.state ? facility.state : ''
        this.parkingInstruction = facility.parkingInstruction
            ? facility.parkingInstruction
            : ''
        this.logoUrl = facility.logoUrl ? facility.logoUrl : ''
        this.rates = []
        this.isEnabled = facility.isEnabled ? facility.isEnabled : false
    }
}
const styles = {
    textField: {
        width: '100%',
        marginBottom: '0.75em'
    }
}

const mapStateToProps = state => ({ user: state.user, history: state.history })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        }
    }
}
const Settings = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const history = useHistory()
    const [facility, setFacility] = useState(
        props.user && props.user.selectedFacility
            ? new Facility(props.user.selectedFacility)
            : {}
    )
    const [editRatesTable, setEditRatesTable] = useState([])
    const loadRates = async () => {
        let headers = await AuthService.getHeader()
        let res = null
        try {
            res = await axios.get(
                `/api/admin/account/rates/facility/${
                    props.user.selectedFacility.id
                }`,
                {
                    headers: headers
                }
            )
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }
        if (res) {
            let rates = res.data
            console.log(rates)
            setFacility({
                ...props.user.selectedFacility,
                rates: rates
            })
        }
    }

    React.useEffect(
        () => {
            let rates = facility.rates

            let tmp = []
            rates.forEach((rateData, index) => {
                let block = (
                    <Grid container spacing={3} style={{ marginTop: '2em' }}>
                        <Grid item xs={12} md={12}>
                            {rateData.type} Rates:
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id=""
                                value={rateData.billableHourlyRate}
                                style={styles.textField}
                                onChange={e =>
                                    handleRateChange(e, index, rateData.id)
                                }
                                label="Billable Hourly Rate"
                                name="billableHourlyRate"
                                required
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id=""
                                value={rateData.payoutHourlyRate}
                                style={styles.textField}
                                onChange={e =>
                                    handleRateChange(e, index, rateData.id)
                                }
                                label="Payout Hourly Rate"
                                name="payoutHourlyRate"
                                required
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} />

                        <Grid item xs={12} md={3}>
                            <TextField
                                id=""
                                value={rateData.crisisBillableHourlyRate}
                                style={styles.textField}
                                onChange={e =>
                                    handleRateChange(e, index, rateData.id)
                                }
                                label="Crisis Billable Hourly Rate"
                                name="crisisBillableHourlyRate"
                                required
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id=""
                                value={rateData.crisisPayoutHourlyRate}
                                style={styles.textField}
                                onChange={e =>
                                    handleRateChange(e, index, rateData.id)
                                }
                                label="Crisis Payout Hourly Rate"
                                name="crisisPayoutHourlyRate"
                                required
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                )
                tmp.push(block)
            })
            setEditRatesTable(tmp)
        },
        [facility]
    )

    React.useEffect(
        () => {
            setFacility(new Facility(props.user.selectedFacility))
            if (props.user.isPlatformSuperAdmin) {
                loadRates()
            }
        },
        [props.user]
    )
    React.useEffect(() => {
        if (!Utils.hasFacilities(props.user)) {
            console.log('no facilities')

            history.push('/onboarding')
        }
        // on page load scroll to top, fix buggy nav
        window.scrollTo(0, 0)
    }, [])

    const fileUpload = useRef(null)
    const [file, setFile] = useState('') // storing the uploaded file
    const [isLoading, setIsLoading] = useState(false)
    const handleInputChange = (event, payRateId) => {
        const name = event.target.name
        const value = event.target.value

        setFacility({
            ...facility,
            [name]: value
        })
    }

    const handleRateChange = (event, rateIndex, rateId) => {
        const rateProp = event.target.name // payoutHourlyRate | billableHourlyRate
        const newRate = event.target.value

        let ratesData = facility.rates.slice() // Make a copy of the rates first.
        let rateData = ratesData[rateIndex]
        rateData[rateProp] = newRate
        //console.log('name ' + name)
        console.log(ratesData)
        setFacility({
            ...facility,
            rates: ratesData
        })
    }

    const handleFileChange = e => {
        const file = e.target.files[0] // accessing file
        console.log(file)
        setFile(file) // storing file
    }

    const handleBooleanInputChange = event => {
        const name = event.target.name
        let value = event.target.value
        console.log('name ' + name + ' value ' + value)
        setFacility({
            ...facility,
            [name]: !facility[name]
        })
    }

    const updateUser = async event => {
        event.preventDefault()
        console.log(facility.name)
        //console.log(this.state)
        setIsLoading(true)
        let res = null

        /*
        const json = JSON.stringify(facility)
        const blob = new Blob([json], {
            type: 'application/json'
        })
        data.append('document', blob)
        */
        const data = new FormData()
        data.append('image', file)
        for (let propertyName in facility) {
            data.append(propertyName, facility[propertyName])
        }

        let headers = await AuthService.getHeader()
        try {
            res = await axios.put(`/api/facility/${facility.id}`, data, {
                headers: headers
            })
        } catch (e) {
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        try {
            if (res) {
                await UserService.getAccount()
            }
        } catch (e) {
            console.log(e)
            window.alert(
                'Sorry, there was an error. Please try again in a few seconds.'
            )
        }

        //update rates
        // TODO check and only update rates if rates changed
        if (
            props.user.isPlatformSuperAdmin &&
            facility.rates &&
            facility.rates.length > 0
        ) {
            try {
                res = await axios.put(
                    `/api/admin/account/rates/facility/${facility.id}`,
                    facility.rates,
                    {
                        headers: headers
                    }
                )
            } catch (e) {
                window.alert(
                    'Sorry, there was an error. Please try again in a few seconds.'
                )
            }
            await loadRates()
        }
        setIsLoading(false)
    }
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />

            <SelectFacility user={props.user} />
            <br />
            <br />
            <br />
            <br />
            <h2>Settings</h2>
            <br />
            <br />
            <form onSubmit={updateUser}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            id=""
                            value={facility.name}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="Healthcare Facility"
                            name="name"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={facility.address}
                            onChange={handleInputChange}
                            label="Address"
                            name="address"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.city}
                            onChange={handleInputChange}
                            style={styles.textField}
                            label="City"
                            name="city"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            value={facility.state}
                            style={styles.textField}
                            onChange={handleInputChange}
                            label="State"
                            name="State"
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            style={styles.textField}
                            value={facility.zipcode}
                            onChange={handleInputChange}
                            label="Zipcode"
                            name="zipcode"
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            style={styles.textField}
                            value={facility.phone}
                            onChange={handleInputChange}
                            label="Phone"
                            name="phone"
                            type="phone"
                            placeholder="+15671234"
                            variant="outlined"
                        />
                    </Grid>

                    {/*
                            helperText="If a nurse has questions about a shift, the call will be directed to this number"
                            */}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            style={styles.textField}
                            value={facility.parkingInstruction}
                            onChange={handleInputChange}
                            label="Parking Instructions"
                            name="parkingInstruction"
                            multiline
                            rows={4}
                            placeholder="(Optional) Ex. Please park on the south east corner by Western and Carson Blvd. There's free parking there."
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={facility.isEnabled}
                                    onChange={handleBooleanInputChange}
                                    name="isEnabled"
                                />
                            }
                            label="Account Enabled (can place new shifts)"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <img
                            src={facility.logoUrl}
                            alt=""
                            onClick={() => {
                                fileUpload.current.click()
                            }}
                            style={{ borderRadius: '1em' }}
                        />
                        <br />
                        <br />
                        <Button variant="contained" component="label">
                            Upload logo image
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden
                                ref={fileUpload}
                            />
                        </Button>
                    </Grid>
                </Grid>

                {props.user.isPlatformSuperAdmin ? (
                    <div>
                        <br />
                        <br />
                        <div>Billing Rates</div>

                        {editRatesTable}
                        <br />
                        <br />
                    </div>
                ) : (
                    ''
                )}

                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <Button
                            type="submit"
                            style={styles.textField}
                            label="save"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                        {isLoading ? <CircularProgress /> : null}
                    </Grid>
                </Grid>
            </form>
            <br />
            <br />
            <br />
        </Container>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)
