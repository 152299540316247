import React from 'react'

import { withRouter } from 'react-router-dom'
import Container from '@mui/material/Container'

import withStyles from '@mui/styles/withStyles'

//import Paper from '@mui/material/Paper'
import { setUser, resetUser } from '../features/User/userSlice.js'
//import UserService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import DownloadShifts from '../features/FacilityAdmin/DownloadShifts/DownloadShifts'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const useStyles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
})

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        },
        resetUser: () => dispatch(resetUser())
    }
}

const Home = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />

            <DownloadShifts user={props.user} />
        </Container>
    )
}

//export default withStyles(useStyles) connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
export default compose(
    withStyles(useStyles),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Home)
