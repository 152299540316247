import React from 'react'

import Button from '@mui/material/Button'
import { connect } from 'react-redux' //Code

const moment = require('moment')
const XLSX = require('xlsx')

const DownloadShifts = props => {
    const handleDownload = e => {
        let rows = []
        console.log('handle select')
        let facilities = props.user.facilities
        facilities.forEach(facility => {
            let shiftCollections = facility.shiftCollections
            shiftCollections.forEach(shiftCollection => {
                let isCanceled = 'No'
                if (shiftCollection.isCanceled) {
                    isCanceled = 'Yes'
                }
                let numRequests = shiftCollection.quantity
                let numFilledRequests = 0
                let requests = shiftCollection.requests

                let startEnd =
                    shiftCollection.startTime + ' - ' + shiftCollection.endTime
                requests.forEach(request => {
                    let isBooked = request.shift
                    if (isBooked && !request.shift.isCanceled) {
                        numFilledRequests++
                        //console.log(shiftCollection)

                        let row = {
                            Facility: facility.name,
                            Filled: 'Yes',
                            Role: shiftCollection.type,
                            Date: shiftCollection.date,
                            Shift: startEnd,
                            'First Name': request.user.firstName,
                            'Last Name': request.user.lastName,
                            Zone: shiftCollection.data.zone,
                            Canceled: isCanceled,
                            Notes: shiftCollection.note,
                            Start: shiftCollection.start,
                            'Has Timesheet?': request.shift.hasTimesheet
                                ? 'Yes'
                                : 'No'
                        }
                        rows.push(row)
                    }
                })

                let requestRows = numFilledRequests
                while (requestRows <= numRequests) {
                    let row = {
                        Facility: facility.name,
                        Filled: 'No',
                        Role: shiftCollection.type,
                        Date: shiftCollection.date,
                        Shift: startEnd,
                        'First Name': '',
                        'Last Name': '',
                        Zone: shiftCollection.data.zone,
                        Canceled: isCanceled,
                        Notes: shiftCollection.note,
                        Start: shiftCollection.start,
                        'Has Timesheet?': ''
                    }
                    rows.push(row)
                    requestRows++
                }
            })
        })

        // sort by date
        rows = rows.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            //   // to get a value that is either negative, positive, or zero.
            let bStart = new Date(b.Start)
            let aStart = new Date(a.Start)
            //
            //return new Date(bStart) - new Date(aStart)
            //console.log(bStart)
            return bStart - aStart
        })
        // remove redundant key
        rows.forEach(row => {
            delete row.Start

            return row
        })
        const ws = XLSX.utils.json_to_sheet(rows)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, ws, 'Schedule')

        let date = moment(new Date()).format('YYYY-MM-DD@HH-mm-ss')

        let filename = 'schedule_' + date
        filename = filename + '.xlsx'

        XLSX.writeFile(workbook, filename)
    }

    let downloadBtn = (
        <div>
            <span style={{ fontSize: '1.5em', fontWeight: 'bolder' }}>
                *EXPERIMENTAL* Spreadsheet For All Facility Requests:
            </span>
            <br />
            <br />
            <Button
                onClick={handleDownload}
                style={{
                    marginLeft: '1em',
                    fontSize: '1.5em',
                    fontWeight: 'bolder'
                }}
                variant="contained"
            >
                Download{' '}
            </Button>
            <br />
            <br />* May have bugs, might be missing shifts, might be incorrect,
            etc. If you find an error with a spreadsheet, please let Scott know.
            Thanks!
        </div>
    )
    return downloadBtn
}

export default connect(
    null,
    null
)(DownloadShifts)
