import { createSlice } from '@reduxjs/toolkit'

export const customFacilityPayDialogSlice = createSlice({
    name: 'customFacilityPayDialog',
    initialState: {
        isOpen: false
    },
    reducers: {
        openCustomFacilityPayDialog: (state, action, status) => {
            // console.log('action pyaload')
            //console.log(action.payload)

            state = {
                ...state,
                isOpen: true
            }

            return state
        },
        closeCustomFacilityPayDialog: (state, action) =>
            (state = { ...state, isOpen: false, status: '' })
    }
})

export const isOpen = state => state
export const {
    openCustomFacilityPayDialog,
    closeCustomFacilityPayDialog
} = customFacilityPayDialogSlice.actions

export default customFacilityPayDialogSlice.reducer
