import React from 'react'
import { connect } from 'react-redux' //Code

import Button from '@mui/material/Button'
import axios from 'axios'

//import { openModal } from '../ShiftModal/shiftModalSlice'
//import ShiftModal from '../ShiftModal/ShiftModal'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
//import { useDispatch } from 'react-redux'

import AuthService from '../../../services/authService.js'
//import userService from '../../services/userService.js'
import ShiftService from '../../../services/nurseAdmin/shiftService.js'
import CircularProgress from '@mui/material/CircularProgress'
//import CallIcon from '@mui/icons-material/Call'
//import DescriptionIcon from '@mui/icons-material/Description'
//import NoteIcon from '@mui/icons-material/Note'

import { openSnackbarMessage } from '../../SnackbarMessage/snackbarMessageSlice'

const mapStateToProps = state => ({
    user: state.user
})
const mapDispatchToProps = dispatch => {
    return {
        openSnackbarMessage: data => {
            dispatch(openSnackbarMessage(data))
        }
    }
}

const CancelShiftDialog = props => {
    /* popover */
    const [cancelDialogTitle, setCancelDialogTitle] = React.useState('')
    const [cancelDialogText, setCancelDialogText] = React.useState('')
    const [cancelReason, setCancelReason] = React.useState('')
    /* end pop over */

    //THIS IS REALLY SHIFT COLLECTION AND NOT SHIFT
    let shift = props.shift

    //const [cancelDialogState, setCancelDialogState] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(
        () => {
            if (props.cancelAction === 'cancelShift') {
                setCancelDialogTitle('Cancel Shift?')
                setCancelDialogText('Would you like to cancel this shift?')
            } else if (props.cancelAction === 'cancelShiftRequest') {
                setCancelDialogTitle('Cancel Shift Request?')
                setCancelDialogText(
                    'Would you like to cancel this shift request?'
                )
            }
        },
        [props.cancelAction]
    )

    React.useEffect(
        () => {
            if (props.isOpen) {
                // clean out
                setCancelReason('')
            }
        },
        [props.isOpen]
    )
    /*
    const handleCloseCancelDialog = () => {
        setCancelDialogState(false)
    }
    */
    const handleConfirmCancelDialog = async () => {
        //require cancelation reason for cancel shift
        if (props.cancelAction === 'cancelShift') {
            if (!cancelReason) {
                alert(
                    'Please provide a reason for canceling shift. This will be sent to the facility. Thank you.'
                )
                return
            }
        }
        //nurse data
        setIsLoading(true)
        let headers = await AuthService.getHeader()
        headers['Content-Type'] = 'application/json'
        //get request
        /*
        let request = requests.reduce((prev, cur, index) => {
            return new Date(prev.updated) > new Date(cur.updated) ? prev : cur
        }) // returns 11
        */

        let shiftData = JSON.parse(JSON.stringify(shift))
        shiftData.associateId = props.user.selectedAssociate.id

        shiftData.cancelReason = cancelReason
        //TODO allow nurse to provide reason for cancelation
        await axios.delete(`/api/admin/shift/request/${shiftData.request.id}`, {
            headers: headers,
            data: shiftData
        })

        //setCancelDialogState(false)
        //
        //close modal
        props.handleClose()

        await ShiftService.getAvailableShifts()
        setIsLoading(false)
        let msg = `You have canceled this shift request.`
        props.openSnackbarMessage({
            isOpen: true,
            message: msg,
            alertType: 'success'
        })
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {cancelDialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {cancelDialogText}
                </DialogContentText>

                {props.cancelAction === 'cancelShift' ? (
                    <>
                        <br />
                        <DialogContentText id="alert-dialog-description">
                            Please provide a reason for cancelation. This will
                            be sent to the facility.
                        </DialogContentText>
                        <br />
                        <TextField
                            id=""
                            value={cancelReason}
                            onChange={event => {
                                setCancelReason(event.target.value)
                            }}
                            style={{ width: '100%' }}
                            label="Reason for cancelation"
                            rows={2}
                            minRows={2}
                            name="cancelReason"
                            required
                            variant="outlined"
                        />
                    </>
                ) : (
                    ''
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    No
                </Button>
                <Button onClick={handleConfirmCancelDialog} color="primary">
                    Yes
                </Button>
                {isLoading ? <CircularProgress /> : null}
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelShiftDialog)
