import React, { useState } from 'react'
import { connect } from 'react-redux' //Code

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
//import { useDispatch } from 'react-redux'

import CircularProgress from '@mui/material/CircularProgress'
//import ShiftService from '../../services/shiftService.js'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import AssociateTable from '../AssociateTable/AssociateTable.js'

//import CreateAssociate from '../CreateAssociate/CreateAssociate.js'
/*
import {
    openCustomFacilityPayDialog,
    closeCustomFacilityPayDialog
} from './customFacilityPayDialogSlice.js'
*/

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => {
    return {
        /*
        openDialog: () => {
            dispatch(openCustomFacilityPayDialog())
        },

        closeDialog: () => {
            dispatch(closeCustomFacilityPayDialog())
        }
        */
    }
}

const SelectAssociate = props => {
    // handle back button + see timsehet slice  for implemntation
    /*
    React.useEffect(
        () => {
            const onPopChange = e => {
                let last = props.navStack.slice(-1)[0]

                if (last === 'file' && props.isOpen) {
                    props.popNavStack()
                    props.closeDialog()
                    showTawk()
                    window.removeEventListener('popstate', onPopChange)
                    return
                }
            }
            window.addEventListener('popstate', onPopChange)
        },
        [props.navStack]
    )
    */
    // const [isClockDialogOpen, setIsClockDialogOpen] = React.useState(false)

    const handleCloseFileDialog = () => {
        //props.closeDialog()
        setIsOpen(false)
        //window.history.back()
        //setIsFileDialogOpen(false)
    }
    const handleOpen = () => {
        setIsOpen(true)
        //window.history.back()
        //setIsFileDialogOpen(false)
    }

    React.useEffect(
        () => {
            if (!props.isOpen) {
                // clear prior timesheet image
                //setFileUrl('')
                return
            }
        },
        [props.isOpen]
    )

    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <div>
            <Button
                onClick={handleOpen}
                color="primary"
                variant="contained"
                size="large"
            >
                Change Nurse
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                onClose={handleCloseFileDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                {/*
                <DialogTitle id="alert-dialog-title">
                    Switch Associate
                </DialogTitle>
               */}

                <DialogContent style={{ minWidth: '50vw' }}>
                    {/*
                    <CreateAssociate />
                    <br />
                    */}
                    <AssociateTable closeModal={handleCloseFileDialog} />
                    {isLoading ? <CircularProgress /> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectAssociate)
