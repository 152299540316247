import axios from 'axios'
import store from '../../app/store'
import { setUser } from '../../features/User/userSlice.js'
import { setShifts } from '../../features/NurseAdmin/Shift/shiftSlice.js'
import AuthService from './authService.js'
import TzMoment from '../../plugins/TzMoment'

const ShiftService = {
    getAvailableShifts: async function(accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }
        let shiftCollections = null
        let user = store.getState().user
        if (!user.selectedAssociate) {
            console.log('WARN no associate,  associateId')
            return
        }
        let associateId = store.getState().user.selectedAssociate.id
        if (!associateId) {
            console.log('WARN no associateId')
            return
        }
        let res = await axios.get(`/api/admin/shift/list/${associateId}`, {
            headers: headers
        })
        if (res) {
            shiftCollections = res.data
        }
        shiftCollections = shiftCollections.map(x => {
            let y = Object.assign({}, x)
            let shiftRange = y.dateTimeRange
            let tzMoment = new TzMoment(y.timezone)
            let start = tzMoment.moment(shiftRange[0])
            let end = tzMoment.moment(shiftRange[1])
            //console.log('shift service start ' + start)
            y.date = start.format('MMMM Do, dddd')
            y.dateSmall = start.format('MMM Do, dddd')
            y.startTime = start.format('h:mma')
            y.endTime = end.format('h:mma')
            y.isConfirmed = false
            y.isNoShow = false
            y.isPending = false
            y.isDeclined = false
            if (y.isCanceled || (y.shift && y.shift.isCanceled)) {
                y.isCanceled = true
                //y.status = 'Canceled'
            } else if (y.shift && y.shift.isNoShow) {
                y.isNoShow = true
            } else if (y.isFilled && !y.shift) {
                y.isDeclined = true
            } else if (y.shift && y.shift.id && y.shift.isCanceled !== true) {
                y.isConfirmed = true
                //y.status = 'Pending'
            } else if (
                y.request &&
                y.request.id &&
                y.request.isCanceled !== true
            ) {
                y.isPending = true
                //y.status = 'Available'
            }
            y.compensation =
                y.payoutHourlyRate *
                tzMoment.moment
                    .duration(tzMoment.moment(end).diff(tzMoment.moment(start)))
                    .asHours()

            return y
        })
        shiftCollections.sort(
            (a, b) =>
                new Date(a.dateTimeRange[0]) - new Date(b.dateTimeRange[1])
        )
        console.log('test save shift collections ')
        console.log(shiftCollections)
        store.dispatch(setShifts(shiftCollections))
        //console.log('inspect shifts')
        //console.log(store.getState().shifts)
        return shiftCollections
    },
    //DEPRECATED?!
    getAccount: async function(accessToken) {
        let headers = ''
        if (!accessToken) {
            headers = await AuthService.getHeader()
        } else {
            headers = { Authorization: 'Bearer ' + accessToken }
        }

        let user = null
        let res = await axios.get('/api/nurse/account/', {
            headers: headers
        })
        if (res) {
            user = res.data
        }

        /*
        let facilities = user.facilities

        // set facility if facility is not selected, update facility if exists
        // (1) replace user facility w/ refreshed facility obj
        let selectedFacilityId = null
        if (store.selectedFacility && store.selectedFacility.id) {
            selectedFacilityId = store.selectedFacility.id
            let updatedFacility = facilities.find(
                o => o.id === selectedFacilityId
            )
            if (updatedFacility) {
                user.selectedFacility = updatedFacility
            }
        } else {
            // (2) TODO make smarter; ok for now bc we don't support multi-facility
            for (let x = 0; x < facilities.length; x++) {
                let facility = facilities[x]
                user.selectedFacility = facility
            }
        }
        //console.log('user service ' + user.selectedFacility.shifts.length)
        */

        store.dispatch(setUser(user))
        return user
    }
}

export default ShiftService
