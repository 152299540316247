import {
    configureStore,
    getDefaultMiddleware,
    combineReducers
} from '@reduxjs/toolkit'

//persistStore,
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import shiftModalReducer from '../features/ShiftModal/shiftModalSlice'
import plivoModalReducer from '../features/PlivoModal/plivoModalSlice'
import cancelShiftDialogReducer from '../features/FacilityAdmin/CancelShiftDialog/cancelShiftDialogSlice'
import profileDialogReducer from '../features/ProfileDialog/profileDialogSlice'
import clockDialogReducer from '../features/ClockDialog/clockDialogSlice'
import fileReducer from '../features/File/fileSlice'

import userReducer from '../features/User/userSlice.js'
import timesheetReducer from '../features/Timesheet/timesheetSlice'
import customFacilityPayDialogReducer from '../features/NurseAdmin/CustomFacilityPayDialog/customFacilityPayDialogSlice'
import snackbarMessageReducer from '../features/SnackbarMessage/snackbarMessageSlice'

// nurse side
import shiftsReducer from '../features/NurseAdmin/Shift/shiftSlice.js'

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    stateReconciler: autoMergeLevel2,

    //blacklist: ['modal', 'shiftModal', 'plivo', 'user', 'cancelShiftDialog']
    blacklist: [
        'modal',
        'shiftModal',
        'plivo',
        'cancelShiftDialog',
        'profileDialog',
        'clockDialog',
        'file',
        'snackbarMessage',
        'customFacilityPayDialog'
    ]
}

export const rootReducer = combineReducers({
    user: userReducer,
    shifts: shiftsReducer,
    plivo: plivoModalReducer,
    shiftModal: shiftModalReducer,
    cancelShiftDialog: cancelShiftDialogReducer,
    profileDialog: profileDialogReducer,
    clockDialog: clockDialogReducer,
    timesheet: timesheetReducer,
    snackbarMessage: snackbarMessageReducer,
    file: fileReducer,
    customFacilityPayDialog: customFacilityPayDialogReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
})
