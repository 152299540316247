import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Avatar from '@mui/material/Avatar'
//import InboxIcon from '@mui/icons-material/MoveToInbox'
//mport MailIcon from '@mui/icons-material/Mail'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
//import HomeIcon from '@mui/icons-material/Home'
import DateRangeIcon from '@mui/icons-material/DateRange'
import BuildIcon from '@mui/icons-material/Build'
//import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore'
//import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
//import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import PaymentIcon from '@mui/icons-material/Payment'
import GroupsIcon from '@mui/icons-material/Groups'
import SettingsIcon from '@mui/icons-material/Settings'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ListIcon from '@mui/icons-material/List'

import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import { Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import { Auth } from 'aws-amplify'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
//import StarBorder from '@mui/icons-material/StarBorder'
import AppBar from '@mui/material/AppBar'
import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code
import CssBaseline from '@mui/material/CssBaseline'

//import { SignOut } from '@aws-amplify/ui-react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useHistory } from 'react-router-dom'
/*
const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto'
    }
})
*/

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em'
    },
    root: {
        display: 'flex',
        fontSize: '0.85em'
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}))

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = null

const AppDrawer = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const [path, setPath] = React.useState('/')

    // load on mount
    React.useEffect(() => {
        let startUrl = window.location.pathname.split('/').pop()
        startUrl = '/' + startUrl

        setPath(startUrl)
    }, [])

    const history = useHistory()

    React.useEffect(
        () => {
            history.listen(location => {
                setPath(location.pathname)
            })
        },
        [history]
    )
    // end set active

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const [facilityOpen, setFacilityOpen] = React.useState(true)
    const [nurseOpen, setNurseOpen] = React.useState(true)

    const expandOptions = (event, type) => {
        event.preventDefault()
        event.stopPropagation()
        if (type === 'facility') {
            setFacilityOpen(!facilityOpen)
        }
        if (type === 'nurse') {
            setNurseOpen(!nurseOpen)
        }
    }
    const toggleDrawer = state => event => {
        console.log('toggling')
        if (!isDesktop) {
            setOpen(!open)
        }
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            /*
            if (!isDesktop) {
                setOpen(!open)
            }
            */
            return
        }

        //setState({ ...state, [anchor]: open })
    }

    const logout = () => {
        Auth.signOut()
    }

    const list = anchor => (
        <div
            className={classes.root}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button onClick={e => expandOptions(e, 'facility')}>
                    <span style={{ marginRight: '1.5em' }}>
                        <Avatar style={{ backgroundColor: 'green' }}>F</Avatar>
                    </span>

                    <span style={{ marginRight: '0.75em', fontSize: '1.25em' }}>
                        Facility Admin
                    </span>
                    {facilityOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={facilityOpen} timeout="auto" unmountOnExit>
                    <List style={{ paddingLeft: '3em', fontSize: '0.5em' }}>
                        <ListItem
                            button
                            key="All Shifts"
                            selected={path === '/'}
                            component={Link}
                            to="/"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary="All Shifts" />
                        </ListItem>
                        <ListItem
                            button
                            key="All Open Shifts"
                            selected={path === '/open_shifts'}
                            component={Link}
                            to="/open_shifts"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <ListAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="All Open Shifts" />
                        </ListItem>
                        <ListItem
                            button
                            key="Home"
                            component={Link}
                            to="/facility_shifts"
                            selected={path === '/facility_shifts'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Facility Shifts" />
                        </ListItem>
                        <ListItem
                            button
                            key="Calendar"
                            selected={path === '/facility_calendar'}
                            component={Link}
                            to="/facility_calendar"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <DateRangeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Facility Calendar" />
                        </ListItem>
                        {props.user.isPlatformSuperAdmin ? (
                            <ListItem
                                button
                                key="Invoices"
                                component={Link}
                                to="/facility_invoices"
                                selected={path === '/facility_invoices'}
                            >
                                <ListItemIcon style={{ color: '#585c89' }}>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Facility Invoices" />
                            </ListItem>
                        ) : (
                            ''
                        )}
                        <ListItem
                            button
                            key="Settings"
                            component={Link}
                            to="/facility_settings"
                            selected={path === '/facility_settings'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Facility Settings" />
                        </ListItem>
                        <ListItem
                            button
                            key="Tools"
                            component={Link}
                            to="/facility_tools"
                            selected={path === '/facility_tools'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tools" />
                        </ListItem>
                        <ListItem
                            button
                            key="Prior Shifts"
                            selected={path === '/prior_shifts'}
                            component={Link}
                            to="/prior_shifts"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <ListAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="Past Shifts" />
                        </ListItem>
                        <ListItem
                            button
                            key="Help"
                            component="a"
                            target="_blank"
                            href="https://nurselab-facility.tawk.help/"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Facility Help " />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />

                <ListItem button onClick={e => expandOptions(e, 'nurse')}>
                    <span style={{ marginRight: '1.5em' }}>
                        <Avatar style={{ backgroundColor: 'red' }}>N</Avatar>
                    </span>

                    <span style={{ marginRight: '0.75em', fontSize: '1.25em' }}>
                        Nurse Admin
                    </span>
                    {/*}
                    <ListItemIcon>
                        <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nurse Admin" />
                    */}
                    {nurseOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={nurseOpen} timeout="auto" unmountOnExit>
                    <List style={{ paddingLeft: '3em', fontSize: '0.5em' }}>
                        <ListItem
                            button
                            key="Home"
                            component={Link}
                            to="/nurses"
                            selected={path === '/nurses'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <GroupsIcon />
                            </ListItemIcon>
                            <ListItemText primary="All Nurses" />
                        </ListItem>
                        <ListItem
                            button
                            key="Nurse Shifts"
                            component={Link}
                            to="/nurse_shifts"
                            selected={path === '/nurse_shifts'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Nurse Dashboard" />
                        </ListItem>
                        <ListItem
                            button
                            key="Calendar"
                            component={Link}
                            to="/nurse_calendar"
                            selected={path === '/nurse_calendar'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <DateRangeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Nurse Calendar" />
                        </ListItem>
                        <ListItem
                            button
                            key="History"
                            component={Link}
                            to="/nurse_activity"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Nurse Activity" />
                        </ListItem>
                        {/*
                        {props.user.isPlatformSuperAdmin? (
                            <ListItem
                                button
                                key="Billing"
                                component={Link}
                                to="/nurse_payments"
                            >
                                <ListItemIcon>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Billing" />
                            </ListItem>
                        ) : (
                            ''
                        )}
                        */}
                        <ListItem
                            button
                            key="Settings"
                            component={Link}
                            to="/nurse_settings"
                            selected={path === '/nurse_settings'}
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Nurse Settings" />
                        </ListItem>
                        <ListItem
                            button
                            key="Help"
                            component="a"
                            target="_blank"
                            href="https://nurselab-nurses.tawk.help/"
                        >
                            <ListItemIcon style={{ color: '#585c89' }}>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Nurse Help " />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <ListItem button key="Sign Out" onClick={logout}>
                    <ListItemIcon style={{ color: '#585c89' }}>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Divider />
                {/* hide menu icon on desktop devices since it's out by default */}
                {!isDesktop ? (
                    <Toolbar
                        style={{
                            minHeight: 0,
                            paddingTop: '0.2em',
                            paddingBottom: '0.2em'
                        }}
                    >
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            style={{ flexGrow: 0, color: 'black' }}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                ) : (
                    ''
                )}

                {/*end mobile view */}
            </AppBar>

            {!isDesktop ? (
                <Drawer
                    variant="temporary"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 1,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box'
                        }
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#282a3e',
                            color: 'white'
                        }
                    }}
                    className={classes.drawer}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    open={open}
                >
                    <div className={classes.toolbar}>
                        <IconButton
                            style={{ color: isDesktop ? '#585c89' : 'inherit' }}
                            onClick={handleDrawerClose}
                            size="large"
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    <ListItem style={{ fontSize: '1.5em' }}>
                        <span>
                            <img
                                src="/logo_white.png"
                                alt="logo"
                                style={{
                                    height: '1.75em',
                                    color: 'black',
                                    marginRight: '0.35em',
                                    verticalAlign: 'bottom',
                                    display: 'inline-block'
                                }}
                            />

                            <span style={{ fontWeight: 'bolder' }}>
                                NurseLab
                            </span>
                        </span>
                    </ListItem>

                    {list()}
                </Drawer>
            ) : (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 1,
                        overflow: 'hidden',
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            overflow: 'hidden',
                            boxSizing: 'border-box'
                        }
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#282a3e',
                            color: 'white'
                        }
                    }}
                    className={classes.drawer}
                >
                    <ListItem style={{ fontSize: '1.5em' }}>
                        <span>
                            <img
                                src="/logo_white.png"
                                alt="logo"
                                style={{
                                    height: '1.75em',
                                    color: 'black',
                                    marginRight: '0.35em',
                                    verticalAlign: 'bottom',
                                    display: 'inline-block'
                                }}
                            />

                            <span
                                style={{
                                    fontWeight: 'bolder',
                                    marginTop: '0.5em'
                                }}
                            >
                                NurseLab
                            </span>
                        </span>
                    </ListItem>
                    {list()}
                </Drawer>
            )}
        </div>
    )
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AppDrawer)
