import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux' //Code

import TzMoment from '../../plugins/TzMoment.js'

const mapStateToProps = state => ({
    user: state.user
})

const TZClocks = props => {
    const [date, setDate] = useState(new Date())

    let timezone = 'America/Los_Angeles'

    if (props.user.selectedFacility.timezone) {
        timezone = props.user.selectedFacility.timezone
    }
    let lMoment = new TzMoment()
    let fMoment = new TzMoment(timezone)

    const [fDate, setFDate] = useState(fMoment.moment())

    function refreshClock() {
        setDate(new Date())
        setFDate(fMoment.moment())
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 5000)
        return function cleanup() {
            clearInterval(timerId)
        }
    }, [])
    return (
        <div>
            <br />
            Time @ {props.user.selectedFacility.name}:{' '}
            {fDate.format('dddd, MMMM D [at] h:mm A (z)')}
            <br />
            Your local time:{' '}
            {date.toLocaleTimeString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit'
            })}{' '}
        </div>
    )
}

export default connect(
    mapStateToProps,
    null
)(TZClocks)
