import React from 'react'

import AuthService from '../services/authService.js'
import Container from '@mui/material/Container'

import SelectFacility from '../features/FacilityAdmin/SelectFacility/SelectFacility'
//import { withStyles } from '@mui/material/styles'

import { setUser } from '../features/User/userSlice.js'
//import userService from '../services/userService.js'

import { compose } from 'redux' //Code
import { connect } from 'react-redux' //Code

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'

import AddInvoice from '../features/FacilityAdmin/AddInvoice/AddInvoice.js'
import EditInvoice from '../features/FacilityAdmin/EditInvoice/EditInvoice.js'
import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit'

import TzMoment from '../plugins/TzMoment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
//import Utils from '../helpers/utils.js'

const useStyles = makeStyles({
    table: {
        minWidth: 0
    }
})

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => {
    return {
        setUser: userState => {
            dispatch(setUser(userState))
        }
    }
}

let oldServerData = []
/*
function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
}
*/

/*
var rows = [
    createRow('12/10/20 RN, Lindsey Johnson 9-5pm ', 8, 70.0),
    createRow('12/9/20 LVN, Amy Rowlett 9-9pm ', 12, 45.0),
    createRow('12/5/20 CNA, Tim Reed 9-1pm ', 4, 28.0),
    createRow('12/3/20 CNA, Tim Reed 9-5pm ', 8, 28.0)
]
*/

//const invoiceSubtotal = subtotal(rows)
//const invoiceTotal = invoiceTaxes + invoiceSubtotal
//const invoiceTotal = invoiceSubtotal

const Invoices = props => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const [invoiceRows, setInvoiceRows] = React.useState([])
    const [editInvoiceToggle, setEditInvoiceToggle] = React.useState(false)
    const [selectedInvoice, setSelectedInvoice] = React.useState({})

    let tzMoment = new TzMoment(props.user.selectedFacility.timezone)
    const populateTable = rows => {
        //ctotal unused; dep
        let cTotal = 0
        ;[rows, cTotal] = createRows(rows)
        //console.log(JSON.stringify(rows))
        if (rows.length === 0) {
            rows = [
                {
                    id: '',
                    invoiceDatePretty: 'No Invoices',
                    invoice: '',
                    isPaid: '',
                    url: ''
                }
            ]
        }
        setInvoiceRows(rows)
    }
    const createRows = rows => {
        let tmpRows = []
        let bigTotal = 0

        rows.forEach(item => {
            let role = ''
            if (item.role) {
                role = item.role.shortName + ': '
            }

            /*
        if (hrs > 5) {
            msg += '. Includes 30 min lunch break.'
        }
        */
            //console.log(JSON.stringify(item))
            let total = item.amount ? item.amount : item.pendingAmount
            let invoice = item.invoice ? item.invoice : ''
            total = parseFloat(total).toFixed(2)
            bigTotal = (parseFloat(bigTotal) + parseFloat(total)).toFixed(2)

            let hrs = ''
            if (item.shiftCollection && item.shiftCollection.duration) {
                hrs = item.shiftCollection.duration
            }

            let rate = ''
            if (item.payRate && item.payRate.billableHourlyRate) {
                rate = item.payRate.billableHourlyRate.toFixed(2)
            }

            let invoiceDatePretty = tzMoment
                .moment(item.invoiceDate)
                .format('l')
            let invoiceDate = item.invoiceDate
            let isPaidPretty = item.isPaid ? 'Paid' : 'Not Paid'
            let isPaid = item.isPaid
            let id = item.id
            let url = item.url

            let tmp = {
                id,
                invoice,
                hrs,
                total,
                rate,
                invoiceDate,
                invoiceDatePretty,
                isPaid,
                isPaidPretty,
                url
            }
            tmpRows.push(tmp)
        })
        return [tmpRows, bigTotal]
    }

    const pageLoad = async () => {
        let headers = await AuthService.getHeader()

        let rows = []
        if (
            props.user &&
            props.user.selectedFacility &&
            props.user.selectedFacility.id
        ) {
            let facilityId = props.user.selectedFacility.id

            let res
            try {
                res = await axios.get(
                    `/api/admin/account/invoices/facility/${facilityId}`,
                    {
                        headers: headers
                    }
                )

                console.log(res.data)

                rows = res.data
            } catch (e) {
                window.alert(
                    'Sorry, there was an error. Please try again in a few seconds.'
                )
            }
        }
        oldServerData = rows
        populateTable(rows)
    }
    React.useEffect(
        () => {
            console.log('loading billing use effect')

            pageLoad()
        },
        [props.user.selectedFacility]
    )
    React.useEffect(() => {
        console.log('loading page ')

        populateTable(oldServerData)
    }, [])
    const classes = useStyles()

    const editInvoice = invoice => {
        setSelectedInvoice(invoice)
        setEditInvoiceToggle(!editInvoiceToggle)
    }
    const toggleEditInvoice = invoice => {
        setEditInvoiceToggle(!editInvoiceToggle)
    }

    return (
        <Container
            style={{
                maxWidth: '100%',
                width: '100%',
                marginLeft: isDesktop ? '3em' : '0',
                marginRight: isDesktop ? '3em' : '0'
            }}
        >
            <br />
            <br />
            <SelectFacility user={props.user} />
            <br />
            <br />
            <br />
            <br />
            <AddInvoice />
            <br />
            <br />
            <h2> Invoices </h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell align="">Status</TableCell>
                            <TableCell align="right">
                                Download Invoice
                            </TableCell>
                            <TableCell align="right">Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceRows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.invoiceDatePretty}</TableCell>
                                <TableCell>{row.invoice}</TableCell>
                                <TableCell align="">
                                    {row.isPaidPretty}
                                </TableCell>
                                <TableCell align="right">
                                    {row.url ? (
                                        <a
                                            href={row.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Invoice {row.invoice}
                                        </a>
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    {row.invoice ? (
                                        <EditIcon
                                            style={{ color: 'gray' }}
                                            onClick={() => editInvoice(row)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditInvoice
                isOpen={editInvoiceToggle}
                toggleEditInvoice={toggleEditInvoice}
                invoice={selectedInvoice}
            />
        </Container>
    )
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Invoices)
